import React, { useEffect, useState } from "react";
import "./BlogCard.css";
import axios from "axios";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Eye, Pencil, Trash } from "lucide-react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";

const BlogCard = ({
  setBlogId,
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [blogs, setBlogs] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const getBlogs = () => {
    axios
      .get("https://gharwala-5466fddd6458.herokuapp.com/api/blogs/allBlogs")
      .then((res) => {
        console.log(res);
        setBlogs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteBlog = (id) => {
    privateReq
      .post(`/delete-blogs/${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.message === "Blog removed successfully") {
          toast.success("blog removed successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getBlogs();
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const handleEdit = (id) => {
    setBlogId(id);
    navigate("/blogs/editblog");
  };

  const handleDelete = (id) => {
    deleteBlog(id);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Blogs | GharWala One step solution for your housing and furniture
          rental search gharwalah.com{" "}
        </title>

        <meta
          name="description"
          content="Explore insightful articles, tips, and updates for properties. Stay informed and inspired with the latest content curated just for you."
        />
      </Helmet>

      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        className="blog-layout"
        style={{ maxWidth: "1400px", margin: "0 auto", marginTop: "180px" }}
      >
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              to="/admin"
            >
              Dashboard
            </Link>

            <Link
              underline="hover"
              color="inherit"
              to="/admin/blog-management"
            >
              Blog Options
            </Link>
            <Typography sx={{ color: "text.primary" }}>All Blogs</Typography>

          </Breadcrumbs>
        </div>

        <div style={{ marginTop: "30px" }} className="lower-section">
          
          {user?.role === "admin" ? (
            <Paper
              elevation={3}
              sx={{
                width: "100%",
                overflow: "hidden",
                borderRadius: "20px",
                background: "white",
                boxShadow: "0 4px 24px rgba(0,0,0,0.08)",
                mb: 4,
              }}
            >
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "700",
                          backgroundColor: "#f8fafc",
                          padding: "16px 24px",
                          fontSize: "0.95rem",
                          color: "#1e293b",
                          borderBottom: "2px solid #e2e8f0",
                        }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "700",
                          backgroundColor: "#f8fafc",
                          padding: "16px 24px",
                          fontSize: "0.95rem",
                          color: "#1e293b",
                          borderBottom: "2px solid #e2e8f0",
                        }}
                      >
                        Short Description
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "700",
                          backgroundColor: "#f8fafc",
                          padding: "16px 24px",
                          fontSize: "0.95rem",
                          color: "#1e293b",
                          borderBottom: "2px solid #e2e8f0",
                        }}
                      >
                        Image
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          backgroundColor: "#f8fafc",
                          padding: "1px 20px",
                        }}
                      >
                        Author
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "600",
                          backgroundColor: "#f8fafc",
                          padding: "1px 20px",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {blogs.map((blog) => (
                      <TableRow
                        key={blog._id}
                        hover
                        sx={{
                          "&:hover": {
                            backgroundColor: "#f8fafc",
                          },
                        }}
                      >
                        <TableCell style={{ padding: "1px 20px" }}>
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              color: "#334155",
                              display: "-webkit-box",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              lineHeight: 1.4,
                            }}
                          >
                            {blog?.title}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "1px 20px" }}>
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              color: "#334155",
                              display: "-webkit-box",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              width: "200px",
                            }}
                          >
                            {blog?.shortDescription}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "1px 20px" }}>
                          <Typography variant="caption" color="textSecondary">
                            <img
                              src={blog?.imageUrl}
                              alt={blog?.title}
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "12px",
                                objectFit: "cover",
                                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                              }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg";
                              }}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "1px 20px" }}>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              width: "200px",
                            }}
                          >
                            {blog?.author}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "16px 24px",
                            "& .MuiIconButton-root": {
                              transition: "all 0.2s ease",
                              padding: "8px",
                              "&:hover": {
                                transform: "translateY(-2px)",
                              },
                            },
                          }}
                        >
                          <div style={{ display: "flex", gap: "12px" }}>
                            <Tooltip title="View Blog">
                              <IconButton
                                onClick={() =>
                                  window.open(
                                    `/blogs/${
                                      blog?.routeTitle
                                        ? blog?.routeTitle
                                        : blog._id
                                    }`,
                                    "_blank"
                                  )
                                }
                                sx={{
                                  color: "#3b82f6",
                                  "&:hover": { backgroundColor: "#dbeafe" },
                                }}
                                size="small"
                              >
                                <Eye size={18} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit Blog">
                              <IconButton
                                onClick={() => handleEdit(blog._id)}
                                sx={{
                                  color: "#10b981",
                                  "&:hover": { backgroundColor: "#dcfce7" },
                                }}
                                size="small"
                              >
                                <Pencil size={18} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Blog">
                              <IconButton
                                onClick={() => handleDelete(blog._id)}
                                sx={{
                                  color: "#ef4444",
                                  "&:hover": { backgroundColor: "#fee2e2" },
                                }}
                                size="small"
                              >
                                <Trash size={18} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <div className="blog-grid">
              {blogs.map((blog) => (
                <div
                  key={blog._id}
                  className="blog-card"
                  onClick={() =>
                    window.open(
                      `/blogs/${
                        blog?.routeTitle ? blog?.routeTitle : blog._id
                      }`,
                      "_blank"
                    )
                  }
                >
                  <div className="blog-card-image">
                    <img
                      src={blog?.imageUrl}
                      alt={blog?.title}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg";
                      }}
                    />
                  </div>
                  <div className="blog-card-content">
                    <h3 className="blog-title">
                      {blog?.title?.length > 60
                        ? blog.title.substring(0, 60) + "..."
                        : blog?.title}
                    </h3>
                    <p className="blog-description">
                      {blog?.shortDescription?.length > 160
                        ? blog.shortDescription.substring(0, 160) + "..."
                        : blog?.shortDescription}
                    </p>
                    <div className="blog-meta">
                      <span className="blog-author">
                        By{" "}
                        {blog?.author?.length > 30
                          ? blog.author.substring(0, 30) + "..."
                          : blog?.author}
                      </span>
                    </div>
                    <button className="blog-read-more">Explore Article</button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default BlogCard;
