import React, { useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import publicReq from "../../config/axiosConfig";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import contact from "../../assets/images/homeaboutbottom.svg";
import homeabout from "../../assets/images/homeabout.svg";
import "./contact.css";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ContactUsPage({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    isFeedback: false,
  });

  const [showPage, setShowPage] = useState(true);
  const { user } = useSelector((state) => state.auth);



  const isFormValid = () => {
    return (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.message !== "" &&
      isValidEmail(formData.email)
    );
  };

  const isValidEmail = (email) => {
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Submit the form if validation passes
    if (isFormValid()) {
      publicReq
        .post("/contact", {
          fullName: formData.name,
          email: formData.email,
          desc: formData.message,
          phone: formData.phoneNumber,
          is_feedback: formData.isFeedback,
        })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Details submitted successfully.");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // Reset the form after submission
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
        isFeedback: false,
      });
    } else {
      toast.error("Please fill in all required fields with valid data.");
    }
  };

  return showPage ? (
    <>
    <Helmet>
        <title>Contact Us | GharWala One step solution for your housing and furniture rental search gharwalah.com </title>
        <meta
          name="description"
          content="For any inquiries or assistance related to returns and refunds, please contact our customer support team. We strive to ensure a seamless and customer-centric experience with our furniture and home appliances rental services."
        />
      </Helmet>

      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          display: "flex",
          marginTop: "100px",
          paddingBlock: "60px",
          background:
            "linear-gradient(to right, #b614bf, #4f10c3, #db23d5, #610ab4)",
        }}
        className="contact-main-container"
      >
        <div className="contact-page-image" style={{ padding: "60px" }}>
          <img src={contact} />
        </div>
        <Container
          style={{
            backgroundColor: "white",
            paddingBlock: "20px",
            borderRadius: "8px",
          }}
          maxWidth="sm"
        >
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isFeedback"
                      checked={formData.isFeedback}
                      onChange={handleChange}
                    />
                  }
                  label="Is this feedback?"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isFormValid()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
      <Footer />
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
}

export default ContactUsPage;
