import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import StickyFooter from "../../components/Footer";
import StickyFooterMobile from "../../components/FooterMobile";
import { useMediaQuery } from "react-responsive";
import Setting from "../../components/Setting";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import owner from "../../assets/images/owner.png";

import user44 from "../../assets/images/44.png";
import blog1 from "../../assets/images/blog1.png";
import blog2 from "../../assets/images/blog2.png";
import blog3 from "../../assets/images/blog3.png";
import { Breadcrumbs, Typography } from "@mui/material";

const BlogOptions = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { user } = useSelector((state) => state.auth);
  const [showAdmin, setShowAdmin] = useState(false);

  useEffect(() => {
    console.log("this is now user", user);
    if (user !== null) {
      if (user?.role === "admin") {
        setShowAdmin(true);
      }
    }
  }, []);

  return (
    <>
      <div>
        <Header
          closeLoginModal={closeLoginModal}
          setCloseLoginModal={setCloseLoginModal}
          setOpenPopUp={setOpenPopUp}
        />
      </div>
      {showAdmin ? (
        <>
          <div
            style={{
              marginTop: "100px",
              padding: "20px",
            }}
          >
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  to="/admin"
                >
                  Dashboard
                </Link>

                <Typography sx={{ color: "text.primary" }}>Blogs</Typography>
              </Breadcrumbs>
            </div>

            <div className="admin-head">Blog Options</div>

            <div className="each-box">
              <div className="image-box">
                <Link to="/blogs">
                  <img className="zip img-admin" src={blog3} alt="zip" />
                </Link>
                <div className="img-admin-name">All Blogs</div>
              </div>

              <div className="image-box">
                <Link to="/admin/blogs/addBlog">
                  <img
                    className="zip img-admin"
                    src={blog1}
                    alt="role-management"
                  />
                </Link>
                <div className="img-admin-name">Category</div>
              </div>

              <div className="image-box">
                <Link to="/admin/blogs/addBlog">
                  <img className="tab img-admin" src={blog2} alt="tab" />
                </Link>
                <div className="img-admin-name">Write Blog</div>
              </div>

              {/* <div className="image-box">
                <Link to="/all-users?role=guest">
                  <img className="tab img-admin" src={user4} alt="tab" />
                </Link>
                <div className="img-admin-name">Guest</div>
              </div> */}

              {/* <div className="image-box">
                <Link to="/all-users?role=serviceman">
                  <img className="tab img-admin" src={user5} alt="tab" />
                </Link>
                <div className="img-admin-name">Serviceman</div>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <h1 style={{ margin: " 250px 10px", textAlign: "center" }}>
          404 ERROR
        </h1>
      )}

      {isMobile ? <StickyFooterMobile /> : <StickyFooter />}
    </>
  );
};

export default BlogOptions;
