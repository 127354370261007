import React, { useEffect, useState } from "react";
import { getProperties } from "../../redux/action/property";
import { useDispatch } from "react-redux";
import axios from "axios";

const Sitemap = () => {
  const [sortby, setSortBy] = useState("created");
  const dispatch = useDispatch();
  const [propertyDetails, setPropertyDetails] = useState();

  const getPropertiesInitial = async () => {
    try {
      const api =
        "https://gharwala-5466fddd6458.herokuapp.com/api/get-properties?&sortby=created";

      const resp = await axios.get(api);
      setPropertyDetails(resp.data);
    } catch {}
  };

  useEffect(() => {
    getPropertiesInitial();
  }, []);

  return (
    <div>
      {/* <urlset
        xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd"
      > */}
      {/* <script src="chrome-extension://hoklmmgfnpapgjgcpechhaamimifchmp/frame_ant/frame_ant.js" />

        <url>
          <loc>https://www.gharwalah.com/</loc>
          <lastmod>2024-04-23T10:08:35+00:00</lastmod>
        </url> */}

      <br></br>
      {propertyDetails?.map((propertyDetails) => (
        <>
          &lt;url&gt;
          <br></br>
          &lt;loc&gt;
          <br></br>
          <span style={{ textTransform: "lowercase" }} className={""}>
            https://gharwalah.com/properties/
            {propertyDetails?.bedrooms}-bhk-for{"-"}
            {propertyDetails?.wantto !== "sell" ? "rent-" : "sell-"}in{"-"}
            {propertyDetails?.locality?.coordinates[2]
              .replace(/,/g, "")
              .replace(/\s+/g, "-")
              .toLowerCase()}
            ?id={propertyDetails?._id}
            <br></br>
          </span>
          &lt;/loc&gt;
          <br></br>
          &lt;lastmod&gt;2024-04-23T10:08:35+00:00&lt;/lastmod&gt;
          <br></br>
          &lt;/url&gt;
          <br></br>
          <br></br>
        </>
      ))}

      {/* </urlset> */}
    </div>
  );
};

export default Sitemap;
