import React, { useEffect, useState } from "react";
import Home from "./home";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation,
} from "react-router-dom";
import Properties from "./properties";
import Furniture from "./furniture";
import AddProperty from "./add-property";
import AddFurniture from "./add-furniture";
import AddBroker from "./add-broker";
import Owners from "./owners";
import Brokers from "./brokers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccessories,
  setCurrentLocation,
  setCurrentUser,
} from "../redux/action/auth";
import Login from "../screens/LoginScreen";
import { getProperties } from "../redux/action/property";
import { getFurnitures } from "../redux/action/furniture";
import PropertyDetails from "./property-detail";
import FurnitureDetails from "./furniture-detail";
import ProductList from "./viewed-products";
import Profile from "./profile";
import UserDetails from "./user-details";
import AddAccessories from "./add-accessories";
import AddOtherService from "./other-service";
import OtherServices from "./other-service/list";
import FurnitureOrder from "./furniture-order";
import AboutUs from "./about";
import RentalTerms from "./terms/rental";
import RefferalTerms from "./terms/referral";
import Privacy from "./privacy";
import ContactUs from "./contact";
import BuyingProperty from "./buying-property";
import SellProperty from "./sell-property";
import RentingProperty from "./renting-property";
import SecurityDep from "./security-deposit";
import Wishlist from "./wishlist";
import Services from "./services-text";
import ReturnPolicy from "./return-policy";
import DataSecurity from "./data-security";
import toast from "react-hot-toast";
import Covid19 from "./covid";
import Benefits from "./benefits";
import MainTerms from "./terms/main";
import CheckPaymentStatus from "../components/CheckPaymentStatus";
import Agreement from "./agreement";
import AgreementForm from "./agreement-form/AgreementForm";
import FormBasicDetails from "./agreement-form/forms/FormBasicDetails";
import FormPropertyDetails from "./agreement-form/forms/FormPropertyDetails";
import FormContractDetails from "./agreement-form/forms/FormContractDetails";
import AgreementContent from "./agreement-form/components/AgreementContent";
import FinalAgreement from "./agreement-form/components/FinalAgreement";
import AgreementPaymentStatus from "../components/AgreementPaymentStatus";
import { checkTabsStatus } from "../config/user";
import AdminLogin from "./admin-login";
import TabsAccess from "./tabs";
import Index from "./carrying-cost";
import Shortlist from "./shortlist";
import Pincodes from "./pincodes";
import Notification from "./notification/Notification";
import Admin from "./admin";
import SuccessfullOrder from "./succssfullOrder";
import AdminCategory from "./admin-category";
import EditServices from "./edit-services";
import ImageUploader from "../components/BlogUpload/Blog";
import SingleBlogPage from "../components/BlogUpload/SingleBlog";
import BlogForm from "../components/BlogUpload/UploadBlog";
import Checkout from "./checkout";
import ReferScheme from "./refer-scheme";
import AddTestimonial from "./add-testimonial";
import FurnitureRenting from "./furniture-home-renting";
import BuyingFurniture from "./furniture-home-renting/buying";
import ContactUsPage from "./contact-us";
import Testimonials from "./testimonials";
import PincodeTestimonial from "./pincode-testimonial";
import GetContact from "./get-contact";
import BlogCard from "../components/BlogUpload/Blog";
import EditBlog from "../components/BlogUpload/EditBlog";
import HappyClients from "./happy-clients";
import WelcomeToGharwala from "./welcome-to-gharwala";
import AgreementPage from "./agreement-page";
import EditFurniture from "./edit-furniture/editFurniture";
import FormFurnishingDetails from "./agreement-form/forms/FormFurnishingDetails";
import RecentProperties from "./recent-properties";
import ReferAndEarn from "./refer-earn";
import ManageServices from "./manage-services";
import ManageProperties from "./manage-properties";
import ModalCredit from "../components/ModalCredits/ModalCredit";
import UnsuccessfulOrder from "./unsuccessfulOrder";
import UpdateProperty from "./edit-property";
import UserList from "./users";
import HomeNew from "./homen-new";
import AdminAddUsers from "./admin-add-user";
import RoleManagement from "./role-management";
import SetRoleAdmin from "./set-role-admin";
import Department from "./department";
import SiteSettings from "./site-settings";
import RentalAssets from "./rental-assets";
import Settings from "./settings";
import AdminUserList from "./admin-userlist";
import Sitemap from "./sitemap";
import GoogleConsole from "./google-console";
import PropertyList from "./property-list";
import BlogOptions from "./blog-options";
import FeatureProperties from "./feature-properties";
import NearByProperties from "./near-by-properties";
import AdminProperties from "./properties/admin-properties";
import BacklogEnteries from "./backlog-enteries";

const RootStackScreen = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [blogId, setBlogId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  console.log("user", user);
  // const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  React.useEffect(() => {
    getUser();
    fetchDataEveryMinute();
    // onGetAllProperties();
  }, []);

  const fetchDataEveryMinute = async () => {
    let enabled = true;
    if (user?.role === "BROKERS") {
      let res = await checkTabsStatus({ propertyName: "BROKERS" });
      enabled = res.message.enabled;
    } else if (user?.role === "OWNERS") {
      let res = await checkTabsStatus({ propertyName: "OWNERS" });
      enabled = res.message.enabled;
    } else if (user?.role === "SERVICES") {
      let res = await checkTabsStatus({ propertyName: "SERVICES" });
      enabled = res.message.enabled;
    }
    if (!enabled) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchDataEveryMinute, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        );
        onGetAllProperties(
          `?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`
        );
        onGetAllFurnitures();
      },
      () => {
        onGetAllProperties(``);
        onGetAllFurnitures();
      }
    );
  }, []);

  const onGetAllProperties = (query = "") => {
    // dispatch(getProperties(query));
    dispatch(getAllAccessories());
  };
  const onGetAllFurnitures = () => {
    let filterQuery = "";
    dispatch(getFurnitures(filterQuery));
  };

  const getUser = () => {
    let token = localStorage.getItem("token");
    if (token) {
      dispatch(
        setCurrentUser(token, () => {
          setIsLogin(true);
          setLoader(false);
        })
      );
    } else {
      setIsLogin(false);
      setLoader(false);
    }
  };
  return (
    <Routes>
      <Route path="/login" element={<Login setIsLogin={setIsLogin} />}></Route>
      <Route
        path="/"
        element={
          <HomeNew
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/home-new"
        element={
          <HomeNew
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />{" "}
      {/* Only <Route> inside <Routes> */}
      {/* Add more routes here */}
      <Route
        path="/checkout"
        element={
          <Checkout
            user={user}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/furniture-renting"
        element={
          <FurnitureRenting
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/furniture-buying"
        element={
          <BuyingFurniture
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/agreement"
        element={
          <Agreement
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="featured-properties"
        element={
          <FeatureProperties
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/near-by-properties"
        element={
          <NearByProperties
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/agreement"
        element={
          <AgreementForm
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      >
        <Route
          path="basic-details"
          element={
            <FormBasicDetails
              closeLoginModal={closeLoginModal}
              setCloseLoginModal={setCloseLoginModal}
              setOpenPopUp={setOpenPopUp}
            />
          }
        />

        <Route
          path="property-details"
          element={
            <FormPropertyDetails
              closeLoginModal={closeLoginModal}
              setCloseLoginModal={setCloseLoginModal}
              setOpenPopUp={setOpenPopUp}
            />
          }
        />
        <Route
          path="contract-details"
          element={
            <FormContractDetails
              closeLoginModal={closeLoginModal}
              setCloseLoginModal={setCloseLoginModal}
              setOpenPopUp={setOpenPopUp}
            />
          }
        />
        <Route
          path="furnishing-details"
          element={
            <FormFurnishingDetails
              closeLoginModal={closeLoginModal}
              setCloseLoginModal={setCloseLoginModal}
              setOpenPopUp={setOpenPopUp}
            />
          }
        />
        <Route
          path="final-agreement"
          element={
            <FinalAgreement
              closeLoginModal={closeLoginModal}
              setCloseLoginModal={setCloseLoginModal}
              setOpenPopUp={setOpenPopUp}
            />
          }
        />
      </Route>
      <Route
        path="/admin/google-console"
        element={
          <GoogleConsole
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-settings/carrying-cost"
        element={
          <Index
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/properties"
        element={
          <Properties
            user={user}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/admin/properties"
        element={
          <AdminProperties
            user={user}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/properties/:id"
        element={
          <PropertyDetails
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/furniture"
        element={
          <Furniture
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/owners"
        element={
          <Owners
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-settings"
        element={
          <Settings
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/admin/user-management"
        element={
          <AdminUserList
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/owners/:id"
        element={
          <UserDetails
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/user-management/all-users"
        element={
          <UserList
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
            user_loggedin={user}
          />
        }
      />
      <Route
        path="/admin/blog-management"
        element={
          <BlogOptions
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
            user_loggedin={user}
          />
        }
      />
      <Route
        path="/successfullOrder"
        element={
          <SuccessfullOrder
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/unsuccessfulOrder"
        element={
          <UnsuccessfulOrder
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/edit-properties/:id"
        element={
          <UpdateProperty
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/brokers"
        element={
          <Brokers
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/brokers"
        element={
          <Brokers
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/pincodes"
        element={
          <Pincodes
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/notification"
        element={
          <Notification
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/category-management"
        element={
          <AdminCategory
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/add-property"
        element={
          <AddProperty
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/add-furniture"
        element={
          <AddFurniture
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/furniture/:id"
        element={
          <FurnitureDetails
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/edit-furniture/:id"
        element={
          <EditFurniture
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/check-status"
        element={
          <CheckPaymentStatus
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/agreement-status"
        element={
          <AgreementPaymentStatus
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/blogs"
        element={
          <BlogCard
            user={user}
            setBlogId={setBlogId}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/rental-assets-management"
        element={
          <RentalAssets
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route path="/sitemap" element={<Sitemap />} />
      <Route
        path="/blogs/:blogId"
        element={
          <SingleBlogPage
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/blogs/addBlog"
        element={
          <BlogForm
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/blogs/editBlog"
        element={
          <EditBlog
            blogId={blogId}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/furniture/order"
        element={
          <FurnitureOrder
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/add-broker"
        element={
          <AddBroker
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/brokers/:id"
        element={
          <UserDetails
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/add-accessories"
        element={
          <AddAccessories
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/add-other-service"
        element={
          <AddOtherService
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/other-services"
        element={
          <OtherServices
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/about-us"
        element={
          <AboutUs
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/rental-terms"
        element={
          <RentalTerms
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/referral-terms"
        element={
          <RefferalTerms
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/privacy"
        element={
          <Privacy
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/contact-us"
        element={
          <ContactUs
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/profile"
        element={
          <Profile
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/buying-property"
        element={
          <BuyingProperty
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/wishlist"
        element={
          <Wishlist
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/shortlist"
        element={
          <Shortlist
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/selling-property"
        element={
          <SellProperty
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/renting-property"
        element={
          <RentingProperty
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/security-deposit"
        element={
          <SecurityDep
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/services"
        element={
          <Services
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/return-policy"
        element={
          <ReturnPolicy
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/data-security"
        element={
          <DataSecurity
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/covid19"
        element={
          <Covid19
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/benefits"
        element={
          <Benefits
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/terms"
        element={
          <MainTerms
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-login"
        element={
          <AdminLogin
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-settings/tabs-access"
        element={
          <TabsAccess
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/manage-services"
        element={
          <ManageServices
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/manage-properties"
        element={
          <ManageProperties
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin"
        element={
          <Admin
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/recent-properties"
        element={
          <RecentProperties
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-settings/site-settings"
        element={
          <SiteSettings
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-settings/department"
        element={
          <Department
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/enquires"
        element={
          <AdminAddUsers
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/role-management"
        element={
          <RoleManagement
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/property-list"
        element={
          <PropertyList
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-settings/role-management"
        element={
          <SetRoleAdmin
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/services-management"
        element={
          <EditServices
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/contact"
        element={
          <ContactUsPage
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/testimonial"
        element={
          <AddTestimonial
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />

      <Route
        path="/admin/backlog-enteries"
        element={
          <BacklogEnteries
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />

      <Route
        path="/pincode-testimonial"
        element={
          <PincodeTestimonial
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/get-testimonial"
        element={
          <Testimonials
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/happy-clients"
        element={
          <HappyClients
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin/contact-data"
        element={
          <GetContact
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/admin-settings/refer-scheme"
        element={
          <ReferScheme
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/refer-now"
        element={
          <ReferAndEarn
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/viewed-properties-all"
        element={
          <ProductList
            user={user}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route
        path="/viewed-properties"
        element={
          <ProductList
            user={user}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route path="/welcome-to-gharwala" element={<WelcomeToGharwala />} />
      <Route path="/agreement-page" element={<AgreementPage />} />
      <Route
        path="/buy-credits"
        element={
          <ModalCredit
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
    </Routes>
  );
};

export default RootStackScreen;
