import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import { Helmet } from "react-helmet";
const Covid19 = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Helmet>
        <title>Covid 19 | GharWala One step solution for your housing and furniture rental search gharwalah.com </title>
        <meta
          name="description"
          content="COVID-19 safety precautions with Hygiene and Cleanliness."
        />
      </Helmet>

      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        className="container"
        style={{ paddingTop: "125px", marginBottom: "100px" }}
      >
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          COVID-19 safety precautions with Hygiene and Cleanliness
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          At Gharwalah.com, your health and safety are our top priorities,
          especially during these challenging times. We have implemented a range
          of stringent COVID-19 safety precautions to ensure your peace of mind
          when renting furniture and home appliances: Sanitization Protocols:
          Before any item is delivered to your home, it undergoes a thorough
          sanitization process. This includes cleaning and disinfecting all
          surfaces to minimize the risk of viral transmission. Contactless
          Delivery: Our delivery process is designed to be entirely contactless.
          Our delivery personnel are trained to follow social distancing
          guidelines, ensuring a safe and secure delivery experience Hygienic
          Packaging: All products are carefully packaged in a way that maintains
          their cleanliness and ensures that they remain virus-free during
          transit. Regular Health Checks: Our staff undergo regular health
          checks to confirm that they are fit for duty. Any staff member showing
          symptoms or potential exposure is quarantined and tested to maintain a
          safe work environment. Masks and Personal Protective Equipment (PPE):
          Our delivery personnel are equipped with masks, gloves, and other
          necessary PPE to reduce the risk of infection. Safe Interaction: In
          the rare instances where in-person interactions are required (e.g.,
          maintenance services), our technicians maintain strict safety
          measures, ensuring their safety and yours. Contact-Free Payments: We
          encourage contactless payment methods to minimize physical contact
          during transactions. These precautions are designed to offer you a
          worry-free rental experience, assuring you that every step of the
          process is conducted with your safety in mind. Our commitment to your
          well-being remains unwavering, even in the face of challenging
          circumstances. Zero Commitment: Unlike long-term investments, renting
          provides a level of flexibility and freedom to adapt to your needs.
          Try out different items or change your selections without long-term
          commitments. By choosing Gharwalah.com, you're making a wise and
          cost-effective choice for furnishing your home, with the added
          convenience and peace of mind that comes with it. _ _Career
          Opportunities with Gharwala: _ Joining the Gharwala team means
          becoming a part of a dynamic and innovative community. We offer
          exciting career opportunities with numerous benefits: - Growth and
          Learning: At Gharwala, we believe in continuous learning and personal
          growth. We provide training and development programs to help you
          expand your skill set and stay ahead in your career. - Innovative
          Environment: Our work culture is driven by innovation. We encourage
          employees to think outside the box and contribute fresh ideas. You'll
          be part of a team that values creativity and original thinking. -
          Work-Life Balance: We understand the importance of a work-life
          balance. Our flexible work arrangements ensure you can excel in your
          career without sacrificing your personal life. We believe in your
          well-being both in and outside the workplace. - Team Collaboration:
          You'll be working with a diverse and talented team, fostering
          collaboration and camaraderie. Together, we tackle challenges and
          achieve great things. - Competitive Compensation: We offer competitive
          salaries and performance-based incentives, recognizing and rewarding
          your hard work. - Job Security: In uncertain times, Gharwala remains a
          stable and secure employer. We prioritize the well-being of our
          employees, offering job security and stability in your career. -Career
          Advancement: As we grow, so do our employees. There are ample
          opportunities for career advancement within the company. Whether
          you're starting your career or looking to climb the corporate ladder,
          we have a path for you. If you're looking for a rewarding career in a
          company that values your growth and well-being, Gharwala might be the
          perfect fit for you. Join us and be a part of our exciting journey.
          Send your resume to careers@gharwalah.com
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default Covid19;
