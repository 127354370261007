import React, { useEffect, useState } from "react";
import "./index.css";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";

const FeaturedBlog = () => {
  const [blogs, setBlogs] = useState();

  const getBlogs = () => {
    axios
      .get("https://gharwala-5466fddd6458.herokuapp.com/api/blogs/allBlogs")
      .then((res) => {
        console.log(res);
        setBlogs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <div className="featured-blog">
      <Typography
        variant="h4"
        align="center"
        fontWeight="bold"
        color="#0E0E46"
        gutterBottom
      >
        Read Our Recent Article
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Box
          component="hr"
          sx={{
            width: "20px",
            height: "6px",
            backgroundColor: "#B90000",
            border: "none",
            borderRadius: "30px",
          }}
        />

        <Box
          component="hr"
          sx={{
            width: "50px",
            height: "6px",
            backgroundColor: "#B90000",
            border: "none",
            borderRadius: "30px",
          }}
        />
      </Box>

      <div className="blog-grid">
        {blogs?.slice(0, 6).map((blog) => (
          <div
            key={blog._id}
            className="blog-card"
            onClick={() =>
              window.open(
                `/blogs/${blog?.routeTitle ? blog?.routeTitle : blog._id}`,
                "_blank"
              )
            }
          >
            <div className="blog-card-image">
              <img
                src={blog?.imageUrl}
                alt={blog?.title}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg";
                }}
              />
            </div>
            <div className="blog-card-content">
              <h3 className="blog-title">
                {blog?.title?.length > 60
                  ? blog.title.substring(0, 60) + "..."
                  : blog?.title}
              </h3>
              <p className="blog-description">
                {blog?.shortDescription?.length > 160
                  ? blog.shortDescription.substring(0, 160) + "..."
                  : blog?.shortDescription}
              </p>
              <div className="blog-meta">
                <span className="blog-author">
                  By{" "}
                  {blog?.author?.length > 30
                    ? blog.author.substring(0, 30) + "..."
                    : blog?.author}
                </span>
              </div>
              <button className="blog-read-more">Explore Article</button>
            </div>
          </div>
        ))}
      </div>

      <div className="blog-container-btn">
        <Link to={"/blogs"} className="view-all-blogs">
          View All Article
        </Link>
      </div>
    </div>
  );
};

export default FeaturedBlog;
