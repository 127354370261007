import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { privateReq } from "../../config/axiosConfig";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

const Notification = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [pincodes, setPincodes] = useState();
  const [addNotificationDate, setAddNotificationDate] = useState({
    username: "",
    phone: "",
    description: "",
    // email:"",
  });

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   setHeaderHeight(headerRef.current.offsetHeight);
  // }, [headerHeight]);

  const onChangenotification = (e) => {
    const { name, value } = e.target;
    setAddNotificationDate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSendNotification = async () => {
    if (
      !addNotificationDate.username ||
      !addNotificationDate.phone ||
      !addNotificationDate.description
    ) {
      toast.error("Please fill in all fields.", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
      return;
    }

    let phoneToSend = addNotificationDate.phone;
    if (!phoneToSend.startsWith("+91")) {
      phoneToSend = "+91" + phoneToSend;
    }

    try {
      const res = await privateReq.post("/admin-notification", {
        ...addNotificationDate,
        phone: phoneToSend,
      });
      console.log("res", res);
      toast.success("Message sent successfully", {
        position: "top-right",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />


<div style={{marginLeft:"50px", marginTop:"150px", marginBottom:"10px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

          

            <Typography sx={{ color: "text.primary" }}>
             Payment Notification
            </Typography>
          </Breadcrumbs>
          </div>

      <div
        style={{
          padding: "0 50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontFamily: "Montserrat",
          }}
        >
          Payment Notification
        </div>
        <p>
          This notification will be sent to the phone number added in the form.
        </p>
        <div>
          <div>
            <div style={{ marginLeft: "7px" }}>
              <div
                style={{
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <input
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  name="username"
                  value={addNotificationDate.username}
                  onChange={onChangenotification}
                  placeholder="User name"
                />
              </div>

              <input
                style={{
                  width: "300px",
                  marginBottom: "8px",
                  padding: "9px",
                  border:"1.5px solid #A9A9A9",
                  borderRadius:"4px"
                }}
                type="Number"
                name="phone"
                value={addNotificationDate.phone}
                onChange={onChangenotification}
                placeholder="Phone Number"
              />
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <textarea
                  style={{
                    width: "300px",
                    height: "200px",
                    borderRadius: "10px",
                    padding: "10px",
                    outline: "none",
                    border: "1px solid #C8C8C8",
                  }}
                  type="text"
                  name="description"
                  value={addNotificationDate.description}
                  onChange={onChangenotification}
                  placeholder="Enter message for user"
                />
              </div>
            </div>

            <ButtonContained
              style={{
                width: "300px",
              }}
              onClick={() => onSendNotification()}
            >
              Send Notification
            </ButtonContained>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default Notification;
