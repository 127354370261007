import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import { Helmet } from "react-helmet";
const RentingProperty = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Renting a property | GharWala One step solution for your housing and furniture rental search gharwalah.com </title>

        <meta
          name="description"
          content="Your Key to Hassle-Free Living Spaces. Whether you're seeking a cozy flat, an independent house, or a comfortable PG, our platform offers a diverse range of rental properties."
        />
      </Helmet>

      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Renting a property
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          Welcome to Gharwala Property Rentals – Your Key to Hassle-Free Living
          Spaces. Whether you're seeking a cozy flat, an independent house, or a
          comfortable PG, our platform offers a diverse range of rental
          properties. Owners and brokers can list their spaces with confidence,
          as Gharwala ensures a thorough verification process for authenticity.
          Renting a property has never been easier – explore listings, check
          details, and book your ideal living space, all from the convenience of
          our user-friendly website. Gharwala Property Rentals is not just a
          platform; it's a commitment to providing a seamless renting
          experience. Find your perfect home with Gharwala today!
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default RentingProperty;
