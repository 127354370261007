import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./testimonial.css";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";

import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Modal,
  Switch,
  Breadcrumbs,
} from "@mui/material";
import { Trash } from "lucide-react";
import { LocationCity } from "@mui/icons-material";
import publicReq, { privateReq } from "../../config/axiosConfig";
import { useMediaQuery } from "react-responsive";

function AddTestimonial({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) {
  const [name, setName] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [title, setTitle] = useState("");
  // const [pincode, setPincode] = useState("");

  //for 404 auth
  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  //end 404 auth

  const submitTestimonial = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://gharwala-5466fddd6458.herokuapp.com/api/add-testimonial",
        {
          name,
          description: testimonial,
          title,
        }
      );

      if (response.data.message === "Testimonial created successfully") {
        toast.success("Testimonial added");
        fetchData();
      }

      setName("");
      setTestimonial("");
      setTitle("");
      // setPincode("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [tests, setTests] = useState();


  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  const deleteTest = (id) => {
    privateReq
      .delete(`/delete-testimonial/${id}`)
      .then((res) => {
        toast.success("Testimonial Deleted");
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });

    fetchData();
  };

  const fetchData = () => {
    publicReq
      .get("/get-all-testimonials")
      .then((res) => {
        setTests(res.data.testimonials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />


<div style={{marginLeft:"35px", marginTop:"150px", marginBottom:"0px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

          

            <Typography sx={{ color: "text.primary" }}>
            Testimonials
            </Typography>
          </Breadcrumbs>
          </div>

      
      <div className="testimonial-main">
      <div className="testimonial-container">
        <h6>Create a New Testimonial</h6>
        <form onSubmit={submitTestimonial}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="testimonial">Testimonial:</label>
            <textarea
              id="testimonial"
              value={testimonial}
              onChange={(e) => setTestimonial(e.target.value)}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
       
      </div>


      <div className="pincode-table-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "3px 10px",
            }}
          >
            
            <Typography variant="p" fontWeight="600" color="#2c3e50">
              Testimonial List
            </Typography>
          </Box>

          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                      width: "140px",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Title
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Description
                  </TableCell>
                 

                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tests?.map((testimonal) => (
                  <TableRow
                    key={testimonal?._id}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f8fafc",
                      },
                    }}
                  >
                    <TableCell style={{ padding: "0px 20px" }}>
                      <Typography sx={{ fontWeight: 500, fontSize:"small" }}>
                       {testimonal?.name}
                      </Typography>
                    </TableCell>
                    
                    <TableCell style={{ padding: "1px 20px", fontSize:"small" }}>
                      <Typography variant="caption" color="textSecondary">
                       {testimonal.title}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 0px", fontSize:"small", maxWidth:"300px" }}>
                      <Typography
                        
                        variant="caption"
                        color="textSecondary"

                        
                      >
                       {testimonal?.description}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px", fontSize:"small" }}>
                      <Typography variant="caption" color="textSecondary">
                        <button
                          style={{ background: "none", padding: "5px" }}
                          onClick={() => deleteTest(testimonal?._id)}
                        >
                          <Trash  color="#800000" size={"14px"} />
                        </button>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      
      <Footer />
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
}

export default AddTestimonial;
