import React, { useEffect, useState } from "react";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import ButtonContained from "../../components/Buttons/ButtonContained";
import { Public } from "@mui/icons-material";
import publicReq from "../../config/axiosConfig";
import toast from "react-hot-toast";
import Header from "../../components/Header";
import StickyFooter from "../../components/Footer";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const Index = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const [carryingCost, setCarryingCost] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarryingCost((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getCost = () => {
    publicReq
      .get("/carryingCost")
      .then((res) => {
        console.log(res);
        const { __v, _id, ...rest } = res.data;
        setCarryingCost(rest);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCost();
  }, []);

  const handleSubmit = () => {
    // Here you can submit the carryingCost object to your backend API
    console.log(carryingCost);

    publicReq
      .put("/carryingCost", carryingCost)
      .then((res) => {
        console.log(res);
        if ((res.data.message = "Carrying costs updated successfully")) {
          toast.success("Carrying cost updated successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div style={{ marginTop: "150px", padding: "0 50px" }}>
        <Header
          closeLoginModal={closeLoginModal}
          setCloseLoginModal={setCloseLoginModal}
          setOpenPopUp={setOpenPopUp}
        />

<div style={{marginLeft:"0px", marginTop:"1px", marginBottom:"10px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

            <Link underline="hover" color="inherit" to="/admin-settings">
              Admin Setting
            </Link>

            <Typography sx={{ color: "text.primary" }}>
              Carrying Cost
            </Typography>
          </Breadcrumbs>
          </div>

        <div
          style={{
            fontSize: "20px",
            fontFamily: "Montserrat",
            marginBottom:"20px",
          }}
        >
          Carrying Cost
        </div>
        <div>
          <div>
            <div style={{ marginLeft: "7px" }}>
              {[1, 2, 3, 4, 5].map((number) => (
                <div key={number}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <label>{`Product ${number} INR `}</label>
                    <OutlinedFormInput
                      style={{
                        width: "100px",
                        marginLeft: number===1 ? "4px": "0px",
                      }}
                      type="text"
                      name={`product${number}`}
                      value={carryingCost[`product${number}`]}
                      onChange={handleChange}
                      placeholder={`Product ${number} carrying cost`}
                    />
                  </div>
                </div>
              ))}
            </div>

            <ButtonContained
              style={{
                width: "150px",
              }}
              onClick={handleSubmit}
            >
              SUBMIT
            </ButtonContained>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }}></div>
      <StickyFooter />
    </>
  );
};

export default Index;
