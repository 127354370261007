import React from "react";
import "./backlog.css";
import { Link } from "react-router-dom";
import calender from "../../assets/images/calender-icon.png";
import phoneDiary from "../../assets/images/phoneDiary.png";
import property from "../../assets/images/property.png";
import Header from "../../components/Header";
import StickyFooter from "../../components/Footer";


function BacklogEnteries({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) {
  return (
    <div style={{marginTop: "20px"}}>
      <Header />
      <div  className="backlog-head">Backlog Enteries</div>

      <div className="backlog-each-box">
      <div className="image-box">
        <Link to="/admin/backlog-enteries">
          <img className="tab img-admin" src={phoneDiary} alt="tab" />
        </Link>
        <div className="img-admin-name">Enquiry</div>
      </div>

      <div className="image-box">
        <Link to="/admin/backlog-enteries">
          <img className="tab img-admin" src={property} alt="tab" />
        </Link>
        <div className="img-admin-name">Property</div>
      </div>

      <div className="image-box">
        <Link to="/admin/backlog-enteries">
          <img className="tab img-admin" src={calender} alt="tab" />
        </Link>
        <div className="img-admin-name">Backlog</div>
      </div>
      </div>
      <StickyFooter />
    </div>
  );
}

export default BacklogEnteries;
