import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router
import RightSideImage from "../../assets/images/Hero.png";
import { getProperties } from "../../redux/action/property";
import SearchBar from "../SearchBar/SearchBar";
import "./index.css";

const PropertySection = () => {
  // State to manage input values
  const [location, setLocation] = useState("Kolkata, India");
  const [moveInDate, setMoveInDate] = useState("");
  const [wantTo, setWantTo] = useState(""); // State to track rent or buy
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch properties when the component mounts
    dispatch(getProperties());
  }, [dispatch]);

  const handleContact = (id) => {
    // Navigate to the properties details page
    navigate(`/properties/${id}`);
  };

  const handleRentClick = () => {
    setWantTo("rent"); // Set the state to rent
  };

  const handleBuyClick = () => {
    setWantTo("buy"); // Set the state to buy
  };

  return (
    <div className="hero-new">
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left Side Content */}
        <div style={{ flex: 1 }}>
          <h1 className="responsive-heading">
            List your property
            <br /> for rent and sale
            <br /> for free
          </h1>

          <p className="responsive-paragraph">
            Trusted platform to sell and rent properties <br />
            with rent agreement
          </p>

          {/* Metrics */}
          <div style={{ display: "flex", gap: "40px", marginBottom: "40px" }}>
            <div className="stat-container">
              <span>10,000+</span>
              <p>Renters</p>
            </div>
            <div className="stat-container">
              <span>2,000+</span>
              <p>Properties</p>
            </div>
          </div>

          {/* Rent and Buy Tabs */}
          <div style={{ display: "flex", marginTop: "60px" }}>
            <button
              onClick={handleRentClick} // Set the wantTo state to rent
              className="responsive-box"
            >
              Properties
            </button>
            {/* <button
              onClick={handleBuyClick} // Set the wantTo state to buy
              style={{
                padding: '10px 20px',
                backgroundColor: '#FFF',
                marginRight: '5px',
                borderRadius: '8px',
                color: '#B90000',
                fontWeight: 'bold',
                marginLeft: '0px',
              }}
            >
              Buy
            </button> */}
          </div>

          {/* Location and Date Selection */}

         
          <div
            className="search-box "
          >
             
            <SearchBar />
          </div>
        </div>

        {/* Right Side Image */}
        {/* You can add additional content for the right side here if needed */}
      </div>
    </div>
  );
};

export default PropertySection;
