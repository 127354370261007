import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
} from "@mui/material";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop.js";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import { Pencil, Trash } from "lucide-react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

let rentalsList = ["12M", "8M", "6M", "3M", "2M", "1M"];

const RentalAssets = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    status: "Active",
    productMaster: "",
    brand: "",
    specification: "",
    images: [],
    serialNumber: "",
    securityDeposit: "",
    installationCharges: "",
    rentals: "",
    stock: "",
  });
  const [enquiries, setEnquiries] = useState([]);
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    fetchEnquiries();
    setHeaderHeight(headerRef.current.offsetHeight);
  }, []);

  const fetchEnquiries = async () => {
    try {
      const response = await privateReq.get("/rental-asset");
      setEnquiries(response.data.data);
    } catch (err) {
      toast.error("Failed to fetch rental assets");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      name: "",
      status: "Active",
      productMaster: "",
      brand: "",
      specification: "",
      images: [],
      serialNumber: "",
      securityDeposit: "",
      installationCharges: "",
      rentals: "",
      stock: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (formData._id) {
        response = await privateReq.put(
          `/rental-asset/${formData._id}`,
          formData
        );
      } else {
        response = await privateReq.post("/rental-asset", formData);
      }

      if (response.data) {
        toast.success(
          formData._id
            ? "Rental asset updated successfully"
            : "Rental asset added successfully"
        );
        resetForm();
        fetchEnquiries();
        setOpenModal(false);
      }
    } catch (err) {
      console.error("Error adding rental asset:", err);
      toast.error(err.response?.data?.message || "Failed to add rental asset");
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleSwitchChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      status: event.target.checked ? "Active" : "Inactive",
    }));
  };

  const onEdit = (id) => {
    setFormData(enquiries.find((enquiry) => enquiry._id === id));
    setOpenModal(true);
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this rental asset?")) {
      privateReq
        .delete(`/rental-asset/${id}`)
        .then(() => {
          fetchEnquiries();
          toast.success("Rental asset deleted successfully");
        })
        .catch((err) => {
          toast.error(
            err.response?.data?.message || "Failed to delete rental asset"
          );
        });
    }
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const uploadedImages = [];
    try {
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);

          const response = await axios.post(
            `https://gharwala-5466fddd6458.herokuapp.com/api/upload-image`,
            formData
          );
          if (response.data) {
            uploadedImages.push(response.data);
            toast.success("Image uploaded successfully");
          } else {
            toast.error("Failed to upload image");
          }
        })
      );

      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...uploadedImages].slice(0, 5),
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    }
  };

  return (
    <>
      <ScrollToTop />
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <Box
        sx={{
          p: 4,
          maxWidth: "100%",
          mt: `${headerHeight}px`,
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)",
          minHeight: "100vh",
        }}
      >
      <div style={{ marginLeft: "10px", marginTop: "10px", marginBottom: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
           
            to="/admin"
          >
            Dashboard
          </Link>

          <Typography sx={{ color: "text.primary" }}>Rental Assets</Typography>
        </Breadcrumbs>
      </div>

        <Paper
          elevation={3}
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "16px",
            background: "white",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            mb: 4,
          }}
        >
          <Box
            sx={{
              p: 1,
              borderBottom: "1px solid #eee",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "3px 10px",
              }}
            >
              <PeopleIcon sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                Rental Assets
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenModal(true)}
              sx={{
                borderRadius: "12px",
                textTransform: "none",
                background: "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                "&:hover": {
                  background:
                    "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                },
              }}
            >
              Add New Rental Assets
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                      width: "140px",
                    }}
                  >
                    name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    productMaster
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    brand
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    specification
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    serialNumber
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    securityDeposit
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    installationCharges
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    rentals
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    stock
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Created At
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enquiries.map((enquiry) => (
                  <TableRow
                    key={enquiry._id}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f8fafc",
                      },
                    }}
                  >
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.name}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.status}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.productMaster}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.brand}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.specification}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.serialNumber}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.securityDeposit}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.installationCharges}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.rentals}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.stock}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {formatDate(enquiry.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        <button
                          className="delete-button"
                          onClick={() => onEdit(enquiry._id)}
                        >
                          <Pencil size={"14px"} color="#000000" />
                        </button>
                        <button
                          className="delete-button"
                          onClick={() => onDelete(enquiry._id)}
                        >
                          <Trash size={"14px"} color="#990000" />
                        </button>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            resetForm();
          }}
          aria-labelledby="add-enquiry-modal"
          aria-describedby="modal-to-add-new-enquiry"
        >
          <Paper sx={modalStyle}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 0 }}>
              <PersonAddIcon sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                {formData._id ? "Update Rental Asset" : "Add Rental Asset"}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Status
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.status === "Active"}
                      onChange={handleSwitchChange}
                      name="status"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formData.status ? "Active" : "Inactive"}
                    </Typography>
                  }
                />
              </Box>
              <TextField
                fullWidth
                label="productMaster"
                name="productMaster"
                value={formData.productMaster}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="brand"
                name="brand"
                value={formData.brand}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="specification"
                name="specification"
                value={formData.specification}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="serialNumber"
                name="serialNumber"
                value={formData.serialNumber}
                onChange={handleInputChange}
                margin="normal"
                type="number"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />

              <TextField
                fullWidth
                label="stock"
                name="stock"
                required
                margin="normal"
                type="number"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="securityDeposit"
                name="securityDeposit"
                value={formData.securityDeposit}
                onChange={handleInputChange}
                margin="normal"
                required
                type="number"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="installationCharges"
                name="installationCharges"
                value={formData.installationCharges}
                onChange={handleInputChange}
                type="number"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="stock"
                name="stock"
                value={formData.stock}
                onChange={handleInputChange}
                type="number"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <Select
                fullWidth
                label="rentals"
                name="rentals"
                value={formData.rentals}
                onChange={handleInputChange}
                margin="normal"
                required
              >
                {rentalsList.map((rental) => (
                  <MenuItem key={rental} value={rental}>
                    {rental}
                  </MenuItem>
                ))}
              </Select>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                  id="image-upload"
                />
                <label htmlFor="image-upload">
                  <Button
                    component="span"
                    sx={{
                      mt: 1,
                      height: "48px",
                      borderRadius: "12px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                    variant="contained"
                  >
                    Pick Images
                  </Button>
                </label>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {formData.images.map((image, index) => (
                    <Box key={index} sx={{ position: "relative" }}>
                      <img
                        src={image}
                        alt={`Rental Asset Image ${index + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                      <Button
                        onClick={() => {
                          setFormData((prev) => ({
                            ...prev,
                            images: prev.images.filter((_, i) => i !== index),
                          }));
                        }}
                        sx={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          minWidth: "24px",
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          p: 0,
                          bgcolor: "error.main",
                          color: "white",
                          "&:hover": {
                            bgcolor: "error.dark",
                          },
                        }}
                      >
                        ×
                      </Button>
                    </Box>
                  ))}
                </Box>
                {formData.images.length > 0 && (
                  <Typography variant="caption" color="text.secondary">
                    {formData.images.length}/5 images
                  </Typography>
                )}
              </Box>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 1,
                  height: "48px",
                  borderRadius: "12px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  background:
                    "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                  boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                  "&:hover": {
                    background:
                      "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                  },
                }}
              >
                {formData._id ? "Update Rental Asset" : "Add Rental Asset"}
              </Button>
            </form>
          </Paper>
        </Modal>
      </Box>
    </>
  );
};

export default RentalAssets;
