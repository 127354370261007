import React, { useEffect, useState } from "react";
import "./BlogForm.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import Header from "../Header.js";
import Footer from "../Footer.js";
import publicReq, { privateReq } from "../../config/axiosConfig.js";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect.js";
import { useNavigate } from "react-router-dom";

function EditBlog({
  blogId,
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const navigate = useNavigate();
  const [author, setAuthor] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [captchaToken, setCaptchaToken] = useState("");
  const [blog, setBlog] = useState(null);
  const [tags, setTags] = useState([]);

  const uploadFile = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const getBlog = () => {
    publicReq
      .get(`/blogs/${blogId}`)
      .then((res) => {
        setBlog(res.data);
        setTitle(res.data.title);
        if(res.data.metaTitle==="undefined"){
          setMetaTitle("");
         
        }
        else{
          setMetaTitle(res.data.metaTitle);
        }

        if(res.data.metaDescription==="undefined"){
         setMetaDescription("");
         
        }
        else{
          setMetaDescription(res.data.metaDescription);
        }
        
        
        setAuthor(res.data.author);
        setShortDescription(res.data.shortDescription);
        setTags(JSON.parse(res.data.tags) || []);
        const contentState = convertFromRaw(JSON.parse(res.data.blogContent));
        setEditorState(EditorState.createWithContent(contentState));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (blogId) {
      getBlog();
    }
  }, []);

  function onChange(value) {
    setCaptchaToken(value);
  }

  const onEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleTagInput = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      e.target.value = '';
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const submitFiles = (event) => {
    event.preventDefault();

    if (!title || !author || !shortDescription || !editorState) {
      alert("Please fill in all fields");
      return;
    }

    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const formData = new FormData();
    formData.append("file", image);
    formData.append("title", title);
    formData.append("author", author);
    formData.append("shortDescription", shortDescription);
    formData.append("blogContent", JSON.stringify(rawContentState));
    formData.append("metaTitle", metaTitle);
    formData.append("metaDescription", metaDescription);
    formData.append("tags", JSON.stringify(tags));

    privateReq
      .put(`/blogs/${blogId}`, formData)
      .then((res) => {
        toast.success("Blog Edited");
        navigate("/blogs");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          maxWidth: "1600px",
          width: "90%",
          marginTop: "150px",
          marginBottom: "40px",
        }}
        className="blog-form-container"
      >
        <h5>Edit Blog Post</h5>
        <form onSubmit={submitFiles}>
          <div className="form-group">
            <img
              src={blog?.imageUrl}
              style={{ height: "400px", width: "100%" }}
              alt="blogimg"
            />
            <label htmlFor="image">Upload Image:</label>
            <input
              type="file"
              id="image"
              onChange={uploadFile}
              accept="image/*"
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="author">Author:</label>
            <input
              type="text"
              id="author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="metaTitle">Meta Title:</label>
            <input
              type="text"
              id="metaTitle"
              value={metaTitle}
              onChange={(e) => setMetaTitle(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="tags">Tags:</label>
            <div className="tags-input-container">
              <div className="tags-display">
                {tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                    <button type="button" onClick={() => removeTag(tag)}>&times;</button>
                  </span>
                ))}
              </div>
              <input
                type="text"
                id="tags"
                placeholder="Type a tag and press Enter"
                onKeyDown={handleTagInput}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="metaDescription">Meta Description:</label>
            <textarea
              id="metaDescription"
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="shortDescription">Short Description:</label>
            <textarea
              id="shortDescription"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mainContent">Main Content:</label>
            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorChange}
              editorClassName="editor-wrapper"
            />
          </div>
          <div className="form-group">
            <label htmlFor="tags">Tags:</label>
            <div className="tags-input-container">
              <div className="tags-display">
                {tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                    <button type="button" onClick={() => removeTag(tag)}>&times;</button>
                  </span>
                ))}
              </div>
              <input
                type="text"
                id="tags"
                placeholder="Type a tag and press Enter"
                onKeyDown={handleTagInput}
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <button type="submit">Submit</button>
            <Link to="/blogs" className="view-blogs-button">
              <button type="button">View Blogs</button>
            </Link>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default EditBlog;
