import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { privateReq } from "../../config/axiosConfig";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import { FileX, Settings, Trash } from "lucide-react";
import { BellDot } from "lucide-react";
import { User } from "lucide-react";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";
import "./index.css";

import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Modal,
  Switch,
  Breadcrumbs,
} from "@mui/material";
import { LocationCity } from "@mui/icons-material";

const Pincodes = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [pincodes, setPincodes] = useState();
  const [loading, setLoading] = useState(false);

  const [addPincode, setAddPincode] = useState({
    pincode: "",
    state: "",
    country: "",
    countryCode: "",
  });
  const [deletePincode, setDeletePincode] = useState(false);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    display:"flex",
    borderRadius:"7px",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    boxShadow: 24,
    p: 4,
  };

  
  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  useEffect(() => {
    (async function () {
      const res = await privateReq.get("/get-pincodes");
      setPincodes(res.data.data);
    })();
  }, [, deletePincode]);

  const onChangeAddPincode = (e) => {
    const { name, value } = e.target;
    setAddPincode((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddPincode = async () => {
    try {
      setLoading(true);
      const res = await privateReq.post("/add-pincode", addPincode);
      setAddPincode({
        pincode: "",
        state: "",
        country: "",
        countryCode: "",
      });
      setDeletePincode(!deletePincode);
       

      
    } catch (err) {
      console.log(err);
    }
    finally{
      setLoading(false);
    }
  };

  const handleDeletePincode = async (id) => {
    try {
      const res = await privateReq.delete("/delete-pincode/" + id);
      toast.success("Deleted Successfully");
      setDeletePincode(!deletePincode);
    } catch (err) {
      console.log(err);
    }
  };

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />


<div style={{marginLeft:"50px", marginTop:"150px", marginBottom:"10px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

          

            <Typography sx={{ color: "text.primary" }}>
             Pincode
            </Typography>
          </Breadcrumbs>
          </div>

      <div
        className="flex1"
        style={{
          marginTop: "10px",
          padding: "0 50px",
          gap: "1rem",
        }}
      >

        
        <div className="pincode-container">
          <div style={{ marginLeft: "7px", marginBottom: "10px" }}>
            Add Pin code
          </div>

          <div className="input-container" style={{ marginLeft: "7px" }}>
            <OutlinedFormInput
              className="pin-input"
              type="text"
              name="pincode"
              value={addPincode.pincode}
              onChange={onChangeAddPincode}
              placeholder="Pin code"
            />
            <OutlinedFormInput
              className="pin-input"
              type="text"
              name="state"
              value={addPincode.state}
              onChange={onChangeAddPincode}
              placeholder="State"
            />

            <OutlinedFormInput
              className="pin-input"
              type="text"
              name="country"
              value={addPincode.country}
              onChange={onChangeAddPincode}
              placeholder="Country"
            />
            <OutlinedFormInput
              className="pin-input"
              type="text"
              name="countryCode"
              value={addPincode.countryCode}
              onChange={onChangeAddPincode}
              placeholder="Country Code"
            />
          </div>

          <ButtonContained className="w-btn" onClick={handleAddPincode}>
            {loading ? "Adding..." : " Add Pin code"}
          </ButtonContained>
        </div>

        <div className="pincode-table-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "3px 10px",
            }}
          >
            <LocationCity sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
            <Typography variant="p" fontWeight="600" color="#2c3e50">
              Pincode List
            </Typography>
          </Box>

          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                      width: "140px",
                    }}
                  >
                    Pincode
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    State
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Country
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Country Code
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pincodes?.map((pincode) => (
                  <TableRow
                    key={pincode._id}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f8fafc",
                      },
                    }}
                  >
                    <TableCell style={{ padding: "0px 20px" }}>
                      <Typography sx={{ fontWeight: 500, fontSize:"small" }}>
                        {pincode.pincode}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography style={{fontSize:"small"}}>{pincode.state}</Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px", fontSize:"small" }}>
                      <Typography variant="caption" color="textSecondary">
                        {pincode?.country}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px", fontSize:"small" }}>
                      <Typography
                        style={{ marginLeft: "35px" }}
                        variant="caption"
                        color="textSecondary"
                      >
                        {pincode?.countryCode}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ padding: "1px 20px", fontSize:"small" }}>
                      <Typography variant="caption" color="textSecondary">
                        <button
                          style={{ background: "none", padding: "5px" }}
                          onClick={() => handleDeletePincode(pincode._id)}
                        >
                          <Trash color="#800000" size={"14px"} />
                        </button>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  ) : (
    // <h1 style={{ margin: " 250px 10px", textAlign: "center" }}>404 ERROR</h1>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default Pincodes;
