import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Chip,
  InputAdornment,
  Breadcrumbs,
} from "@mui/material";
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const GoogleConsole = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [indexedUrls, setIndexedUrls] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [filteredUrls, setFilteredUrls] = useState([]);

  useEffect(() => {
    onGetAllUrlIndexing();
  }, []);

  useEffect(() => {
    let filtered = [...indexedUrls];

    if (statusFilter !== "all") {
      filtered = filtered.filter(
        (item) => item.indexingStatus === statusFilter
      );
    }

    setFilteredUrls(filtered);
  }, [searchQuery, statusFilter, indexedUrls]);

  const onGetAllUrlIndexing = async () => {
    try {
      setLoading(true);
      const response = await privateReq.get("/property-get-indexing");
      setIndexedUrls(response.data.property);
      setFilteredUrls(response.data.property);
    } catch (err) {
      setError("Failed to fetch indexed URLs");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitUrl = async () => {
    try {
      setLoading(true);
      setError(null);
      await privateReq.post("/url-indexing/submit", { url });
      onGetAllUrlIndexing();
      toast.success("URL submitted successfully");
    } catch (err) {
      setError(
        err.response?.data?.message ||
          "An error occurred while submitting the URL"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleReindex = async (urlToIndex) => {
    try {
      setLoading(true);
      await privateReq.post("/url-indexing/submit", { url: urlToIndex });
      toast.success("URL reindexed successfully");
      onGetAllUrlIndexing();
    } catch (err) {
      setError("Failed to reindex URL");
    } finally {
      setLoading(false);
    }
  };

  const getStatusChipColor = (status) => {
    switch (status?.toLowerCase()) {
      case "indexed":
        return "success";
      case "pending":
        return "warning";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />



      <div  style={{marginTop:"140px", marginLeft:"20px", marginRight:"20px"}}>

      <div style={{marginLeft:"0px", marginTop:"1px", marginBottom:"10px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>
           <Typography sx={{ color: "text.primary" }}>
             Google console
            </Typography>
          </Breadcrumbs>
          </div>

        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Google Indexing Console
          </Typography>

          <Box sx={{ mb: 4 }}>
            <TextField
              fullWidth
              label="URL"
              variant="outlined"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com/page"
              sx={{ mb: 2 }}
            />

            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleSubmitUrl}
                disabled={loading || !url}
              >
                Submit URL for Indexing
              </Button>
            </Box>
          </Box>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status Filter</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  label="Status Filter"
                >
                  <MenuItem value="all">All Status</MenuItem>
                  <MenuItem value="indexed">Indexed</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="failed">Failed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={onGetAllUrlIndexing}
                disabled={loading}
              >
                Refresh List
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Last Indexed</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUrls &&
                  filteredUrls
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            maxWidth: 300,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          https://www.gharwalah.com/properties/
                          {item.route_title}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={item?.indexingStatus || "Unknown"}
                            color={getStatusChipColor(item?.indexingStatus)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          {item?.lastIndexedAt
                            ? new Date(item?.lastIndexedAt).toLocaleString()
                            : "Never"}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleReindex(`https://www.gharwalah.com/properties/
                          ${item.route_title}`)
                            }
                            disabled={loading}
                            color="primary"
                            title="Reindex URL"
                          >
                            <RefreshIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            component="div"
            count={filteredUrls.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />

          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
              <CircularProgress />
            </Box>
          )}

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Paper>
      </div>
      <Footer />
    </>
  );
};

export default GoogleConsole;
