import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import StickyFooterMobile from "../../../components/FooterMobile";
import StickyFooter from "../../../components/Footer";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
const RefferalTerms = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <Helmet>
        <title>Referral Terms and Conditions | GharWala One step solution for your housing and furniture rental search gharwalah.com </title>
        <meta
          name="description"
          content="Referrers will receive a unique referral link to share with potential Referees."
        />
      </Helmet>

      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ padding: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Referral Terms and Conditions{" "}
        </h2>
        <h3>
          These Referral Terms and Conditions ("Terms") govern the referral
          program ("Program") offered by Gharwalah.com. Please read these Terms
          carefully before participating in the Program. By participating, you
          agree to abide by the
        </h3>
        <div>
          <br />
          <h4 className="h4-about-tems">1. Eligibility:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            Referrers and Referees must be individuals who are at least 18 years
            of age.
          </p>
          <br />
          <h4 className="h4-about-tems">2. How the Program Works: </h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - Gharwalah.com ("Service Provider") offers rental services to you
            ("Renter") under the terms and conditions outlined herein. - The
            rental agreement commences when the Renter places an order and is
            confirmed by the Service Provider.
          </p>
          <br />
          <h4 className="h4-about-tems"> 2. Rental Period:</h4>
          <br />
          <p className="para-about-terms">
            - Referrers will receive a unique referral link to share with
            potential Referees. - Referees must use the provided referral link
            during the registration process. -To be eligible for a referral
            reward, the Referee must complete a qualifying action, such as
            making a first rental payment. - Referrers will receive their
            referral reward after the Referee completes the qualifying action
          </p>
          <br />
          <h4 className="h4-about-tems"> 3. Rental Payments:</h4>
          <p className="para-about-terms">
            {" "}
            - The Renter agrees to make all rental payments on a prepaid basis.
            - The rental fees are payable monthly or by the agreed-upon payment
            schedule.
          </p>
          <br />
          <h4 className="h4-about-tems">3. Referral Rewards:</h4>
          <br />
          <p className="para-about-terms">
            - The Referrer will receive the stated reward as specified in the
            Program for each successful referral. - Rewards may be in the form
            of account credits, discounts, or other forms specified in the
            Program
          </p>
          <br />
          <h4 className="h4-about-tems"> 4. Restrictions:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            -Self-referrals are not eligible for rewards. - The Program may not
            be used for commercial purposes. - Referrals from existing users or
            fraudulent accounts are not eligible for rewards.
          </p>
          <br />
          <h4 className="h4-about-tems"> 5. Redemption and Expiration:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            -Referral rewards must be redeemed within the specified time frame,
            as detailed in the Program. -Expired rewards will not be reissued
          </p>
          <br />
          <h4 className="h4-about-tems"> 6. Termination and Modification:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - The Service Provider reserves the right to terminate, modify, or
            suspend the Program at any time for any reason. - Referrers and
            Referees will be informed in advance of any significant changes to
            the Program
          </p>
          <br />
          <h4 className="h4-about-tems"> 7. Liability</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            -The Service Provider is not responsible for any loss, damage, or
            injury resulting from participation in the Program.
          </p>
          <br />
          <h4 className="h4-about-tems"> 8. Governing Law:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - These Terms are governed by the laws of [Specify jurisdiction].
            Any disputes will be resolved in the courts of this jurisdiction.
          </p>
          <br />
          <h4 className="h4-about-tems">9. Amendments:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - The Service Provider reserves the right to amend these Terms at
            any time. Participants will be informed of any changes in advance.
          </p>
          <br />
          <h4 className="h4-about-tems"> 10. Entire Agreement:</h4>
          <br />
          <p className="para-about-terms">
            {" "}
            - These Terms constitute the entire agreement between the parties
            and supersede any prior agreements or understandings. By
            participating in the Program, you acknowledge your understanding and
            agreement with these Terms. If you have any questions or concerns,
            please contact us for clarification.
          </p>
        </div>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default RefferalTerms;
