import { SET_FEATURED_PROPERTIES } from "./types";

import { getFeaturedPropertiesService } from "../../config/property";

export const getFeaturedProperties = (data) => async (dispatch) => {
  try {
    const res = await getFeaturedPropertiesService(data);
    dispatch({
      type: SET_FEATURED_PROPERTIES,
      payload: res.data,
    });
    console.log(res, "featured");
  } catch (e) {
    console.log(e);
  }
};
