import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./SingleBlog.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Header from "../Header";
import { useMediaQuery } from "react-responsive";
import { Height } from "@mui/icons-material";
import { Helmet } from "react-helmet";
const SingleBlogPage = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { blogId } = useParams();

  const fetchBlog = async () => {
    try {
      const response = await axios.get(
        // `http://localhost:5000/api/blogs/${blogId}`
        `https://gharwala-5466fddd6458.herokuapp.com/api/blogs/${blogId}`
      );
      console.log(response.data);
      setBlog(response.data);
    } catch (error) {
      console.error("Error fetching blog:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, [blogId]);

  useEffect(() => {
    if (blog && blog.blogContent) {
      try {
        const contentState = convertFromRaw(JSON.parse(blog.blogContent));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Error parsing blog content:", error);
      }
    }
  }, [blog]);

  const renderContent = () => {
    if (loading) {
      return <div className="loading-state">Loading amazing content...</div>;
    }

    if (!blog) {
      return (
        <div className="error-state">
          Blog post not found. Please check the URL and try again.
        </div>
      );
    }

    return (
      <div key={blog.id} className="blog-item">
        <Helmet>
      <title>{blog.title}</title>
      <description>{blog.shortDescription} Gharwala your One step solution for your housing and furniture rental search gharwalah.com</description>
        </Helmet>
        <div className="header-container">
          <div className="title-blog">
            <h1 className="title-h1">{blog.title}</h1>
          </div>
          <div className="short-desc">{blog.shortDescription}</div>
        </div>
        <div className="blog-image">
          <img
            src={blog.imageUrl}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg";
            }}
            alt={blog.title}
            loading="lazy"
          />
          <div className="author-info">
            <p>Written by {blog.author}</p>
          </div>
        </div>
        <div className="content-container">
          <div className="blog-content">
            <Editor
              editorState={editorState}
              readOnly={true}
              toolbarHidden
              editorStyle={{
                padding: "20px",
                minHeight: "300px",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="single-blog-container">{renderContent()}</div>
    </>
  );
};

export default SingleBlogPage;
