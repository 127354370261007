import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getProperties } from "../../redux/action/property";
import { useMediaQuery } from "react-responsive";
import { Watermark } from "@hirohe/react-watermark";
import { Pagination, Stack } from "@mui/material";
import PropertiesCard from "../../components/PropertiesCard/index.js";
import { Helmet } from "react-helmet";
import "./index.css";
import { Slider } from "@mui/material";

function capitalizeFLetter(word) {
  if (word) {
    return word[0].toUpperCase() + word.slice(1);
  }
}

const Properties = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const latFromUrl = urlParams.get("lat");
  const lngFromUrl = urlParams.get("lng");
  const locationFromUrl = urlParams.get("location");
  const propertyTypeFromUrl = urlParams.get("propertyType");
  const wantToFromUrl = urlParams.get("wantto");
  const bedroomsFromUrl = urlParams.get("bedrooms");
  const dispatch = useDispatch();
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const { properties } = useSelector((state) => state.property);
  const { user, location: userLocation } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [searchQuery, setSearchQuery] = useState("");
  const [propertiesPerPage] = useState(user?.role === "admin" ? 50 : 12);
  const [selectedRange, setSelectedRange] = useState(3); // Default 3km range

  useEffect(() => {
    if (latFromUrl && lngFromUrl) {
      const coordinates = {
        latitude: parseFloat(latFromUrl),
        longitude: parseFloat(lngFromUrl),
      };
      const queryParams = new URLSearchParams();
      queryParams.append("latitude", coordinates.latitude);
      queryParams.append("longitude", coordinates.longitude);
      queryParams.append("propertyType", propertyTypeFromUrl);
      queryParams.append("wantto", wantToFromUrl);
      queryParams.append("bedrooms", bedroomsFromUrl);

      dispatch(getProperties("?" + queryParams.toString()));
    }
  }, [latFromUrl, lngFromUrl]);

  function removeLocations(inputString) {
    if (inputString) {
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();
      return modifiedString;
    }
    return inputString;
  }

  function deleteProperty(id) {}

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  const [currentPage, setCurrentPage] = useState(1);
  const propertyList = properties.length ? properties.filter((property) => property.status === true && property.listingStatus === false) : [];

  // Function to calculate distance between two coordinates using Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  // First filter the properties based on search and range
  const filteredProperties = propertyList.filter((property) => {
    const matchesSearch = !searchQuery ? true : (
      property.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.desc?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.locality?.coordinates[2]?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const withinRange = latFromUrl && lngFromUrl ? 
      calculateDistance(
        parseFloat(latFromUrl),
        parseFloat(lngFromUrl),
        property.locality.coordinates[1],
        property.locality.coordinates[0]
      ) <= selectedRange : true;

    return matchesSearch && withinRange;
  });

  // Then handle pagination of filtered results
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  const totalPageCount = Math.ceil(filteredProperties.length / propertiesPerPage);

  // Reset page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedRange, searchQuery]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    scrollToTop();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {`Properties Nearby ${locationFromUrl} | GharWala One step solution for your housing and furniture rental search gharwalah.com`}{" "}
        </title>
        <description>
          GharWala One step solution for your housing and furniture rental
          search gharwalah.com
        </description>
      </Helmet>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div style={{ marginTop: "40px" }} className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          <div className="row mx-0 w-100">
            <div className={` ${"col-lg-12 col-md-12 col-xs-12"} `}>
              <div className="row properties-list mx-0">
                <div className="row properties-header mt-5 px-3">
                  <div className="col-lg-6 col-md-6 col-sm-12 heading-properties">
                    <span>Nearby Properties</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 range-selector">
                    <h3>Distance Range</h3>
                    <div className="range-labels">
                      <span>{selectedRange} km</span>
                      <Slider
                        value={selectedRange}
                        onChange={(e, newValue) => setSelectedRange(newValue)}
                        min={1}
                        max={10}
                        step={1}
                        className="range-slider"
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `${value}km`}
                      />
                      <span>10 km</span>
                    </div>
                  </div>
                </div>
                <div>
                  <PropertiesCard
                    currentProperties={currentProperties}
                    isMobile={isMobile}
                    navigate={navigate}
                    deleteProperty={deleteProperty}
                    user={user}
                    removeLocations={removeLocations}
                    capitalizeFLetter={capitalizeFLetter}
                    Watermark={Watermark}
                  />
                </div>
                <Stack
                  spacing={2}
                  alignItems="center"
                  marginTop={4}
                  marginBottom={6}
                >
                  {totalPageCount > 1 && (
                    <Pagination
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  )}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Properties;
