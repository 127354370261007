import React, { useEffect, useState } from "react";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import Carousel from "react-simply-carousel";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { TestimonialsImage } from "../../assets/images/testimonials.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const Testimonials = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState([]);
 // Custom arrow components
 const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "100%",
        left: "45%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        zIndex: 1,
        color: "#fff",
        display: isMobile ? "none" : "flex",
        backgroundColor: "white",
        borderRadius: "50%",
        padding: "10px",
        "&:hover": { backgroundColor: "#c5c6c6" },
      }}
    >
      <FaAngleLeft color="black" size={24} />
    </Box>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "100%",
        right: "45%",
        transform: "translateY(-50%)",
        display: isMobile ? "none" : "flex",
        cursor: "pointer",
        zIndex: 1,
        color: "#fff",
        backgroundColor: "white",
        borderRadius: "50%",
        padding: "10px",
        "&:hover": { backgroundColor: "#c5c6c6 " },
      }}
    >
      <FaAngleRight color="black" size={24} />
    </Box>
  );
};

  

  var settings = {
    dots: isMobile ? false : false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile? 1 :3,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Time in milliseconds between slides (3 seconds)
    prevArrow: <CustomPrevArrow />, // Custom Previous Arrow
    nextArrow: <CustomNextArrow />, // Custom Next Arrow
  };

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://gharwala-5466fddd6458.herokuapp.com/api/get-all-testimonials"
        );
        setData(response.data.testimonials);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };
    fetchTestimonials();
  }, []);

  return (
    <Box
      sx={{
        padding: isMobile ? "50px 0" : "50px 100px",
        paddingBottom: isMobile? "0px" : "50px",
        height: isMobile? "450px" : "auto",
        backgroundImage: "url('/assests/images/bg-test.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        overflow: "hidden",
        // backgroundColor: "#00436A",
      }}
    >
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={{
          fontFamily: "Montserrat",
          textAlign: "center",
          marginBottom: "20px",
          color: "white",
          fontWeight: "bold",
        }}
      >
        Our Happy Customers
      </Typography>
      <Box
        sx={{
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          position: "relative",
          maxWidth: "1880px",
          margin: "auto",
        }}
      >
        <Slider {...settings}>
          {data.map((item, index) =>
            item.pincode ? null : (
              <Box
                key={index}
                sx={{
                  border: "none",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  overflowY: "scroll",
                }}
              >
                
                <Box
                  sx={{
                    // width: isMobile ? "300px" : "800px",
                    height: isMobile? "270px" : "320px",
                    padding: "30px",
                    border: "1px solid #9A291B",
                    borderRadius: "15px",
                    position: "relative",
                    margin: "20px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    justifyContent:"center",
                    backgroundColor: "white",
                    overflow:"hidden" // Add transparency
                  }}
                >
                  <div 
                  style={{
                    display:"flex",
                    justifyContent:"center"
                  }}
                  >
                  <Avatar  sx={{ bgcolor: "#b90000" }}>
                    {item?.name[0]}
                  </Avatar>
                  </div>
                  {/* <Avatar sx={{ bgcolor: deepPurple[500] }}>OP</Avatar> */}
                  <Box sx={{ marginTop: "10px" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      {/* {item.title} */}
                    </Typography>
                    <Typography>{item.description}</Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: isMobile ? "14px" : "20px",
                      marginTop: "20px",
                      fontWeight: "600",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      marginTop: "20px",
                      fontWeight: "600",
                    }}
                  >
                    {item.pincode}
                  </Typography>
                </Box>
              </Box>
            )
          )}
        </Slider>
      </Box>
    </Box>
  );
};

export default Testimonials;
