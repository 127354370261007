import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import HomeBottomSection from "../../components/HomeBottomSections/HomeBottomSection";
import HomeAbouSection from "../../components/HomeAboutSection/HomeAboutSection";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import AdminToggleSection from "../../components/AdminToggleSection";
import { checkTabsStatus } from "../../config/user";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

const TabsAccess = ({
  isLogin,
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  return showPage ? (
    <div>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />

<div style={{marginLeft:"15px", marginTop:"150px", marginBottom:"10px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

            <Link underline="hover" color="inherit" to="/admin-settings">
              Admin Setting
            </Link>

            <Typography sx={{ color: "text.primary" }}>
             Tabs Access
            </Typography>
          </Breadcrumbs>
          </div>
      <AdminToggleSection />
      <HomeAbouSection />
      <HomeBottomSection />

      {isMobile ? <StickyFooterMobile /> : <StickyFooter />}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default TabsAccess;
