import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bed.svg";
import ButtonContained from "../Buttons/ButtonContained";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import classes from "../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import { BedRounded } from "@mui/icons-material";
import { Chip } from "@mui/material";
import './index.css'

const PropertiesCardList = ({
  currentProperties,
  isMobile,
  navigate,
  deleteProperty,
  user,
  removeLocations,
  capitalizeFLetter,
  Watermark,
}) => {
  const theme = useTheme();

  function removeLocations(inputString) {
    if (inputString) {
      // Remove "West Bengal", "India", and any postal code at the end of the string
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();

      // Remove any extra commas and spaces that might result from the replacements
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();

      return modifiedString;
    }
    return inputString;
  }

  return (
    <div className="d-flex flex-wrap">
      {currentProperties.length > 0 ? (
        currentProperties.map((card, index) => (
          <div
            key={index}
            className={`col-lg-12 col-md-12 col-sm-12 ${isMobile && "w-100"}`}
          >
            <div className="card-container-list1">
              <div className="card1">
                <Watermark
                  gutter={60}
                  rotate={0}
                  text="Gharwalah.com"
                  textSize={20}
                  multiline={false}
                >
                  <div
                    onClick={() => {
                      !card.route_title
                        ? window.open(`/properties/${card._id}`, "_blank")
                        : window.open(
                            `/properties/${card?.route_title}?id=${card._id}`,
                            "_blank"
                          );
                    }}
                    className="card-image-properties1"
                    style={{
                      backgroundImage: card.image
                        ? `url(${card.image})`
                        : "none",
                      backgroundSize: card.image ? "100%" : "initial",
                      backgroundRepeat: card.image ? "no-repeat" : "initial",
                      backgroundClip: card.image ? "content-box" : "initial",
                      backgroundPosition: card.image ? "center" : "initial",
                    }}
                  ></div>
                </Watermark>
                {card.isFeature && (
                  <div className="badge">
                    <Chip
                      style={{
                        color: "#000000",
                        backgroundColor: "#FFD700",
                        fontSize: "12px",
                        marginTop: "2px",
                        marginLeft: "2px",
                      }}
                      label={"Featured"}
                    />
                  </div>
                )}
                <div className="text-info1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        marginBottom: "0px",
                      }}
                    >
                      {/* {card?.bedrooms} BHK{" "} */}
                      {capitalizeFLetter(card.propertyType)}{" "}
                    </p>
                    <p
                      style={{ color: "#b90000" }}
                      className="slider-title price-text"
                    >
                      ₹
                      {Intl.NumberFormat("en-IN").format(
                        card?.expectedMonthlyRent
                      )}
                      {card?.wantto !== "sell" && ""}
                    </p>
                  </div>
                  {/* <div
                    style={{
                      height: "23px",
                    }}
                  >
                    {card.buildupArea && (
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        {card.buildupArea} Sq. Ft.
                      </span>
                    )}
                  </div> */}
                  <div className="slider-card-header">
                    <h2
                      style={{ textTransform: "capitalize" }}
                      className="slider-title1 flat-description1"
                    >
                      {card?.title} {card?.propertyType} For {card?.wantto}{" "}
                      {card?.locality.coordinates[2]
                        ? "in" + removeLocations(card?.locality.coordinates[2])
                        : ""}
                    </h2>
                  </div>
                  {/* <div>
                    <span
                      style={{
                        color: "#9a291b",
                        fontWeight: "700",
                        textTransform: "capitalize",
                      }}
                    >
                      {card.wantto}
                    </span>
                  </div> */}
                  <p className="property-desc">{card.description}</p>
                  <span
                    style={{
                      color: "gray",
                      fontSize: "small",
                    }}
                  >
                    Listed on: {card.created.slice(0, 10)}
                  </span>
                  <div
                    style={{
                      color: "#00B908",
                      fontSize: "small",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    {card.createdBy !== "" && (
                      <div>
                        Post by:{" "}
                        {card.createdBy === "admin"
                          ? "Gharwala Exclusive"
                          : card.createdBy || "Gharwala Exclusive"}
                      </div>
                    )}
                    <span
                      style={{
                        color: "gray",
                      }}
                    >
                      {removeLocations(card?.locality.coordinates[2])}
                    </span>
                  </div>
                  <div className="propety-icons">
                    <div className="property-icon">
                      <img src={BedSvg} />
                      {/* <BedRounded color="#b90000" /> */}
                      <span className="text-color979797 property-icon-text">
                        {card?.bedrooms}
                      </span>
                    </div>
                    {card.bathrooms && (
                      <div className="property-icon">
                        <img src={BathSvg} />
                        <span className="property-icon-text text-color979797">
                          {card.bathrooms}
                        </span>
                      </div>
                    )}
                    {user && user?.role !== "guest" && (
                      <div className="property-icon">
                        <span
                          style={{
                            color: card.status ? "green" : "red",
                          }}
                          className="property-icon-text text-color979797 px-2"
                        >
                          {card?.status ? "Active" : "Deactivated"}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="button-container"
                
                >
                  <div
                    style={{
                   
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <ButtonContained
                      onClick={() =>
                        !card.route_title
                          ? window.open(`/properties/${card._id}`, "_blank")
                          : window.open(
                              `/properties/${card?.route_title}?id=${card._id}`,
                              "_blank"
                            )
                      }
                      sx={[
                        classes.borderRadius12,
                        classes.fs12,
                        classes.margin4,
                        classes.p10,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs9,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                            ...classes.p7,
                          },
                        },
                      ]}
                      className="m-0 mx-1 my-2"
                    >
                      Connect
                    </ButtonContained>
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="property-slider-buttons"
                  >
                    <ButtonOutlined
                    className="view-btn"
                      sx={[
                        classes.borderRadius12,
                        classes.fs12,
                        classes.margin4,
                        classes.p10,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs9,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                            ...classes.p6,
                          },
                        },
                      ]}
                      onClick={() =>
                        !card.route_title
                          ? navigate(`/properties/${card._id}`)
                          : navigate(
                              `/properties/${card?.route_title}?id=${card._id}`
                            )
                      }
                    >
                      View Detail
                    </ButtonOutlined>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="loading-properties">
          <div className="loading-property-card"></div>
          <div className="loading-property-card"></div>
          <div className="loading-property-card"></div>
         
        </div>
      )}
    </div>
  );
};

export default PropertiesCardList;
