import React, { useState, useEffect } from "react";
import "./BlogForm.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom"; // Import Link
import Header from "../Header.js";
import Footer from "../Footer.js";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

function BlogForm({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [captchaToken, setCaptchaToken] = useState("");
  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  const uploadFile = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  function onChange(value) {
    setCaptchaToken(value);
  }

  const submitFiles = async (event) => {
    event.preventDefault();
    console.log(
      title,
      author,
      shortDescription,
      editorState,
      captchaToken,
      metaTitle,
      metaDescription
    );
    if (
      !title ||
      !author ||
      !shortDescription ||
      !editorState ||
      !metaTitle ||
      !metaDescription
    ) {
      alert("Please fill in all fields");
      return;
    }

    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("author", author);
    formData.append("shortDescription", shortDescription);
    formData.append("blogContent", JSON.stringify(rawContentState));
    formData.append("file", image);
    formData.append("captchaToken", captchaToken);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDescription", metaDescription);
    formData.append("tags", JSON.stringify(tags));

    try {
      const response = await axios.post(
        "https://gharwala-5466fddd6458.herokuapp.com/api/blogs",
        formData
      );
      if (response.status === 200) {
        toast.success("blog uploaded");
        navigate("/blogs");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  const handleTagInput = (e) => {
    if (e.key === "Enter" && e.target.value) {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      e.target.value = "";
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />

      <div
        style={{
          marginTop: "150px",
          marginBottom: "10px",
          marginLeft: "20px",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/admin">
            Dashboard
          </Link>

          <Link underline="hover" color="inherit" to="/admin/blog-management">
            Blog Options
          </Link>
          <Typography sx={{ color: "text.primary" }}>Add Blogs</Typography>
        </Breadcrumbs>
      </div>

      <div
        style={{
          maxWidth: "1600px",
          width: "90%",
          marginTop: "50px",
          marginBottom: "40px",
        }}
        className="blog-form-container"
      >
        <p>Create a New Blog Post</p>
        <form onSubmit={submitFiles}>
          <div className="form-group">
            <label htmlFor="image">Image:</label>
            <input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              onChange={uploadFile}
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="author">Author:</label>
            <input
              type="text"
              id="author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="metaTitle">Meta Title:</label>
            <input
              type="text"
              id="metaTitle"
              value={metaTitle}
              onChange={(e) => setMetaTitle(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="tags">Tags:</label>
            <div className="tags-input-container">
              <div className="tags-display">
                {tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                    <button type="button" onClick={() => removeTag(tag)}>
                      &times;
                    </button>
                  </span>
                ))}
              </div>
              <input
                type="text"
                id="tags"
                placeholder="Type a tag and press Enter"
                onKeyDown={handleTagInput}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="metaDescription">Meta Description:</label>
              <textarea
                id="metaDescription"
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
              />
            </div>
            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="shortDescription">Short Description:</label>
              <textarea
                id="shortDescription"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="mainContent">Main Content:</label>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              editorClassName="editor-wrapper"
            />
          </div>

          <ReCAPTCHA
            sitekey="6LdYvL8pAAAAANfeKw4Dy3zd2FlFrrLTWuZyVlP6"
            onChange={onChange}
          />

          <div style={{ marginTop: "20px" }}>
            <button type="submit">Submit</button>
            <Link to="/blogs" className="view-blogs-button">
              <button type="button">View Blogs</button>
            </Link>
          </div>
        </form>
      </div>

      <Footer />
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
}

export default BlogForm;
