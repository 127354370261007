import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, MenuItem, Breadcrumbs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import Header from "../../components/Header";
import classes from "../../styles/styles.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import publicReq from "../../config/axiosConfig";
import { useMediaQuery } from "react-responsive";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import { privateReq } from "../../config/axiosConfig";
import ScrollToTop from "../../components/ScrollToTop.js";
import RecentPropertyCard from "../../components/AdminPropertiesCard/RecentPropertyCard.js"

export const getLocLatLng = (district) => {
  return geocodeByAddress(district?.label)
    .then((results) => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log("Successfully got latitude and longitude", { lat, lng });
      return { lat, lng };
    })
    .catch((err) => {
      console.log(err);
    });
};
const RecentProperties = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [statusField, setStatusField] = useState("");
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [properties, setProperties] = useState([]);

  const [sortby, setSortBy] = useState("created");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showServices, setShowServices] = useState();

  function checkTabS2() {
    publicReq
      .post("/get-access-for-basic-features", { propertyName: "PROPERTY" })
      .then((result) => {
        setShowServices(result.data.message.enabled);
        if (result.data.message.enabled === false) {
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error("something went wrong");
        console.log(error);
      });
  }

  useEffect(() => {
    checkTabS2();
  }, []);

  function deleteProperty(id) {
    privateReq
      .delete(`/delete-property/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Property deleted");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getRecentProperties();
      });
  }

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  useEffect(() => {
    getRecentProperties();
  }, [sortby]);

  const getRecentProperties = () => {
    privateReq
      .get(`/new-property-list`)
      .then((res) => {
        if (res.status === 200) {
          setProperties(res.data.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function capitalizeFLetter(word) {
    if (word) {
      return word[0].toUpperCase() + word.slice(1);
    }
  }
  function removeLocations(inputString) {
    if (inputString) {
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();
      return modifiedString;
    }
    return inputString;
  }

  return (
    <>
      <ScrollToTop />
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />

       
      <div style={{marginTop:"40px"}} className="container-fluid px-0">

      <div style={{ paddingLeft: "30px", marginTop: "100px", backgroundColor:"#f4f4f4", paddingTop:"40px", paddingBottom:"10px" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        underline="hover"
                        color="inherit"
                        to="/admin"
                      >
                        Dashboard
                      </Link>

                      <Link
                        underline="hover"
                        color="inherit"
                        to="/property-list"
                      >
                        Property List 
                      </Link>

                      <Typography sx={{ color: "text.primary" }}>
                        Recent Properties
                      </Typography>
                    </Breadcrumbs>
                  </div>
        <div className="properties-container bgF4F4F4">

          
          <div className="row mx-0 w-100">
            
            <div className="col-lg-12 col-md-12 col-xs-12">
              <div className="row properties-list mx-0">
                
                <div className="row properties-header px-3">
                  
                  <div className="col-lg-6 col-md-6 col-sm-12 heading-properties">
                  <h5>Recent Properties</h5>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 properties-filter-select mt-9">
                    {/* <div className="select-input mb-3">
                      <Typography
                        id="label"
                        sx={[classes.textSortBy, classes.fontFamilyDS]}
                      >
                        Sort by:
                      </Typography>
                      <Select
                        labelId="label"
                        id="select"
                        value={sortby}
                        onChange={(e) => setSortBy(e.target.value)}
                        IconComponent={KeyboardArrowDownIcon}
                        style={{ width: "300px" }}
                        sx={[
                          classes.fontFamilyDS,
                          classes.fbBold,
                          {
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          },
                        ]}
                      >
                        <MenuItem value="all">all</MenuItem>
                        <MenuItem value="24hours">24hours</MenuItem>
                        <MenuItem value="7days">7days</MenuItem>
                        <MenuItem value="1month">1month</MenuItem>
                      </Select>
                    </div> */}

                    
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  <RecentPropertyCard
                    currentProperties={properties}
                    navigate={navigate}
                    deleteProperty={deleteProperty}
                    removeLocations={removeLocations}
                    capitalizeFLetter={capitalizeFLetter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentProperties;
