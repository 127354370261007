import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import StickyFooter from "../../components/Footer";
import StickyFooterMobile from "../../components/FooterMobile";
import { useMediaQuery } from "react-responsive";
import Setting from "../../components/Setting";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import owner from "../../assets/images/owner.png";
import user1 from "../../assets/images/1.png";
import user2 from "../../assets/images/2.png";
import user3 from "../../assets/images/3.png";
import user4 from "../../assets/images/4.png";
import user5 from "../../assets/images/5.png";
import user44 from "../../assets/images/44.png";
import { Breadcrumbs, Typography } from "@mui/material";

const AdminUserList = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { user } = useSelector((state) => state.auth);
  const [showAdmin, setShowAdmin] = useState(false);

  useEffect(() => {
    console.log("this is now user", user);
    if (user !== null) {
      if (user?.role === "admin") {
        setShowAdmin(true);
      }
    }
  }, []);

  return (
    <>
      <div>
        <Header
          closeLoginModal={closeLoginModal}
          setCloseLoginModal={setCloseLoginModal}
          setOpenPopUp={setOpenPopUp}
        />


<div style={{marginLeft:"35px", marginTop:"150px", marginBottom:"0px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

          

            <Typography sx={{ color: "text.primary" }}>
            User Management
            </Typography>
          </Breadcrumbs>
          </div>

      </div>
      {showAdmin ? (
        <>
          <div
            style={{
              marginTop: "10px",
              padding: "20px",
            }}
          >
            <div className="admin-head">User Management</div>


            <div className="each-box">
            <div className="image-box">
                <Link to="/admin/user-management/all-users">
                  <img className="zip img-admin" src={user1} alt="zip" />
                </Link>
                <div className="img-admin-name">All Users</div>
              </div>

              <div className="image-box">
                <Link to="/admin/user-management/all-users?role=admin">
                  <img className="zip img-admin" src={user2} alt="zip" />
                </Link>
                <div className="img-admin-name">Admin</div>
              </div>

              <div className="image-box">
                <Link to="/admin/user-management/all-users?role=owner">
                  <img
                    className="zip img-admin"
                    src={owner}
                    alt="role-management"
                  />
                </Link>
                <div className="img-admin-name">Owner</div>
              </div>

              <div className="image-box">
                <Link to="/admin/user-management/all-users?role=broker">
                  <img className="tab img-admin" src={user3} alt="tab" />
                </Link>
                <div className="img-admin-name">Broker</div>
              </div>

              <div className="image-box">
                <Link to="/admin/user-management/all-users?role=developer">
                  <img className="tab img-admin" src={user44} alt="tab" />
                </Link>
                <div className="img-admin-name">Developer</div>
              </div>

              <div className="image-box">
                <Link to="/admin/user-management/all-users?role=guest">
                  <img className="tab img-admin" src={user4} alt="tab" />
                </Link>
                <div className="img-admin-name">Guest</div>
              </div>

              <div className="image-box">
                <Link to="/admin/user-management/all-users?role=serviceman">
                  <img className="tab img-admin" src={user5} alt="tab" />
                </Link>
                <div className="img-admin-name">Serviceman</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h1 style={{ margin: " 250px 10px", textAlign: "center" }}>
          404 ERROR
        </h1>
      )}

      {isMobile ? <StickyFooterMobile /> : <StickyFooter />}
    </>
  );
};

export default AdminUserList;
