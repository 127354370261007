import React from "react";
import { Box, Typography, Avatar, Chip, Paper } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Eye, Pencil, Trash } from "lucide-react";
import './index.css'

const AdminPropertiesCard = ({
  currentProperties,
  navigate,
  deleteProperty,
  removeLocations,
  capitalizeFLetter,
}) => {
  

  const urlParams = new URLSearchParams(window.location.search);
  const roleFromUrl = urlParams.get("role");
  console.log("roleFo", roleFromUrl);
  

  return (
   
    
    <>
      <TableContainer style={{ marginTop: "4px" }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>ID</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Title</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Price</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Type</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Listed on</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Post by</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Location</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProperties.map((property) => (
              <TableRow className="table-row"  key={property._id}>
                <TableCell
                  style={{ textTransform: "uppercase", fontSize: "small", cursor:"pointer" }}
                  onClick={() => {
                    !property.route_title
                      ? window.open(`/properties/${property._id}`, "_blank")
                      : window.open(
                          `/properties/${property?.route_title}?id=${property._id}`,
                          "_blank"
                        );
                  }}
                >
                  <span style={{textDecoration:"underline"}}>{property?._id}</span>
                </TableCell>
                <TableCell
                  style={{ textTransform: "uppercase", fontSize: "small" }}
                >
                  {property?.bedrooms} BHK{" "}
                  {capitalizeFLetter(property.propertyType)}{" "}
                </TableCell>

                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2">
                      {Intl.NumberFormat("en-IN").format(
                        property?.expectedMonthlyRent
                      )}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2">{property?.wantto}</Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                      width:"120px"
                    }}
                  >
                    <Typography variant="body2">
                      {property.created.slice(0, 10)}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      
                      width:"250px"
                    }}
                  >
                    <Typography style={{display: "flex", gap: "2px"}} variant="body2">
                      <div  onClick={() => {navigate(`/owners/${property?.user?._id}`)}}
                        style={{
                          
                          color: "black",
                          cursor:"pointer",
                          fontSize: "12px",
                        }}>
                        {property?.user?.name}
                      </div>
                      

                     { !roleFromUrl  &&  
                      <div 
                     
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "orange", 
                        color: "white", 
                        fontSize: "10px", 
                        borderRadius: "20px", 
                        paddingInline: "4px",
                        height: "20px",
                        textTransform:"capitalize",
                        marginLeft:"4px"
                      }}>

                      {
                      (property.createdBy)}
                      </div>
}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2">
                      {removeLocations(property?.locality.coordinates[2])}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex" }}>
                    {/* <button
                      className="delete-button"
                      onClick={() => {
                        !property.route_title
                          ? window.open(`/properties/${property._id}`, "_blank")
                          : window.open(
                              `/properties/${property?.route_title}?id=${property._id}`,
                              "_blank"
                            );
                      }}
                    >
                      <Eye size={"14px"} color="blue" />
                    </button> */}
                    <button
                    
                      className="delete-button transparent-90"
                      onClick={() =>
                        navigate(`/edit-properties/${property._id}`)
                      }
                    >
                      <Pencil size={"14px"} color="#000000" />
                    </button>
                    <button
                      className="delete-button transparent-90"
                      onClick={() => deleteProperty(property._id)}
                    >
                      <Trash size={"14px"} color="#990000" />
                    </button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminPropertiesCard;
