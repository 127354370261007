import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const addPropertyService = (data) =>
  privateReq.post("/property", data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });

export const getAllPropertieService = (data) =>
  privateReq.get("/get-properties" + data);

export const getFeaturedPropertiesService = (data) =>
  privateReq.get("/property-feature" + data);

export const onGetPropertyById = (_id) => privateReq.get("/property/" + _id);
export const updatePropertyStatus = (data) =>
  privateReq.post("/approve-property", data);
export const updatePropertyListingStatus = (data) =>
  privateReq.put("/property/update-listing-status", data);

export const onGetPropertietsByUserId = (data) =>
  privateReq.get("/get-properties-by-use-id/" + data);
export const addAccessoryService = (data) =>
  privateReq.post("/add-accessory", data);
export const viewPropertyContactService = (data) =>
  privateReq.post("/property/view-contact", data);
export const addRatingService = (data) =>
  privateReq.post("/property/add-review", data);
// ratings
export const getAllRatingsListService = (data) =>
  privateReq.get("/ratings/" + data);
export const getPropertyContactViewdervice = (data) =>
  privateReq.get("/property-contact-viewd" + data);

export const addAggrementProperty = (data) =>
  privateReq.post("/property/aggrement", data);

export const addAggrementFurniture = (data) =>
  privateReq.post("/furniture/aggrement", data);

export const checkPaymentProperty = (data) =>
  privateReq.post("/property/checkPayment", data);
