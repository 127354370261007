import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import ButtonContained from "../../components/Buttons/ButtonContained";
import publicReq, { privateReq } from "../../config/axiosConfig";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { Trash } from "lucide-react";
import toast from "react-hot-toast";

const GetContact = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const [tests, setTests] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, [user]);

  const deleteTest = (id) => {
    privateReq
      .delete(`/delete-contact/${id}`)
      .then((res) => {
        console.log("deleted");
        toast.success("Contact deleted successfully");
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = () => {
    publicReq
      .get("/get-contacts")
      .then((res) => {
        setTests(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          marginTop: "130px",
          padding: "0 50px",
        }}
      >
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

            <Typography sx={{ color: "text.primary" }}>Contact Data</Typography>
          </Breadcrumbs>
        </div>

        <h6 style={{ fontWeight: "bold" }}>Website Messages</h6>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Phone</strong>
                </TableCell>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <strong>Description</strong>
                </TableCell>
                <TableCell>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tests?.map((t) => (
                <TableRow key={t._id}>
                  <TableCell>{t.fullName}</TableCell>
                  <TableCell>{t.phone}</TableCell>
                  <TableCell>{t.email}</TableCell>
                  <TableCell>{t.desc}</TableCell>
                  <TableCell>
                    <Button
                      style={{ background: "white" }}
                      color="secondary"
                      onClick={() => deleteTest(t._id)}
                    >
                      <Trash color="#b30000" size={"16px"} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default GetContact;
