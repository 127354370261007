import RedLine from "../../assets/images/property-types-red.png";
import phone from "../../assets/images/phone-call.png";
import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import classes from "../../styles/styles.js";
import Button from "@mui/material/Button";
import { Card, CardContent, Typography, Grid, Box } from "@material-ui/core";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import { useTheme } from "@mui/material/styles";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bed.svg";
import { Avatar, Chip } from "@mui/material";
import {
  addRatingService,
  onGetPropertyById,
  updatePropertyStatus,
  viewPropertyContactService,
} from "../../config/property";
import styles from "../../styles/styles.js";
import StickyFooter from "../../components/Footer";
import { assetUrl, privateReq } from "../../config/axiosConfig";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import ModalSignUp from "../../components/ModalSignup";
import SignUp from "../../components/Signup";
import ModalLogin from "../../components/ModalLogin";
import Login from "../../components/Login";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Watermark } from "@hirohe/react-watermark";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CenterFocusStrong, CenterFocusWeak } from "@mui/icons-material";
import axios from "axios";
import { getProperties } from "../../redux/action/property.js";
import "./index.css";
import LightGallery from "lightgallery/react";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
} from "@mui/icons-material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { Helmet } from "react-helmet";
import ModalPropertyVerification from "../../components/ModalPropertyVerification/index.js";
import { hover } from "@testing-library/user-event/dist/cjs/convenience/hover.js";
import { border, display, margin } from "@mui/system";
import { Bathroom } from "@mui/icons-material";
import { Business } from "@material-ui/icons";
import ShareModal from "../../components/ShareModal/ShareModal.js";
import { BedDoubleIcon } from "lucide-react";
import PropertiesCard from "../../components/PropertiesCard/index.js";
import SliderPropertyCard from "../../components/PropertiesCard/SliderPropertycard.js";
import FeaturedBlog from "../../components/FeaturedBlogs/index.js";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const array = [1, 2, 3, 4, 5, 6];
const result = [];

const PropertyDetails = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const theme = useTheme();

  const params = useParams();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [closeModal, setCloseModal] = useState(false);
  const [propertyVerificationModal, setPropertyVerificationModal] =
    useState(false);
  // const [closeLoginModal, setCloseLoginModal] = useState(false);
  const [disableShortlist, setShortlist] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  // const { properties } = useSelector((state) => state.property);
  const [properties, setProperties] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [showContact, setShowContact] = useState(false);
  const [reviewGiven, setreviewGiven] = useState(false);
  const [reviews, setReviews] = useState([]);
  const navigate = useNavigate();
  const [watermarkedImages, setWatermarkedImages] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [shareModal, setShareModal] = useState(false);
  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState("");
  const [loading, setLodaing] = useState(false);
  const [myRating, setMyRating] = useState({
    rate: 0,
    desc: "",
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          left: "-4%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 1,
          color: "#fff",
          display: isMobile ? "none" : "flex",
          backgroundColor: "#f9dbdb",
          borderRadius: "50%",
          padding: "10px",
          "&:hover": { backgroundColor: "#c5c6c6" },
        }}
      >
        <FaAngleLeft color="black" size={24} />
      </Box>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          right: "-4%",
          transform: "translateY(-50%)",
          display: isMobile ? "none" : "flex",
          cursor: "pointer",
          zIndex: 1,
          color: "#fff",
          backgroundColor: "#f9dbdb",
          borderRadius: "50%",
          padding: "10px",
          "&:hover": { backgroundColor: "#c5c6c6 " },
        }}
      >
        <FaAngleRight color="black" size={24} />
      </Box>
    );
  };

  const settings = {
    dots: false, // Enable dots for navigation
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    prevArrow: <CustomPrevArrow />, // Custom Previous Arrow
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024, // Screen size below 1024px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Screen size below 600px
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (propertyDetails) {
      const title = `${propertyDetails?.bedrooms} BHK ${
        propertyDetails?.propertyType
      } ${propertyDetails?.wantto === "sell" ? "for" : "on"} ${
        propertyDetails?.wantto
      } at Rs ${propertyDetails?.expectedMonthlyRent}/- in ${
        propertyDetails?.locality?.coordinates[2]
      } available immediately`;
      setTitle(title);

      setKeywords(
        `${propertyDetails?.bedrooms} BHK ${propertyDetails?.propertyType} ${
          propertyDetails?.wantto === "sell" ? "for" : "on"
        } ${
          propertyDetails?.wantto
        } in ${propertyDetails?.locality?.coordinates[2]
          ?.split(",")
          .join("")}, ${propertyDetails?.bedrooms} BHK on ${
          propertyDetails?.wantto
        } in ${propertyDetails?.locality?.coordinates[2]
          ?.split(",")
          .join("")},  ${propertyDetails?.bedrooms} BHK on ${
          propertyDetails?.wantto
        } in ${propertyDetails?.expectedMonthlyRent - 5000} - ${
          propertyDetails?.expectedMonthlyRent + 5000
        } in  ${propertyDetails?.locality?.coordinates[2]
          ?.split(",")
          .join("")}, `
      );

      document.title = title;
    }
  }, [
    propertyDetails?.bedrooms,
    propertyDetails?.propertyType,
    propertyDetails?.wantto,
    propertyDetails?.expectedMonthlyRent,
    propertyDetails?.locality?.coordinates,
  ]);

  // Add this helper function to calculate distance between coordinates
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const getPropertiesInitial = async (
    lat,
    long,
    propertyType,
    wantto,
    bedrooms
  ) => {
    try {
      const resp = await privateReq.get("/get-properties", {
        params: {
          latitude: lat,
          longitude: long,
          propertyType: propertyType,
          wantto: wantto,
          bedrooms: bedrooms,
        },
      });
      setLodaing(true);
      let data = resp.data.filter((property) => {
        const isActive = property.status === true;
        const distance = calculateDistance(
          lat,
          long,
          property.locality.coordinates[1],
          property.locality.coordinates[0]
        );

        return isActive && distance <= 3;
      });

      setProperties(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLodaing(false);
    }
  };

  function capitalizeFLetter(word) {
    if (word) {
      return word[0].toUpperCase() + word.slice(1);
    }
  }

  useEffect(() => {
    onGetProperty();
    (() => {
      privateReq
        .get(`/property-list/shortlist/${params?.id}`)
        .then((res) => {
          console.log("exits", res);
          setShortlist(res.data.exists);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    // console.log('details' , propertyDetails)
  }, [params]);

  function removeLocations(inputString) {
    if (inputString) {
      // Remove "West Bengal", "India", and any postal code at the end of the string
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();

      // Remove any extra commas and spaces that might result from the replacements
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();

      return modifiedString;
    }
    return inputString;
  }

  const onGetProperty = async () => {
    const filterQuery = params.id;
    let res = await onGetPropertyById(filterQuery);
    if (res.data) {
      setPropertyDetails(res.data.post);
      setShowContact(res.data.showContact);
      setreviewGiven(res.data.reviewGiven);
      setReviews(res.data.reviews);
      setLoader(false);
      getPropertiesInitial(
        res?.data?.post?.locality?.coordinates[1],
        res?.data?.post?.locality?.coordinates[0],
        res?.data?.post?.propertyType,
        res?.data?.post?.wantto,
        res?.data?.post?.bedrooms
      );
    }
  };

  const handleTextChange = (name, val) => {
    setMyRating({ ...myRating, [name]: val });
  };
  const onViewContact = async (e) => {
    e.preventDefault();
    try {
      console.log(user);
      if (user === null) {
        setPropertyVerificationModal(true);
        return;
      }
      const res = await viewPropertyContactService({
        propertyId: propertyDetails._id,
      });
      setShowContact(true);
    } catch (e) {
      console.log(e);
    }
  };

  const onAddReview = async (e) => {
    e.preventDefault();
    try {
      const res = await addRatingService({
        ...myRating,
        id: params.id,
      });
      if (res.data.status) {
        toast.success("Review added successfully!");
        setMyRating({
          rate: 0,
          desc: "",
        });
        await onGetProperty();
        setreviewGiven(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const shortlist = () => {
    privateReq
      .post("/property-list/shortlist", {
        propertyId: propertyDetails?._id,
      })
      .then((res) => {
        if (res.data === "already added") {
          toast.error("Already shortlisted this property");
        } else if (res.data.status === true) {
          toast.success("Shortlisted");
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onUpdateStatus = async (status) => {
    try {
      const res = await updatePropertyStatus({
        active: !propertyDetails?.status,
        id: propertyDetails?._id,
      });
      if (res.data.success) {
        toast.success(` ${status ? "Deactivated" : "Activated"} successfully!`);
        onGetProperty();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateFeatureStatus = async (status) => {
    if (!status) {
      toast(` Property is not activated.`);
      return;
    }

    try {
      let data = { isFeature: !propertyDetails?.isFeature };
      await privateReq.put(
        `/property-update-feature/${propertyDetails._id}`,
        data
      );
      toast.success(
        `${propertyDetails?.isFeature ? "Unfeature" : "Feature"} successfully!`
      );
      onGetProperty();
    } catch (error) {
      console.error("Error updating feature status:", error);
    }
  };

  const onRejectProperty = async () => {
    if (window.confirm("Are you sure you want to reject this property?")) {
      try {
      } catch (error) {
        console.error("Error rejecting property:", error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content={
            keywords +
            "Apartment, Flat, House, Bungalow, Get Rental Properties in Kolkata, Get Property Sale Deals In Kolkata, Kolkata Properties Ads, GharWala Kolkata Properties, GharWala, Gharwalah.com"
          }
        />
        <meta
          name="description"
          content={
            title +
            "Gharwala your One step solution for your housing and furniture rental search gharwalah.com"
          }
        />
      </Helmet>

      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        // innerRef={headerRef}
      />
      {console.log("type", propertyDetails?.listingStatus)}
      {console.log("yyyy", !propertyDetails?.status)}
      {propertyDetails &&
      user?.role !== "admin" &&
      (propertyDetails?.listingStatus === true || !propertyDetails?.status) ? (
        <div className="container-new">
          <div
            className="row align-items-center justify-content-center"
            style={{
              minHeight: "50vh",
              padding: "40px 20px",
            }}
          >
            <div className="col-md-12 text-center">
              <div
                style={{
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "40px 20px",
                  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                }}
              >
                <svg
                  style={{
                    width: "64px",
                    height: "64px",
                    color: "#9A291B",
                    marginBottom: "20px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <h3
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "#333",
                    marginBottom: "12px",
                  }}
                >
                  Property Unavailable
                </h3>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#666",
                    maxWidth: "400px",
                    margin: "0 auto",
                  }}
                >
                  This property is no longer available. Please check our other
                  listings for similar properties.
                </p>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#9A291B",
                    color: "#fff",
                    marginTop: "24px",
                    "&:hover": {
                      backgroundColor: "#7a2016",
                    },
                  }}
                  onClick={() => navigate("/properties")}
                >
                  Browse Properties
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div /*style={{ paddingBottom: "45px" }} */ className="container-new">
          {user?.role === "admin" && isMobile && (
            <div className="mb-3">
              <ButtonContained
                sx={[
                  styles.fontFamilySGB,
                  propertyDetails?.status && styles.btnBgGreen,
                  styles.p10,
                  styles.borderRadius12,
                ]}
                className="m-0 my-2 mx-1"
                onClick={(e) => {
                  e.preventDefault();
                  onUpdateStatus(propertyDetails?.status);
                }}
              >
                {propertyDetails?.status ? "Deactivate" : "Activate"}
              </ButtonContained>
            </div>
          )}
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div
                className="property-image-container"
                style={{
                  marginRight: "10px",
                  marginBottom: "15px",
                  position: "relative", // Add this
                }}
              >
                {propertyDetails ? (
                  <LightGallery
                    onInit={() => console.log("LightGallery initialized")}
                    speed={500}
                    plugins={[lgZoom]}
                    index={selectedImage}
                  >
                    {propertyDetails?.propertyImages?.map((image, index) => (
                      <a
                        key={index}
                        href={image}
                        data-lightgallery="item"
                        data-src={image}
                        className={index === selectedImage ? "active" : ""}
                      >
                        {index === selectedImage && (
                          <Watermark
                            gutter={150}
                            rotate={0}
                            text="Gharwalah.com"
                            textSize={50}
                            multiline={false}
                          >
                            <img
                              src={image}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://lh5.googleusercontent.com/proxy/t08n2HuxPfw8OpbutGWjekHAgxfPFv-pZZ5_-uTfhEGK8B5Lp-VN4VjrdxKtr8acgJA93S14m9NdELzjafFfy13b68pQ7zzDiAmn4Xg8LvsTw1jogn_7wStYeOx7ojx5h63Gliw";
                              }}
                              className="property-image"
                            />
                          </Watermark>
                        )}
                      </a>
                    ))}
                  </LightGallery>
                ) : (
                  <div className="skelton-img"></div>
                )}

                {/* Add navigation buttons */}
                {selectedImage > 0 && (
                  <button
                    onClick={() => setSelectedImage(selectedImage - 1)}
                    className="nav-button prev"
                    aria-label="Previous image"
                  >
                    <ArrowLeftIcon />
                  </button>
                )}

                {propertyDetails?.propertyImages &&
                  selectedImage < propertyDetails.propertyImages.length - 1 && (
                    <button
                      onClick={() => setSelectedImage(selectedImage + 1)}
                      className="nav-button next"
                      aria-label="Next image"
                    >
                      <ArrowRightIcon />
                    </button>
                  )}
              </div>

              <Box
                sx={{ position: "relative", width: "100%", marginTop: "10px" }}
              >
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      overflow: "hidden",
                      overflowX: "auto",
                      scrollBehavior: "smooth",
                      padding: "0 35px 0 35px",
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                    id="thumbnail-container"
                  >
                    {propertyDetails?.propertyImages.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className="image-wrapper"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://lh5.googleusercontent.com/proxy/t08n2HuxPfw8OpbutGWjekHAgxfPFv-pZZ5_-uTfhEGK8B5Lp-VN4VjrdxKtr8acgJA93S14m9NdELzjafFfy13b68pQ7zzDiAmn4Xg8LvsTw1jogn_7wStYeOx7ojx5h63Gliw";
                        }}
                        style={{
                          border:
                            selectedImage === index
                              ? "2px solid #b90000"
                              : "2px solid transparent",
                          borderRadius: "8px",
                          flexShrink: 0,
                          width: "60px",
                          height: "60px",
                          cursor: "pointer",
                          transition: "border 0.3s ease",
                        }}
                        onClick={() => setSelectedImage(index)}
                      />
                    ))}
                  </div>

                  <Button
                    onClick={() => {
                      const container = document.getElementById(
                        "thumbnail-container"
                      );
                      container.scrollLeft -= 200;
                    }}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                      minWidth: "26px",
                      height: "26px",
                      padding: "3px",
                      backgroundColor: "rgba(255,255,255,0.95)",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                      borderRadius: "50%",
                      zIndex: 1,
                      transition: "all 0.3s ease",
                      border: "1px solid #e0e0e0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#fff",
                        boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
                        transform: "translateY(-50%) scale(1.05)",
                      },
                    }}
                  >
                    <ArrowLeftIcon
                      fontSize="small"
                      style={{
                        color: "#424242",
                        transition: "color 0.3s ease",
                      }}
                    />
                  </Button>

                  {/* Scroll Right Button */}
                  <Button
                    onClick={() => {
                      const container = document.getElementById(
                        "thumbnail-container"
                      );
                      container.scrollLeft += 200;
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                      minWidth: "26px",
                      height: "26px",
                      padding: "3px",
                      backgroundColor: "rgba(255,255,255,0.95)",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                      borderRadius: "50%",
                      zIndex: 1,
                      transition: "all 0.3s ease",
                      border: "1px solid #e0e0e0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#fff",
                        boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
                        transform: "translateY(-50%) scale(1.05)",
                      },
                    }}
                  >
                    <ArrowRightIcon
                      fontSize="small"
                      style={{
                        color: "#424242",
                        transition: "color 0.3s ease",
                      }}
                    />
                  </Button>
                </div>
              </Box>
              {propertyDetails ? (
                <Card className="property-card">
                  <h5 style={{ marginBottom: "15px", fontWeight: "600" }}>
                    {`${propertyDetails?.bedrooms} BHK ${propertyDetails?.propertyType} on ${propertyDetails?.wantto} in ` +
                      removeLocations(
                        propertyDetails?.locality?.coordinates[2]
                      )}
                  </h5>
                  {/* Property Details Section */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "16px",
                        color: "#B90000",
                      }}
                    >
                      Property Details
                    </Typography>

                    {/* <p style={{ fontSize: "14px", display: "flex", gap: "2px" }}>Listed By: 
                      <span style={{ fontWeight: "bold" }}>
                      {propertyDetails?.createdBy
                       ? propertyDetails.createdBy.charAt(0).toUpperCase() + propertyDetails.createdBy.slice(1)
                      : ''}
                      </span>
                    </p> */}

                    <p
                      style={{ fontSize: "14px", display: "flex", gap: "2px" }}
                    >
                      Listed By:
                      <span style={{ fontWeight: "bold" }}>
                        {propertyDetails?.createdBy
                          ? propertyDetails.createdBy.toLowerCase() === "admin"
                            ? "Gharwala Exclusive"
                            : propertyDetails.createdBy
                                .charAt(0)
                                .toUpperCase() +
                              propertyDetails.createdBy.slice(1)
                          : ""}
                      </span>
                    </p>

                    <p
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      Listed on:
                      <span style={{ fontWeight: "bold", marginLeft: "3px" }}>
                        {propertyDetails?.created &&
                          new Date(propertyDetails.created).toLocaleDateString(
                            "en-IN",
                            {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                      </span>
                    </p>
                  </div>

                  <Box>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap", // Allows items to wrap to the next line
                        justifyContent: "space-between",
                        marginBottom: "16px",
                        color: "#607D8B",
                        width: "100%",
                      }}
                    >
                      {propertyDetails?.furnishingDetails && (
                        <div
                          style={{
                            width: "48%",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#607D8B", // Matches the grayish-blue tone
                              }}
                              className="furnishing"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{
                                  marginRight: "8px", // Space between icon and text
                                }}
                              >
                                <path d="M2 20h20" />
                                <path d="m9 10 2 2 4-4" />
                                <rect
                                  x="3"
                                  y="4"
                                  width="18"
                                  height="12"
                                  rx="2"
                                />
                              </svg>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500, // Matches the medium font weight
                                }}
                              >
                                Furnishing
                              </Typography>
                            </div>
                            {propertyDetails?.furnishingDetails &&
                              propertyDetails?.furnishingDetails !== "0" && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500, // Matches the medium font weight
                                    color: "#002F34",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {propertyDetails?.furnishingDetails === "1"
                                    ? "Furnished"
                                    : propertyDetails?.furnishingDetails === "2"
                                    ? "Semi Furnished"
                                    : "Unfurnished"}
                                </Typography>
                              )}
                          </div>
                        </div>
                      )}
                      {propertyDetails?.parking && (
                        <div
                          style={{
                            width: "48%",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            {propertyDetails?.parking &&
                              propertyDetails?.parking !== "0" && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#607D8B", // Matches the grayish-blue tone
                                  }}
                                  className="car-parking"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                      marginRight: "8px", // Space between icon and text
                                    }}
                                  >
                                    <path d="M19 17h2c.6 0 1-.4 1-1v-3c0-.9-.7-1.7-1.5-1.9C18.7 10.6 16 10 16 10s-1.3-1.4-2.2-2.3c-.5-.4-1.1-.7-1.8-.7H5c-.6 0-1.1.4-1.4.9l-1.4 2.9A3.7 3.7 0 0 0 2 12v4c0 .6.4 1 1 1h2" />
                                    <circle cx="7" cy="17" r="2" />
                                    <path d="M9 17h6" />
                                    <circle cx="17" cy="17" r="2" />
                                  </svg>

                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 500, // Matches the medium font weight
                                    }}
                                  >
                                    Car Parking
                                  </Typography>
                                </div>
                              )}

                            {propertyDetails?.parking &&
                              propertyDetails?.parking !== "0" && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500, // Matches the medium font weight
                                    color: "#002F34",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {propertyDetails?.parking === "1"
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              )}
                          </div>
                        </div>
                      )}
                      {propertyDetails?.buildupArea && (
                        <div
                          style={{
                            width: "48%",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#607D8B", // Matches the grayish-blue tone
                              }}
                              className="super-built-up-area-sqft"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{
                                  marginRight: "8px", // Space between icon and text
                                }}
                              >
                                <path d="M2 20h20" />
                                <path d="m9 10 2 2 4-4" />
                                <rect
                                  x="3"
                                  y="4"
                                  width="18"
                                  height="12"
                                  rx="2"
                                />
                              </svg>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500, // Matches the medium font weight
                                }}
                              >
                                Super Built-up area
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: "14px",
                                fontWeight: 500, // Matches the medium font weight
                                color: "#002F34",
                                marginBottom: "5px",
                              }}
                            >
                              {propertyDetails?.buildupArea} Sqft
                            </Typography>
                          </div>
                        </div>
                      )}

                      {propertyDetails?.bedrooms && (
                        <div
                          style={{
                            width: "48%",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            {propertyDetails?.bedrooms &&
                              propertyDetails?.bedrooms !== 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#607D8B", // Matches the grayish-blue tone
                                  }}
                                  className="bedrooms"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                      marginRight: "8px", // Space between icon and text
                                    }}
                                  >
                                    <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
                                    <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                  </svg>

                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 500, // Matches the medium font weight
                                    }}
                                  >
                                    Bedrooms
                                  </Typography>
                                </div>
                              )}

                            {propertyDetails?.bedrooms &&
                              propertyDetails?.bedrooms !== 0 && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500, // Matches the medium font weight
                                    color: "#002F34",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {propertyDetails?.bedrooms} Bedroom(s)
                                </Typography>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Box>

                  <div className="divider"></div>

                  {/* Description Section */}
                  <Typography variant="h5" className="description-title">
                    Description
                  </Typography>
                  <Typography variant="body2" className="description-text">
                    <p>
                      {/* Spacious 2 BHK Property for Rent Near Golf Green, Bijaygarh – Ground Floor, Hassle-Free Living.<br />
                  Looking for a comfortable and convenient home? This ready-to-move-in 2 BHK property located
                  near the prime area of Golf Green, Bijaygarh is perfect for individuals and families seeking a
                  peaceful and hassle-free living experience. */}
                      {propertyDetails?.desc}
                    </p>
                  </Typography>
                  <Typography variant="body2" style={{ color: "#406367" }}>
                    <ul>
                      <li>
                        Location: {propertyDetails?.locality.coordinates[2]}
                      </li>
                      <li
                        style={{ textTransform: "capitalize" }}
                        className="list-item"
                      >
                        Property Type: {propertyDetails?.propertyType}
                      </li>
                      <li>
                        Floor: {propertyDetails?.floorNumber} – ideal for senior
                        citizens, families with children, or anyone looking for
                        easy access without climbing stairs.
                      </li>
                    </ul>

                    {(propertyDetails?.furnishingDetails === "1" ||
                      propertyDetails?.furnishingDetails === "2") &&
                      propertyDetails?.furnitureDetails.length > 0 && (
                        <>
                          <h4
                            className="amenties-heading"
                            style={{ marginTop: "35px" }}
                          >
                            Furnishing Details
                          </h4>
                          <div>
                            <ul className="property-amenties-items">
                              {propertyDetails?.furnitureDetails.map((item) => (
                                <li>
                                  {/* <img src={PoolSvg} /> */}
                                  <span
                                    className="furnture-span"
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    {item.key} : {item.value}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      )}

                    {propertyDetails?.accessoryList.length > 0 && (
                      <>
                        <h4
                          className="amenties-heading"
                          style={{ marginTop: "35px" }}
                        >
                          Society Accessories
                        </h4>
                        <div>
                          <ul className="property-amenties-items">
                            {propertyDetails?.accessoryList.map((item) => (
                              <li>
                                {/* <img src={PoolSvg} /> */}
                                <span
                                  className="furnture-span"
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {item.accessoryName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    )}

                    {propertyDetails?.usp.length > 1 && (
                      <>
                        <h4
                          className="amenties-heading"
                          style={{ marginTop: "35px" }}
                        >
                          USP
                        </h4>
                        <div>
                          <ul className="property-amenties-items">
                            {propertyDetails?.usp.map((item) => (
                              <li>
                                {/* <img src={PoolSvg} /> */}
                                <span
                                  className="text-capitalize furnture-span"
                                  style={{ color: "black" }}
                                >
                                  {item}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    )}
                  </Typography>
                </Card>
              ) : (
                <>
                  <div className="skelton-img2"> </div>
                  <div className="skelton-img2"> </div>
                  <div className="skelton-img2"> </div>
                  <div className="skelton-img2"> </div>
                </>
              )}

              {/* <h4
              className="amenties-heading"
              style={{ marginTop: "35px", marginBottom: "20px" }}
            >
              Places Near By
            </h4>
            <div className="near-by-you">
              <button
                type="button"
                className="btn btn-outline-primary"
                style={{
                  backgroundColor: "#e2b4af",
                  border: "1px solid #9A291B",
                  color: "#9A291B",
                  marginRight: "15px",
                }}
              >
                Burger Point
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                class="btn"
                style={{
                  backgroundColor: "#e2b4af",
                  border: "1px solid #9A291B",
                  color: "#9A291B",
                }}
              >
                Indian Bank
              </button>
            </div> */}
              {/* <>
              <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
                Reviews
              </h4>
              <div>
                {reviews.length > 0 ? (
                  reviews.map((item) => (
                    <>
                      <div className="reviews-section-1">
                        <div className="review-user-section">
                          <Avatar
                            src={assetUrl + item.user?.avatarImage}
                            className="review-image"
                            alt={item.user.name}
                          />
                        </div>
                        <div className="review-stars-section">
                          <div className="review-user-name">
                            <div>
                              <span>{item.user.name}</span>
                            </div>
                            <Rating
                              value={item.rating}
                              sx={{
                                color: "#9A291B",
                              }}
                              readOnly
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="review-sub-title">{item.desc}</p>
                      </div>
                    </>
                  ))
                ) : (
                  <span className="nearby-list-address">No reviews yet</span>
                )}
              </div>
            </> */}
              {/* {showContact && !reviewGiven && (
              <div>
                <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
                  Add a review
                </h4>
                <Rating
                  value={myRating.rate}
                  sx={{
                    color: "#9A291B",
                  }}
                  onChange={(e) =>
                    handleTextChange("rate", parseInt(e.target.value))
                  }
                  size="large"
                />
                <TextareaAutosize
                  style={styles.outlineTextArea}
                  className="w-100 border"
                  minRows={5}
                  placeholder="Write your review..."
                  value={myRating.desc}
                  onChange={(e) => handleTextChange("desc", e.target.value)}
                />
                <ButtonContained onClick={onAddReview}>Submit</ButtonContained>
              </div>
            )} */}
            </div>
            <div
              className="col-md-4 col-xs-12"
              style={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginLeft: "15px",
                }}
              >
                {user?.role === "admin" && !isMobile && (
                  <>
                    {propertyDetails?.listingStatus === true ? (
                      <>
                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            marginBottom: "20px",
                          }}
                        >
                          This listing is inactive by user
                        </p>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            marginBottom: "5px",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <ButtonContained
                            sx={[
                              styles.fontFamilySGB,
                              propertyDetails?.status && styles.btnBgGreen,
                              styles.p10,
                              styles.borderRadius12,
                            ]}
                            className="m-0"
                            onClick={(e) => {
                              e.preventDefault();
                              onUpdateStatus(propertyDetails?.status);
                            }}
                          >
                            {propertyDetails?.status
                              ? "Deactivate"
                              : "Activate"}
                          </ButtonContained>

                          <ButtonContained
                            sx={[
                              styles.fontFamilySGB,
                              styles.p10,
                              styles.borderRadius12,
                              {
                                backgroundColor: "#dc3545",
                                "&:hover": {
                                  backgroundColor: "#c82333",
                                },
                              },
                            ]}
                            className="m-0"
                            onClick={(e) => {
                              e.preventDefault();
                              onRejectProperty();
                            }}
                          >
                            Reject
                          </ButtonContained>
                        </div>
                        <div
                          style={{
                            marginBottom: "20px",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <ButtonContained
                            sx={[
                              styles.fontFamilySGB,
                              styles.p10,
                              styles.borderRadius12,
                              {
                                backgroundColor: propertyDetails?.isFeature
                                  ? "#FFD700"
                                  : "#FFD700",
                                color: "#000000",
                                "&:hover": {
                                  backgroundColor: "#DAA520",
                                },
                                border: "1px solid #DAA520",
                              },
                            ]}
                            className="m-0"
                            onClick={(e) => {
                              e.preventDefault();
                              onUpdateFeatureStatus(propertyDetails?.status);
                            }}
                          >
                            {propertyDetails?.isFeature
                              ? "Unfeature"
                              : "Feature"}
                          </ButtonContained>
                        </div>
                      </>
                    )}
                  </>
                )}
                {propertyDetails ? (
                  <Box
                    sx={{
                      border: "1px solid #0E040533",
                      borderRadius: "2px",
                      marginTop: "-15px",
                      padding: "14px",
                      minHeight: "120px",
                      width: "99%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" className="property-title">
                      ₹
                      {propertyDetails?.expectedMonthlyRent !== undefined
                        ? Intl.NumberFormat("en-IN").format(
                            propertyDetails.expectedMonthlyRent
                          )
                        : "Loading..."}
                      {propertyDetails &&
                        propertyDetails.wantto !== "sell" &&
                        "/month"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        textAlign: "left",
                        color: "#406367",
                      }}
                    >
                      {propertyDetails &&
                        propertyDetails?.title +
                          " " +
                          propertyDetails?.propertyType +
                          " for " +
                          propertyDetails?.wantto +
                          " " +
                          propertyDetails?.locality?.coordinates[2]}
                    </Typography>
                    {/* <Typography
                    variant="body2"
                    style={{
                      marginTop: "12px",
                      color: "#406367",
                      textAlign: "left",
                    }}
                  >
                    {removeLocations(propertyDetails?.locality.coordinates[2])}
                  </Typography> */}
                  </Box>
                ) : (
                  <div className="skelton-img3"> </div>
                )}

                {showContact && (
                  <div
                    className="user-lang contact-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <p className="user-lang-heading" style={{ margin: 0 }}>
                      Contact:
                    </p>
                    <a
                      href={`tel:${propertyDetails?.user?.phone}`}
                      className="user-lang-sub-heading contact-link"
                      style={{
                        color: "#2E7D32",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        fontSize: "16px",
                        padding: "8px 12px",
                        borderRadius: "6px",
                        backgroundColor: "#E8F5E9",
                        transition: "all 0.3s ease",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#C8E6C9")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor = "#E8F5E9")
                      }
                    >
                      <i className="fas fa-phone-alt"></i>
                      {propertyDetails?.user?.phone}
                    </a>
                  </div>
                )}
                <div
                  style={{ display: "flex", gap: "14px", marginTop: "10px" }}
                >
                  {!showContact && (
                    <Button
                      onClick={onViewContact}
                      variant="contained"
                      sx={{
                        backgroundColor: "#006CAB",
                        color: "#fff",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        minWidth: "80px",
                        whiteSpace: "nowrap",
                        "&:hover": {
                          backgroundColor: "#005a8f",
                        },
                      }}
                    >
                      <img
                        src={phone}
                        alt="Phone icon"
                        width={24}
                        height={24}
                        style={{
                          marginRight: "8px",
                        }}
                      ></img>
                      Show Contact
                    </Button>
                  )}
                  <Button
                    disabled={disableShortlist}
                    onClick={() => {
                      shortlist();
                    }}
                    variant="contained"
                    sx={{
                      backgroundColor: "#a000ff",
                      color: "#fff",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      minWidth: "60px",
                      "&:hover": {
                        backgroundColor: "#e6b800",
                      },
                    }}
                  >
                    Shortlist
                  </Button>

                  <Button
                    onClick={() => setShareModal(true)}
                    variant="contained"
                    sx={{
                      backgroundColor: "#00AB4A",
                      color: "#fff",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      border: "1px solid #00AB4A",
                      minWidth: "60px",
                      "&:hover": {
                        backgroundColor: "#009641",
                        border: "1px solid #009641",
                      },
                    }}
                  >
                    Share
                  </Button>
                </div>

                <Box
                  sx={{
                    border: "1px solid #0E040533",
                    borderRadius: "2px",
                    marginTop: "16px",
                    padding: "16px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      color: "#002F34",
                      fontSize: "20px",
                      fontWeight: 700,
                      textAlign: "left",
                    }}
                  >
                    Posted in
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      marginBottom: "16px",
                      color: "#406367",
                      textAlign: "left",
                    }}
                  >
                    {removeLocations(propertyDetails?.locality.coordinates[2])}
                  </Typography>
                </Box>
                {/* <Box
                  sx={{
                    border: "1px solid #0E040533",
                    borderRadius: "2px",
                    marginTop: "16px",
                    padding: "16px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      color: "#002F34",
                      fontSize: "20px",
                      fontWeight: 700,
                      textAlign: "left",
                    }}
                  >
                    Posted on
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      marginBottom: "16px",
                      color: "#406367",
                      textAlign: "left",
                    }}
                  >
                    {propertyDetails?.created &&
                      new Date(propertyDetails.created).toLocaleDateString(
                        "en-IN",
                        {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                  </Typography>
                </Box> */}
                {/*               
              <Box style={{ marginTop: "16px" }}>
                <img
                  src="https://via.placeholder.com/300x200?text=Map+Placeholder"
                  alt="Map Placeholder"
                  style={{
                    width: "99%",
                    borderRadius: "2px",
                    marginLeft: "2px",
                  }}
                />
              </Box> */}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container-nearby-properties" style={{ margin: "" }}>
        {!isMobile ? (
          <div
            style={{
              backgroundColor: "#23AEFF12",
              marginTop: "15px",
              paddingLeft: "72px",
              paddingRight: "72px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h4
                style={{ paddingTop: "30px", color: "#0E0E46" }}
                className="nearby-title"
              >
                Nearby Properties
              </h4>
              <Typography>
                <img
                  src={RedLine}
                  alt="line-img"
                  height="8px"
                  style={{ marginBottom: "5px" }}
                />
              </Typography>
              <p style={{ color: "#8A909A" }}>
                Nearby properties from this location
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "center",
                paddingBottom: "40px",
              }}
            >
              {loading ? (
                <div>Loading Nearby Properties...</div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1400px",
                    margin: "0 auto",
                  }}
                >
                  <Slider autoplay {...settings}>
                    {properties.slice(0, 15).map((property, index) => (
                      <SliderPropertyCard
                        key={index}
                        currentProperties={[property]}
                      />
                    ))}
                  </Slider>
                </div>
              )}

              {properties?.data?.length > 15 && (
                <div
                  style={{ marginTop: "2rem" }}
                  className="blog-container-btn"
                >
                  <Link
                    to={`/near-by-properties?lat=${
                      propertyDetails?.locality?.coordinates[1]
                    }&location=${removeLocations(
                      propertyDetails?.locality?.coordinates[2]
                    )}&lng=${
                      propertyDetails?.locality?.coordinates[0]
                    }&propertyType=${propertyDetails?.propertyType}&wantto=${
                      propertyDetails?.wantto
                    }&bedrooms=${propertyDetails?.bedrooms}`}
                    className="view-all-blogs"
                  >
                    View All Nearby Properties
                  </Link>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {properties?.data?.length > 0 && (
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                infinite={true}
                showDots={true}
                autoPlay={true}
                autoPlaySpeed={3500}
              >
                {/* <Slider
            className='slider-margin-left'
            ref={setSliderRef}
            {...sliderSettings} */}
                {/* > */}
                {properties?.data?.map((card, index) => (
                  <div className="card-container mt-5 mb-5 mx-3">
                    <div key={index} className="card">
                      <Watermark
                        gutter={150}
                        rotate={0}
                        text="Gharwalah.com"
                        textSize={50}
                        multiline={false}
                      >
                        <div
                          className="card-image"
                          style={{
                            backgroundImage: `url(${card.image})`,
                            backgroundSize: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            backgroundPosition: "center",
                          }}
                        >
                          <div className="d-flex justify-content-end"></div>
                        </div>
                      </Watermark>
                      <div className="text-info">
                        <div className="slider-card-header">
                          <h2
                            className="slider-title"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {card.title}
                          </h2>
                          <span className="slider-title price-text">
                            {card.pricingText}
                          </span>
                        </div>
                        <div>
                          {/* <span style={{color: "#9a291b",}}>For</span>{' '}  */}
                          <span
                            style={{
                              color: "black",
                              paddingBlock: "4px",
                              paddingInline: "8px",
                              borderRadius: "8px",
                              textTransform: "capitalize",
                            }}
                          >
                            {card.wantto}
                          </span>
                        </div>
                        <p className="property-desc">{card.description}</p>
                        <div className="propety-icons">
                          <div className="property-icon">
                            <img src={BedSvg} />
                            <span className="text-color979797 property-icon-text">
                              {card.bedrooms}
                            </span>
                          </div>
                          {card.bathrooms && (
                            <div className="property-icon">
                              <img src={BathSvg} />
                              <span className="property-icon-text text-color979797">
                                {card.bathrooms}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="property-slider-buttons">
                        {/* <button className="btn-connect">Connect </button> */}
                        <ButtonContained
                          onClick={() => {
                            !card.route_title
                              ? navigate(`/properties/${card._id}`)
                              : navigate(
                                  `/properties/${card?.route_title}?id=${card._id}`
                                );
                            window.location.reload();
                          }}
                          sx={[
                            classes.borderRadius12,
                            classes.fs12,
                            classes.margin4,
                            classes.p10,
                            {
                              [theme.breakpoints.between("xs", "md")]: {
                                ...classes.fs9,
                                ...classes.margin4,
                                ...classes.borderRadius7,
                              },
                            },
                          ]}
                        >
                          Contact
                        </ButtonContained>
                        <ButtonOutlined
                          onClick={() => {
                            !card.route_title
                              ? navigate(`/properties/${card._id}`)
                              : navigate(
                                  `/properties/${card?.route_title}?id=${card._id}`
                                );
                            window.location.reload();
                          }}
                          sx={[
                            classes.borderRadius12,
                            classes.fs12,
                            classes.margin4,
                            classes.p10,
                            {
                              [theme.breakpoints.between("xs", "md")]: {
                                ...classes.fs8,
                                ...classes.margin4,
                                ...classes.borderRadius7,
                                ...classes.p6,
                                ...classes.px5,
                              },
                            },
                          ]}
                        >
                          View Detail
                        </ButtonOutlined>

                        {/* <button className="btn-connect btn-details">View Detail </button> */}
                      </div>
                    </div>
                  </div>
                ))}
                {/* </Slider> */}
              </Carousel>
            )}
          </>
        )}

        <FeaturedBlog />
      </div>
      {shareModal && (
        <ShareModal state={shareModal} stateFunc={setShareModal} />
      )}
      <StickyFooter />
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeModal}
          onDismiss={() => setCloseModal(false)}
        >
          <SignUp setCloseModal={setCloseModal} />
        </BottomSheet>
      ) : (
        <ModalSignUp
          state={closeModal}
          stateFunc={setCloseModal}
          title="Please fill all the Details"
        >
          <SignUp setCloseModal={setCloseModal} />
        </ModalSignUp>
      )}
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={propertyVerificationModal}
          onDismiss={() => setPropertyVerificationModal(false)}
        >
          <ModalPropertyVerification
            onClose={setPropertyVerificationModal}
            onVerificationComplete={setPropertyVerificationModal}
          />
        </BottomSheet>
      ) : (
        <ModalLogin
          state={propertyVerificationModal}
          stateFunc={setPropertyVerificationModal}
          title="Verification"
        >
          <ModalPropertyVerification
            onClose={setPropertyVerificationModal}
            onVerificationComplete={setPropertyVerificationModal}
          />
        </ModalLogin>
      )}
    </>
  );
};

export default PropertyDetails;
