import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Chip,
  Modal,
  FormControlLabel,
  Switch,
  Breadcrumbs,
} from "@mui/material";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop.js";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import { Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

const Department = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    status: "Active",
  });
  const [enquiries, setEnquiries] = useState([]);
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    fetchEnquiries();
    setHeaderHeight(headerRef.current.offsetHeight);
  }, []);

  const fetchEnquiries = async () => {
    try {
      const response = await privateReq.get("/department");
      setEnquiries(response.data.data);
    } catch (err) {
      toast.error("Failed to fetch role management");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      name: "",
      status: "Active",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (formData._id) {
        response = await privateReq.put(
          `/department/${formData._id}`,
          formData
        );
      } else {
        response = await privateReq.post("/department", formData);
      }

      if (response.data) {
        toast.success(
          formData._id
            ? "Department updated successfully"
            : "Department added successfully"
        );
        resetForm();
        fetchEnquiries();
        setOpenModal(false);
      }
    } catch (err) {
      console.error("Error adding role:", err);
      toast.error(err.response?.data?.message || "Failed to add role");
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleSwitchChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      status: event.target.checked ? "Active" : "Inactive",
    }));
  };

  const onEdit = (id) => {
    setFormData(enquiries.find((enquiry) => enquiry._id === id));
    setOpenModal(true);
  };

  const onDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this department?")) {
      privateReq
        .delete(`/department/${id}`)
        .then(() => {
          fetchEnquiries();
          toast.success("Department deleted successfully");
        })
        .catch((err) => {
          toast.error(
            err.response?.data?.message || "Failed to delete department"
          );
        });
    }
  };

  return (
    <>
      <ScrollToTop />

      
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <Box
        sx={{
          p: 4,
          maxWidth: "100%",
          mt: `${headerHeight}px`,
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)",
          minHeight: "100vh",
        }}
      >

<div style={{marginLeft:"15px", marginTop:"1px", marginBottom:"10px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

            <Link underline="hover" color="inherit" to="/admin-settings">
              Admin Setting
            </Link>

            <Typography sx={{ color: "text.primary" }}>
              Department
            </Typography>
          </Breadcrumbs>
          </div>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "16px",
            background: "white",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            mb: 4,
          }}
        >
          <Box
            sx={{
              p: 1,
              borderBottom: "1px solid #eee",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "3px 10px",
              }}
            >
              <PeopleIcon sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                Department List
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenModal(true)}
              sx={{
                borderRadius: "12px",
                textTransform: "none",
                background: "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                "&:hover": {
                  background:
                    "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                },
              }}
            >
              Add New Department
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                      width: "140px",
                    }}
                  >
                    Department
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Created At
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      backgroundColor: "#f8fafc",
                      padding: "1px 20px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enquiries.map((enquiry) => (
                  <TableRow
                    key={enquiry._id}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f8fafc",
                      },
                    }}
                  >
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.name}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {enquiry.status}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        {formatDate(enquiry.created)}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "1px 20px" }}>
                      <Typography variant="caption" color="textSecondary">
                        <button
                          className="delete-button"
                          onClick={() => onEdit(enquiry._id)}
                        >
                          <Pencil size={"14px"} color="#000000" />
                        </button>
                        <button
                          className="delete-button"
                          onClick={() => onDelete(enquiry._id)}
                        >
                          <Trash size={"14px"} color="#990000" />
                        </button>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            resetForm();
          }}
          aria-labelledby="add-enquiry-modal"
          aria-describedby="modal-to-add-new-enquiry"
        >
          <Paper sx={modalStyle}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 0 }}>
              <PersonAddIcon sx={{ fontSize: 16, color: "#1976d2", mr: 1 }} />
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                {formData._id ? "Update Department" : "Add Department"}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Department Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                margin="normal"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Status
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.status === "Active"}
                      onChange={handleSwitchChange}
                      name="status"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {formData.status ? "Active" : "Inactive"}
                    </Typography>
                  }
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 1,
                  height: "48px",
                  borderRadius: "12px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  background:
                    "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
                  boxShadow: "0 3px 15px rgba(33,150,243,0.3)",
                  "&:hover": {
                    background:
                      "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
                  },
                }}
              >
                {formData._id ? "Update Department" : "Add Department"}
              </Button>
            </form>
          </Paper>
        </Modal>
      </Box>
    </>
  );
};

export default Department;
