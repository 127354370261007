import { SET_FEATURED_PROPERTIES } from "../action/types";

const initialState = {
  featuredProperties: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FEATURED_PROPERTIES: {
      return {
        ...state,
        featuredProperties: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
