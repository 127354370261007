import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { privateReq } from "../../config/axiosConfig";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Pencil, Trash } from "lucide-react";
import { Breadcrumbs } from "@mui/material";

const EditServices = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [services, setServices] = useState([]);
  const [addService, setAddService] = useState({
    service_name: "",
    service_des: "",
    active: true,
  });
  const [au, setAu] = useState("");

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  useEffect(() => {
    // setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    const res = await privateReq.get("/admin-get-services");
    setServices(res.data.services);
  };

  const onChangeAddService = (e) => {
    const { name, value } = e.target;
    setAddService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddService = async () => {
    try {
      await privateReq.post("/admin-add-services", { ...addService });
      getServices();
    } catch (err) {
      console.log(err);
    }
  };

  const fillAddService = async (id) => {
    const service = services.find((s) => id === s._id);
    setAu(id);
    setAddService({
      service_des: service.service_des,
      active: service.active,
      service_name: service.service_name,
    });
  };

  const handleEditService = async () => {
    try {
      await privateReq.put(`/admin-update-services/${au}`, { ...addService });
      getServices();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteService = async (id) => {
    try {
      await privateReq.delete(`/admin-delete-services/${id}`);
      getServices();
    } catch (err) {
      console.log(err);
    }
  };

  return showPage ? (
    <>
      <Header
        innerRef={headerRef}
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        style={{
          marginTop: "130px",
          padding: "0 50px",
        }}
      >
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              to="/admin"
            >
              Dashboard
            </Link>

            <Typography sx={{ color: "text.primary" }}>
              Services
            </Typography>
          </Breadcrumbs>
        </div>

        <div
          style={{
            fontSize: "16px",
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          Edit Services
        </div>
        <div>
          <div>
            <div style={{ marginLeft: "7px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <OutlinedFormInput
                  style={{
                    width: "300px",
                    fontSize: "small",
                  }}
                  type="text"
                  name="service_name"
                  value={addService.service_name}
                  onChange={onChangeAddService}
                  placeholder="Service Name"
                />
                <OutlinedFormInput
                  style={{
                    width: "300px",
                    fontSize: "small",
                  }}
                  type="text"
                  name="service_des"
                  value={addService.service_des}
                  onChange={onChangeAddService}
                  placeholder="Service Description"
                />
                <select
                  name="active"
                  value={addService.active}
                  onChange={onChangeAddService}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "1px 5px",
                    borderRadius: "7px",
                    fontSize: "small",
                    height: "45px",
                  }}
                >
                  <option value="" disabled hidden>
                    Active
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>

                <button
                  style={{
                    width: "150px",
                    height: "45px",
                    background: "#b30000",
                  }}
                  onClick={au === "" ? handleAddService : handleEditService}
                >
                  {au === "" ? "Add" : "Edit"} Service
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <div className="pincode-table-container">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "3px 10px",
              }}
            >
              <Typography variant="p" fontWeight="600" color="#2c3e50">
                Services List
              </Typography>
            </Box>

            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "600",
                        backgroundColor: "#f8fafc",
                        padding: "1px 20px",
                        width: "140px",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "600",
                        backgroundColor: "#f8fafc",
                        padding: "1px 20px",
                      }}
                    >
                      Description
                    </TableCell>

                    <TableCell
                      sx={{
                        fontWeight: "600",
                        backgroundColor: "#f8fafc",
                        padding: "1px 20px",
                      }}
                    >
                      Status
                    </TableCell>

                    <TableCell
                      sx={{
                        fontWeight: "600",
                        backgroundColor: "#f8fafc",
                        padding: "1px 20px",
                        maxWidth: "200px",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {services?.map((service) => (
                    <TableRow
                      key={service?._id}
                      hover
                      sx={{
                        "&:hover": {
                          backgroundColor: "#f8fafc",
                        },
                      }}
                    >
                      <TableCell style={{ padding: "0px 20px" }}>
                        <Typography sx={{ fontWeight: 500, fontSize: "small" }}>
                          {service.service_name}
                        </Typography>
                      </TableCell>

                      <TableCell
                        style={{ padding: "1px 20px", fontSize: "small" }}
                      >
                        <Typography variant="caption" color="textSecondary">
                          {service.service_des}
                        </Typography>
                      </TableCell>

                      <TableCell
                        style={{
                          padding: "1px 0px",
                          fontSize: "small",
                        }}
                      >
                        <Typography variant="caption" color="textSecondary">
                          {service.active ? "Active" : "Inactive"}
                        </Typography>
                      </TableCell>

                      <TableCell
                        style={{
                          padding: "1px 20px",
                          fontSize: "small",
                          maxWidth: "200px",
                        }}
                      >
                        <button
                          style={{ background: "none", padding: "5px" }}
                          // onClick={() => deleteTest(testimonal?._id)}
                        ></button>
                        <button
                          style={{
                            background: "white",
                          }}
                          onClick={() => fillAddService(service._id)}
                        >
                          <Pencil color="blue" size={"14px"} />
                        </button>
                        <button
                          style={{
                            background: "white",
                          }}
                          onClick={() => deleteService(service._id)}
                        >
                          <Trash color="#800000" size={"14px"} />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default EditServices;
