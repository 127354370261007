import React from "react";
import {
  Typography,
  Box,
  TextField,
  Checkbox,
  Grid,
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  MenuItem,
  ListItemText,
} from "@mui/material";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { API_KEY } from "../../config/axiosConfig";
import { useTheme } from "@mui/material/styles";
import ButtonContained from "../Buttons/ButtonContained";
import classes from "../../styles/styles.js";

const PropertiesFilter = ({
  showFilter,
  setShowFilter,
  isMobile,
  onResetFilter,
  onApplyFilter,
  onCheck,
  wantTo,
  setWantTo,
  userType,
  setUserType,
  propertyType,
  setPropertyType,
  propertyFlat,
  setPropertyFlat,
  budget,
  setBudget,
  usp,
  setUsp,
  accessories,
  setAccessoryFilter,
  accessoriesFilter,
  locality,
  setLocality,
  numDifferentiation,
}) => {
  const theme = useTheme();
  let allPropertyFlat = [
    { name: "1 Bhk", value: 1 },
    { name: "2 Bhk", value: 2 },
    { name: "3 Bhk", value: 3 },
    { name: "4 Bhk", value: 4 },
    { name: "5 Bhk", value: 5 },
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <>
      {isMobile && (
        <Typography
          variant="subtitle1"
          color="text.secondary"
          component="div"
          onClick={() => {
            setShowFilter(!showFilter);
          }}
          sx={[
            classes.colorADB0B6,
            classes.fs20,
            classes.fontFamilyDS,
            classes.fbBold,
            classes.textDecorationUL,
            {
              cursor: "pointer",
              zIndex: 9,
              marginLeft: "auto",
              textAlign: "right",
            },
            {
              [theme.breakpoints.between("xs", "md")]: {
                ...classes.fs14,
                ...classes.fbBold,
                marginLeft: "auto",
              },
            },
          ]}
        >
          Show Filters
        </Typography>
      )}
      {(isMobile && showFilter) || !isMobile ? (
        <div
          className="col-lg-3 col-md-3 col-xs-12 bg-white p-4 container-scrollbar"
          style={{
            position: "relative",
            // height: isMobile ? "auto" : `calc(100vh - ${headerHeight}px)`,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Box
            className="mt-3"
            sx={[
              classes.p10,
              classes.dflex,
              classes.jcSBetween,
              classes.alignItemCenter,
            ]}
          >
            <Typography
              component="div"
              variant="h5"
              sx={[
                classes.fontFamilySGB,
                classes.fbBold,
                classes.fs24,
                classes.dflex,
                classes.color240501,
                {
                  [theme.breakpoints.between("xs", "md")]: {
                    ...classes.fs17,
                  },
                },
              ]}
            >
              Filters
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              onClick={onResetFilter}
              sx={[
                classes.colorADB0B6,
                classes.fs20,
                classes.fontFamilyDS,
                classes.fbBold,
                classes.textDecorationUL,
                {
                  cursor: "pointer",
                },
                {
                  [theme.breakpoints.between("xs", "md")]: {
                    ...classes.fs14,
                    ...classes.fbBold,
                  },
                },
              ]}
            >
              Reset Filters
            </Typography>
          </Box>
          <GooglePlacesAutocomplete
            apiKey={API_KEY}
            autocompletionRequest={{
              componentRestrictions: {
                country: ["in"],
              },
            }}
            selectProps={{
              district: locality,
              onChange: setLocality,
              placeholder: "Enter City, Locality",
            }}
          />
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={[
              classes.colorADB0B6,
              classes.fs20,
              classes.fontFamilyDS,
              classes.fbBold,
              classes.p10,
              {
                [theme.breakpoints.between("xs", "md")]: {
                  ...classes.fs17,
                },
              },
            ]}
          >
            Want To
          </Typography>
          <Box component="span" sx={{ display: "block" }}>
            {/* <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    xs={[classes.dBlock, classes.fdc]}
                  > */}
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Rent
              </Typography>
              <Checkbox
                value="rent"
                onChange={(e) => onCheck(wantTo, e.target.value, setWantTo)}
                checked={wantTo.includes("rent")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Buy
              </Typography>
              <Checkbox
                value="sell"
                onChange={(e) => onCheck(wantTo, e.target.value, setWantTo)}
                checked={wantTo.includes("sell")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            {/* <Box
                    className='d-flex justify-content-between w-100 align-items-center'
                    sx={classes.px10}
                  >
                    <Typography
                      sx={[
                        classes.fs16,
                        classes.color240501,
                        classes.fontFamilyDS,
                        {
                          [theme.breakpoints.between('xs', 'md')]: {
                            ...classes.fs14,
                          },
                        },
                      ]}
                    >
                      PG
                    </Typography>
                    <Checkbox
                      value='pg'
                      onChange={(e) => onCheck(wantTo, e.target.value, setWantTo)}
                      checked={wantTo.includes('pg')}
                      sx={{
                        '&.Mui-checked': classes.color9A291B,
                      }}
                    />
                  </Box> */}
          </Box>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={[
              classes.colorADB0B6,
              classes.fs20,
              classes.fontFamilyDS,
              classes.fbBold,
              classes.p10,
              {
                [theme.breakpoints.between("xs", "md")]: {
                  ...classes.fs17,
                },
              },
            ]}
          >
            User Type
          </Typography>
          <Box component="span" sx={{ display: "block" }}>
            {/* <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    xs={[classes.dBlock, classes.fdc]}
                  > */}
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Broker
              </Typography>
              <Checkbox
                value="broker"
                onChange={(e) => onCheck(userType, e.target.value, setUserType)}
                checked={userType?.includes("broker")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
          </Box>
          <Box component="span" sx={{ display: "block" }}>
            {/* <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    xs={[classes.dBlock, classes.fdc]}
                  > */}
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Developer
              </Typography>
              <Checkbox
                value="developer"
                onChange={(e) => onCheck(userType, e.target.value, setUserType)}
                checked={userType?.includes("developer")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
          </Box>

          <Box component="span" sx={{ display: "block" }}>
            {/* <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    xs={[classes.dBlock, classes.fdc]}
                  > */}
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Owner
              </Typography>
              <Checkbox
                value="owner"
                onChange={(e) => onCheck(userType, e.target.value, setUserType)}
                checked={userType?.includes("owner")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
          </Box>

          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={[
              classes.colorADB0B6,
              classes.fs20,
              classes.fontFamilyDS,
              classes.fbBold,
              classes.p10,
              {
                [theme.breakpoints.between("xs", "md")]: {
                  ...classes.fs17,
                },
              },
            ]}
          >
            Property Type
          </Typography>

          <Box component="span" sx={{ display: "block" }}>
            {/* <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    xs={[classes.dBlock, classes.fdc]}
                  > */}
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Flat
              </Typography>
              <Checkbox
                value="flat"
                onChange={(e) =>
                  onCheck(propertyType, e.target.value, setPropertyType)
                }
                checked={propertyType.includes("flat")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                House
              </Typography>
              <Checkbox
                value="house"
                onChange={(e) =>
                  onCheck(propertyType, e.target.value, setPropertyType)
                }
                checked={propertyType.includes("house")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Villa
              </Typography>
              <Checkbox
                value="villa"
                onChange={(e) =>
                  onCheck(propertyType, e.target.value, setPropertyType)
                }
                checked={propertyType.includes("villa")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Commercial Space
              </Typography>
              <Checkbox
                value="commercial space"
                onChange={(e) =>
                  onCheck(propertyType, e.target.value, setPropertyType)
                }
                checked={propertyType.includes("commercial space")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Land
              </Typography>
              <Checkbox
                value="land"
                onChange={(e) =>
                  onCheck(propertyType, e.target.value, setPropertyType)
                }
                checked={propertyType.includes("land")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>

            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Pg
              </Typography>
              <Checkbox
                value="pg"
                onChange={(e) =>
                  onCheck(propertyType, e.target.value, setPropertyType)
                }
                checked={propertyType.includes("pg")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>

            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Flat mate / Room mate
              </Typography>
              <Checkbox
                value="flatmate"
                onChange={(e) =>
                  onCheck(propertyType, e.target.value, setPropertyType)
                }
                checked={propertyType.includes("flatmate")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
          </Box>
          <Grid item xs={12} md={6} mt={2}>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id="property-type-label">Bedrooms</InputLabel>
              <Select
                labelId="property-type-label"
                value={propertyFlat}
                onChange={(e) => {
                  setPropertyFlat(e.target.value);
                }}
                label="Select Bedrooms"
                sx={{ borderRadius: "12px" }}
              >
                <MenuItem value="">
                  <em>Select Bedrooms</em>
                </MenuItem>
                {allPropertyFlat.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={[
              classes.colorADB0B6,
              classes.fs20,
              classes.fontFamilyDS,
              classes.fbBold,
              classes.p10,
              {
                [theme.breakpoints.between("xs", "md")]: {
                  ...classes.fs17,
                },
              },
            ]}
          >
            Budget
          </Typography>
          <Box sx={[classes.px10, { width: "100%" }]}>
            {/* <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  color={"secondary"}
                /> */}
            {/* <AirbnbSlider
                    slots={{ thumb: SliderThumbComponent }}
                    onChange={(e, value, activeThum) => {
                      console.log(value);
                      setBudget(value.map((i) => Math.floor(i)));
                    }}
                    value={budget}
                    min={1}
                    max={wantTo ==='rent' ? 100000 : 1000000000}
                  /> */}
            <div className="d-flex gap-2">
              <TextField
                value={budget[0]}
                variant="outlined"
                label="From"
                onChange={(e) => {
                  setBudget([e.target.value, budget[1]]);
                }}
              />
              <TextField
                variant="outlined"
                label="To"
                value={budget[1]}
                onChange={(e) => {
                  setBudget([budget[0], e.target.value]);
                }}
              />
            </div>
          </Box>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={[
              classes.color240501,
              classes.fs16,
              classes.fontFamilyDS,
              classes.fontWeight500,
              classes.p10,
              classes.lh24,
            ]}
          >
            {wantTo === "rent" ? (
              <>
                {numDifferentiation(budget[0])} - {numDifferentiation(100000)}{" "}
                Rupees
              </>
            ) : (
              <>
                {numDifferentiation(budget[0])} -{" "}
                {numDifferentiation(budget[1])} Rupees
              </>
            )}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={[
              classes.colorADB0B6,
              classes.fs20,
              classes.fontFamilyDS,
              classes.fbBold,
              classes.p10,
              {
                [theme.breakpoints.between("xs", "md")]: {
                  ...classes.fs17,
                },
              },
            ]}
          >
            USP
          </Typography>
          <Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Couple Friendly
              </Typography>
              <Checkbox
                onChange={(e) => onCheck(usp, e.target.value, setUsp)}
                checked={usp.includes("couple friendly")}
                value="couple friendly"
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Pet Friendly
              </Typography>
              <Checkbox
                value="pet friendly"
                onChange={(e) => onCheck(usp, e.target.value, setUsp)}
                checked={usp.includes("pet friendly")}
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
            <Box
              className="d-flex justify-content-between w-100 align-items-center"
              sx={classes.px10}
            >
              <Typography
                sx={[
                  classes.fs16,
                  classes.color240501,
                  classes.fontFamilyDS,
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                    },
                  },
                ]}
              >
                Restriction Free
              </Typography>
              <Checkbox
                onChange={(e) => onCheck(usp, e.target.value, setUsp)}
                checked={usp.includes("restriction free")}
                value="restriction free"
                sx={{
                  "&.Mui-checked": classes.color9A291B,
                }}
              />
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={[
              classes.colorADB0B6,
              classes.fs20,
              classes.fontFamilyDS,
              classes.fbBold,
              classes.p10,
              {
                [theme.breakpoints.between("xs", "md")]: {
                  ...classes.fs17,
                },
              },
            ]}
          >
            Accessories
          </Typography>
          <Box>
            {accessories.map((item) => (
              <Box
                className="d-flex justify-content-between w-100 align-items-center"
                sx={classes.px10}
              >
                <Typography
                  sx={[
                    classes.fs16,
                    classes.color240501,
                    classes.fontFamilyDS,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs14,
                      },
                    },
                  ]}
                >
                  {item.accessoryName}
                </Typography>
                <Checkbox
                  onChange={(e) =>
                    onCheck(
                      accessoriesFilter,
                      e.target.value,
                      setAccessoryFilter
                    )
                  }
                  checked={accessoriesFilter.includes(item._id)}
                  value={item._id}
                  sx={{
                    "&.Mui-checked": classes.color9A291B,
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box>
            <ButtonContained
              onClick={onApplyFilter}
              sx={[
                classes.bg9A291B,
                classes.width95,
                classes.fbBold,
                classes.fontFamilySGB,
                classes.fs16,
                classes.colorWhite,
                classes.borderRadius12,
                classes.mt50,
              ]}
            >
              Apply Filter
            </ButtonContained>
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default PropertiesFilter;
