import { React, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { privateReq } from "../../config/axiosConfig";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import "./index.css";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Modal,
  Breadcrumbs,
} from "@mui/material";
import { Trash } from "lucide-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  display: "flex",
  borderRadius: "7px",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: 24,
  p: 4,
};

const AdminCategory = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const headerRef = useRef();
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [typeCategory, setTypeCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [addCategory, setAddCategory] = useState();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [pincodes, setPincodes] = useState();
  const [addNotificationDate, setAddNotificationDate] = useState({
    username: "",
    phone: "",
    description: "",
  });

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [id, setId] = useState();

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   setHeaderHeight(headerRef.current.offsetHeight);
  // }, [headerHeight]);

  useEffect(() => {
    onGetCategory();
  }, []);

  const onGetCategory = async () => {
    try {
      const res = await privateReq.get("get-category");
      setCategoryList(res?.data?.category);
      let ids = res?.data?.category.map((id) => id._id);

      onGetSubCategory(ids);
    } catch (err) {
      console.log(err);
    }
  };

  const onGetSubCategory = async (id) => {
    try {
      const res = await privateReq.post(`get-subcategory`, {
        id,
      });
      setSubCategoryList(res?.data?.getSubcategory);
    } catch (err) {
      console.log(err);
    }
  };

  const onAddCategory = async () => {
    if (!typeCategory) {
      toast.error("Please select type", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else if (!addCategory) {
      toast.error("Please enter category name", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else {
      const res = await privateReq.post("add-category", {
        name: addCategory,
        type: typeCategory,
      });
     toast.success("Category Added!");
     setAddCategory("");
     setTypeCategory("");
      onGetCategory();
    }
  };

  const onAddSubCategory = async () => {
    if (!selectedCategory) {
      toast.error("Please select category", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else if (!selectedSubCategory) {
      toast.error("Please enter sub category name", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else {
      const res = await privateReq.post("add-subcategory", {
        name: selectedSubCategory,
        category: selectedCategory,
      });
      setSelectedCategory("");
      setSelectedSubCategory("");
      toast.success("Sub-Category Added!")
      console.log("res", res);
      onGetCategory();
    }
  };

  const onDeleteCategory = async (id) => {
    const res = await privateReq.delete(`admin-delete-category/${id}`);
    toast.success("Category Deleted!")
    onGetCategory();
  };
  const onDeleteSubCategory = async (id) => {
    const res = await privateReq.delete(`admin-delete-subcategory/${id}`);
    toast.success("Sub-Category Deleted!")
    onGetCategory();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return showPage ? (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />

       
<div style={{marginLeft:"70px", marginTop:"150px", marginBottom:"0px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

          

            <Typography sx={{ color: "text.primary" }}>
            Category Management
            </Typography>
          </Breadcrumbs>
          </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "0px",
        }}
      >

       

        <div className="category-container">
          <div className="category-inner-container">
            <p style={{ marginTop: "0px" }}>Add Category</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <select
                  id="categorySelect"
                  className="category-select"
                  onChange={(e) => setTypeCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  <option value={"buy"}>Buy</option>
                  <option value={"rent"}>Rent</option>
                </select>
              </div>
              <div>
                <input
                  style={{
                    width: "200px",
                  }}
                  type="text"
                  name="selectedSubCategory"
                  value={addCategory}
                  onChange={(e) => setAddCategory(e.target.value)}
                  placeholder="Category"
                  className="category-select"
                />
              </div>
              <ButtonContained
                className={"category-btn"}
                onClick={() => onAddCategory()}
              >
                Save
              </ButtonContained>
            </div>
          </div>

          <div className="category-inner-container">
            <p style={{ marginTop: "0px" }}>Add SubCategory</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "2px",
                }}
              >
                <select
                  id="categorySelect"
                  className="category-select"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {[...categoryList].reverse().map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name} {`(${category.type})`}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <input
                  style={{
                    width: "200px",
                  }}
                  className="category-select"
                  type="text"
                  name="selectedSubCategory"
                  value={selectedSubCategory}
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                  placeholder="Sub Category"
                />
              </div>
              <ButtonContained
                className="category-btn"
                onClick={() => onAddSubCategory()}
              >
                Save
              </ButtonContained>
            </div>
          </div>
        </div>

        <div className="category-table-container">
          <div className="category-table">
            <Typography
              variant="p"
              sx={{
                fontFamily: "Montserrat",
                paddingBottom: 3,
                fontSize: "small",
                fontWeight: "600",
              }}
            >
              Category List:
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                marginBottom: 4,
                maxWidth: "1000px",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                      Type
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryList?.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>
                        <Button
                          style={{ backgroundColor: "white" }}
                          color="error"
                          size="small"
                          onClick={() => (setId(item?._id), setOpen(true))}
                        >
                          <Trash size={"16px"} color="#930000" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="category-table">
            {/* Sub-Category List */}
            <Typography
              variant="p"
              sx={{
                fontFamily: "Montserrat",
                paddingBottom: 3,
                fontSize: "small",
                fontWeight: "600",
              }}
            >
              Sub-Category List:
            </Typography>

            {subCategoryList?.length === 0 ? (
              <p className="no-category">No sub-category found</p>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: "1000px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                        Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                        Category Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                        Category Type
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subCategoryList?.map((item) => (
                      <TableRow key={item._id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.category?.name}</TableCell>
                        <TableCell>{item.category?.type}</TableCell>
                        <TableCell>
                          <Button
                            style={{ backgroundColor: "white" }}
                            color="error"
                            size="small"
                            onClick={() => onDeleteSubCategory(item?._id)}
                          >
                            <Trash size={"16px"} color="#930000" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>

      <div>
        {/* <button onClick={handleOpen}>Open modal</button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Category
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete?
            </Typography>
            <div className="delete-btn-modal">
              <button onClick={() => setOpen(false)}>Cancel</button>
              <button
                onClick={() => (onDeleteCategory(id), setOpen(false))}
                style={{ backgroundColor: "#b30000" }}
              >
                Delete
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default AdminCategory;
