import React, { useEffect, useState } from "react";
import { privateReq } from "../../config/axiosConfig";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
  TextField,
  InputAdornment,
  Select,
  option,
  FormControl,
  InputLabel,
  IconButton,
  Pagination,
  Stack,
  Paper,
  Breadcrumbs,
} from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Header from "../../components/Header";
import toast from "react-hot-toast";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import "./index.css";
import { Trash } from "lucide-react";

import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  display: "flex",
  borderRadius: "7px",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: 24,
  p: 4,
};

const UserList = ({
  user_loggedin,
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const roleFromUrl = urlParams.get("role");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [role, setRole] = useState(roleFromUrl || "");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit] = useState(20);
  const [deleteUserId, setDeleteUserId] = useState();

  useEffect(() => {
    fetchUsers();
  }, [page, searchQuery, role, startDate, endDate]);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const roleFromUrl = urlParams.get("role");
  //   if (roleFromUrl) {
  //     setRole(roleFromUrl);
  //     fetchUsers();
  //   }
  // }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        page,
        limit,
      });
      params.append("search", searchQuery);
      params.append("role", role);
      params.append("startDate", startDate);
      params.append("endDate", endDate);
      const response = await privateReq.get(`/users-list?${params.toString()}`);
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setTotalUsers(response.data.totalUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await privateReq.delete(`/users-admin/${id}`);
      setUsers(users.filter((user) => user.id !== id));
      toast.success(res?.data?.message);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user");
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "activated":
        return "#4caf50";
      case "deactivated":
        return "#f44336";
      default:
        return "#ff9800";
    }
  };

  const getRoleColor = (role) => {
    switch (role?.toLowerCase()) {
      case "admin":
        return "#e91e63";
      case "broker":
        return "#2e7d32";
      case "owner":
        return "#1976d2";
      case "serviceman":
        return "#ed6c02";
      default:
        return "#9c27b0";
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Debounce search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (page !== 1) {
        setPage(1); // Reset to first page when search changes
      } else {
        fetchUsers();
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />


      <Box
        sx={{
          p: 4,
          mt: 8,
          minHeight: "100vh",
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)",
        }}
      >

<div style={{marginLeft:"0px", marginTop:"40px", marginBottom:"0px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/admin" underline="hover" color="inherit" >
              Dashboard
            </Link>

          

            <Link underline="hover" color="inherit" to="/admin/user-management">
              User Management
            </Link>

            <Typography sx={{ color: "text.primary", textTransform:"capitalize" }}>
            {roleFromUrl || "All Users"}
            </Typography>
          </Breadcrumbs>
          </div>
        {/* Header Section */}
        <Paper
          elevation={0}
          sx={{
            p: 1,
            mb: 1,
            borderRadius: 3,
            color: "black",
            mt: 4,
          }}
        >
          <PeopleAltIcon sx={{ fontSize: 24, mr: 2 }} />
          <Typography variant="p" sx={{ fontWeight: 600 }}>
            User Management
          </Typography>

          <Typography
            variant="p"
            sx={{ opacity: 0.9, ml: 3, fontSize: "13px", color: "gray" }}
          >
            {totalUsers} Total Users • Page {page} of {totalPages}
          </Typography>
        </Paper>

        {/* Filters Section */}
        <Paper
          elevation={1}
          sx={{
            p: 1,
            mb: 1,
            borderRadius: 2,
            background: "white",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <input
                className="name-input"
                placeholder="Search by name, email, or phone"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl style={{ padding: "0px" }} fullWidth>
                {/* <InputLabel>Role</InputLabel> */}
                <select
                  className="name-input"
                  value={role}
                  label="Role"
                  onChange={(e) => {
                    setRole(e.target.value);
                    setPage(1); // Reset to first page when role changes
                  }}
                  sx={{ borderRadius: 2 }}
                >
                  <option value="">All Roles</option>

                  <option value="admin">Admin</option>
                  <option value="owner">Owner</option>
                  <option value="broker">Broker</option>
                  <option value="guest">Guest</option>
                  <option value="serviceman">Serviceman</option>
                </select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <input
                className="name-input"
                fullWidth
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setPage(1); // Reset to first page when date changes
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <input
                className="name-input"
                fullWidth
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setPage(1); // Reset to first page when date changes
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* User Cards Grid */}
        <Grid container>
          {loading ? (
            <Box sx={{ p: 1, textAlign: "center", width: "100%" }}>
              <Typography>Loading users...</Typography>
            </Box>
          ) : users.length === 0 ? (
            <Box sx={{ p: 3, textAlign: "center", width: "100%" }}>
              <Typography>No users found</Typography>
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={600}>Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Role</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Email</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Credits</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Joined</Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography fontWeight={600}>Status</Typography>
                    </TableCell> */}
                    <TableCell>
                      <Typography fontWeight={600}>Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      {/* Name */}
                      <TableCell>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: "#2c3e50",
                              fontSize: "small",
                            }}
                          >
                            {user?.name?.charAt(0).toUpperCase() +
                              user?.name?.slice(1)}
                          </Typography>
                        </Box>
                      </TableCell>

                      {/* Role */}
                      <TableCell
                        style={{
                          textTransform: "uppercase",
                          fontSize: "small",
                        }}
                      >
                        {user.role}
                      </TableCell>

                      {/* Email */}
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="body2">{user.email}</Typography>
                        </Box>
                      </TableCell>

                      {/* Phone */}
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {/* <PhoneIcon
                            sx={{ color: "#2e7d32", mr: 1, fontSize: 20 }}
                          /> */}
                          <Typography variant="body2">{user.phone}</Typography>
                        </Box>
                      </TableCell>

                      {/* Credits */}
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "15px",
                          }}
                        >
                          <Typography variant="body2">
                            {user.credits || 0}
                          </Typography>
                        </Box>
                      </TableCell>

                      {/* Joined */}
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="body2">
                            {formatDate(user.created)}
                          </Typography>
                        </Box>
                      </TableCell>

                      {/* Status */}
                      {/* <TableCell>
                        <Chip
                          label={user.status}
                          size="small"
                          sx={{
                            width: "fit-content",
                            bgcolor: `${getStatusColor(user.status)}15`,
                            color: getStatusColor(user.status),
                            fontWeight: 500,
                          }}
                        />
                      </TableCell> */}

                      <TableCell>
                        <button
                          className="delete-button"
                          onClick={() => (
                            setDeleteUserId(user.id), setOpen(true)
                          )}
                        >
                          <Trash size={"14px"} color="#990000" />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>

        {/* Pagination */}
        {!loading && users.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, value) => setPage(value)}
              color="primary"
              size="large"
              sx={{
                "& .MuiPaginationItem-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Box>
        )}
      </Box>

      <div>
        {/* <button onClick={handleOpen}>Open modal</button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete User
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete the user?
            </Typography>
            <div className="delete-btn-modal">
              <button onClick={() => setOpen(false)}>Cancel</button>
              <button
                onClick={() => (handleDelete(deleteUserId), setOpen(false))}
                style={{ backgroundColor: "#b30000" }}
              >
                Delete
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default UserList;
