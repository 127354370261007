import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logofull from '../../assets/images/logo.jpg';
import Login from '../Login';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  Toolbar: {
    marginTop: '20px',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center', // Center the logo on small screens
    },
  },
  logoImage: {
    height: '40px',
    borderRadius: "100%",
    [theme.breakpoints.down('sm')]: {
      height: '25px', // Reduce logo size on small screens
    },
  },
  navText: {
    flexGrow: 1,
    textAlign: 'center',
    color: '#000',
    fontSize: "1.2rem",
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the text on small screens
    },
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', // Stack buttons vertically on small screens
      gap: '5px',
    },
  },
  listButton1: {
    backgroundColor: '#F5BC00',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#FFB300',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px', // Reduce button text size for mobile
    },
  },
  loginButton22: {
    backgroundColor: '#B90000',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#C62828',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px', // Reduce button text size for mobile
    },
  },
  loginOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 9999, // High z-index to appear on top of everything
  },
  loginModal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 10000, // Higher z-index for the modal itself
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true); // Show the Login component when the login button is clicked
  };

  const handleCloseLogin = () => {
    setShowLogin(false); // Close the Login component
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Hook to check if screen is mobile

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar className={classes.Toolbar}>
          <Box className={classes.logo}>
            <img src={logofull} alt="Logo" className={classes.logoImage} />
            <h3
              className="ms-2"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "column",
                gap: "1px",
                justifyContent: "center",
              }}
            >
              <p style={{ marginBottom: "1px" }}>
                Ghar
                <span style={{ color: "#9A291B" }}>Wala</span>
                <sup style={{ fontSize: "15px" }}>TM</sup>
              </p>

              <span
                style={{
                  fontSize: "8.5px",
                  marginLeft: "2px",
                }}
              >
                (GharWala Home Solutions LLP)
              </span>
            </h3>
          </Box>

          {/* Hide the text on mobile */}
          {!isMobile && (
            <Typography variant="h6" className={classes.navText}>
              Properties
            </Typography>
          )}

          <Box className={classes.buttons}>
            <button variant="contained" className={classes.listButton1}>
              List Property
            </button>
            <button
              variant="contained"
              className={classes.loginButton22}
              onClick={handleLoginClick}
            >
              Login
            </button>
          </Box>
        </Toolbar>
      </AppBar>

      {showLogin && (
        <div className={classes.loginOverlay}>
          <div className={classes.loginModal}>
            <Login />
            <Button onClick={handleCloseLogin}>Close</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;


