import { MenuItem, Select, Slider, FormControl } from "@mui/material";
import { useState } from "react";
import { API_KEY } from "../../config/axiosConfig.js";
import { useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import "./search.css";

export default function SearchBar() {
  const navigate = useNavigate();
  const [propertyopen, setpropertyopen] = useState(false);
  const [district, setDistrict] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [maxPrice, setMaxPrice] = useState([0, 500000]);
  const [propertyType, setpropertyType] = useState("");

  const getLocLatLng = () => {
    return geocodeByAddress(district?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("Successfully got latitude and longitude", { lat, lng });
        return { lat, lng };
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSearch = async () => {
    let obj = await getLocLatLng();
    let queryParams = {};
    if (propertyType) queryParams.propertyType = propertyType;
    if (serviceType) queryParams.wantto = serviceType;
    if (maxPrice?.[0] !== undefined && maxPrice[0] !== 0)
      queryParams.min = maxPrice[0];
    if (maxPrice?.[1] !== undefined && maxPrice[1] !== 500000)
      queryParams.max = maxPrice[1];
    if (obj?.lat) queryParams.latitude = obj.lat;
    if (obj?.lng) queryParams.longitude = obj.lng;
    const queryString = new URLSearchParams(queryParams).toString();
    navigate(`/properties?${queryString}`);
  };

  return (
    <div className="search-bar-wrapper ">
      <div className="search-outer">
        <div className="search-container">
          <div className="search-section1">
            <h3>Location</h3>

            <GooglePlacesAutocomplete
              apiKey={API_KEY}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["in"],
                },
              }}
              selectProps={{
                value: district,
                onChange: setDistrict,
                placeholder: "Enter City, Locality",
                className: "location-input",
                styles: {
                  input: (provided) => ({
                    ...provided,
                    width: 300,
                  }),
                },
              }}
            />
          </div>

          <div className="search-section">
            <h3>Purpose</h3>
            <FormControl fullWidth>
              <Select
                
                value={serviceType}
                onChange={(e) => {
                  setServiceType(e.target.value);
                  e.target.value === "rent"
                    ? setMaxPrice([0, 500000])
                    : setMaxPrice([100000, 50000000]);
                }}
                displayEmpty
                className="search-select"
              >
                <MenuItem value="" disabled>
                  Select Purpose
                </MenuItem>
                <MenuItem value="sell">Buy</MenuItem>
                <MenuItem value="rent">Rent</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="search-section">
            <h3>Property</h3>
            <FormControl fullWidth>
              <Select
                
                value={propertyType}
                onChange={(e) => setpropertyType(e.target.value)}
                displayEmpty
                className="search-select"
                open={propertyopen}
                onClose={() => setpropertyopen(false)}
                onOpen={() => setpropertyopen(true)}
              >
                <MenuItem value="" disabled>
                  Select Property
                </MenuItem>

                {[
                  "flat",
                  "house",
                  "villa",
                  "land",
                  "pg",
                  "commercial",
                  "Flat mate / Room mate",
                ].map((item) => (
                  <MenuItem key={item} value={item} className="text-capitalize">
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="budget-container">
          <h3>Budget</h3>
          <div className="budget-labels">
            <span style={{ width: "120px" }}>
              Min {maxPrice[0].toLocaleString()}
            </span>
            <Slider
              value={maxPrice}
              onChange={(e, newValue) => setMaxPrice(newValue)}
              min={0}
              max={serviceType === "rent" ? 500000 : 50000000}
              step={10000}
              className="budget-slider"
            />
            <span style={{ width: "120px", marginLeft: "20px" }}>
              Max {maxPrice[1].toLocaleString()}{" "}
            </span>
          </div>
        </div>
      </div>

      <button onClick={onSearch} className="search-button">
        Search
      </button>
    </div>
  );
}
