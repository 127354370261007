import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ModalSignUp from "./ModalSignup";
import SignUp from "./Signup";
import ModalLogin from "./ModalLogin";
import Login from "./Login";
import GHModal from "./Modal";
import Profile from "./Profile";
import RatingModal from "./ModalRating";
import Ratings from "./Ratings";
import PropertyContactViewed from "./PropertiesContactView";
import CartModal from "./Cart";
import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";
import { createSessionService } from "../config/user";
import { useSelector } from "react-redux";
import {
  FaHeart,
  FaClipboardList,
  FaBackspace,
  FaBackward,
} from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaTag } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import {
  OutlinedInput,
  Avatar,
  Rating,
  Box,
  Typography,
  Divider,
  Badge,
  MenuItem,
  Menu,
  Button,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import styles from "../styles/styles";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LogoutIcon from "@mui/icons-material/Logout";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import WeekendIcon from "@mui/icons-material/Weekend";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ButtonContained from "./Buttons/ButtonContained";
import { AddOutlined, Money } from "@mui/icons-material";
import publicReq, { assetUrl } from "../config/axiosConfig";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Logo from "../assets/images/logo.jpg";
import "./index.css";
import Profession from "./Profession/Profession";

function HeaderMobile() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [alignment, setAlignment] = useState("right");
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const [firstPropty, setFirstPropty] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [closeModal, setCloseModal] = useState(false);
  const [isPropertyFree, setIsPropertyFree] = useState(false);
  const [closeLoginModal, setCloseLoginModal] = useState(false);
  const [closeProfileModal, setCloseProfileModal] = useState(false);
  const [closeRatingsModal, setCloseRatingsModal] = useState(false);
  const [closeCartsModal, setCloseCartsModal] = useState(false);
  const [totalItemsInCart, setTotalItemsInCart] = useState(0);
  const [closePropertiesContactViewModal, setClosePropertiesContactViewModal] =
    useState(false);

  const [activeItem, setActiveItem] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);

  const open = Boolean(anchorEl);
  const openProfile = Boolean(anchorElProfile);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const handleClick = (event) => {
    if (
      (user?.role === "broker" ||
      user?.role === "developer") && user?.credits === 0)
     {
      toast.error("Please buy credits to add more listing");
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const createSession = async (e) => {
    e.preventDefault();
    try {
      const res = await createSessionService();
      if (res.data.status) {
        window.location.href = res.data.url;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };
  const handleAlignment = (newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleBuyCredits = () => {
    navigate("/buy-credits");
  };

  const [showProperty, setShowProperty] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [loadingProperty, setLoadingProperty] = useState(true);
  const [loadingServices, setLoadingServices] = useState(true);

  async function checkTabS() {
    try {
      const result = await publicReq.post("/get-access-for-basic-features", {
        propertyName: "PROPERTY",
      });

      // const results = await Promise.all(promises);
      console.log("tab", result);
      setShowProperty(result.data.message.enabled);

      // setBroker(results[0].data.message.enabled);
      // setOwner(results[1].data.message.enabled);
      // setService(results[2].data.message.enabled);
      // setProperty(results[3].data.message.enabled);
    } catch (error) {
      toast.error("server overloaded");
      console.log(error);
    } finally {
      setLoadingProperty(false);
    }
  }
  async function checkTabS2() {
    try {
      const result = await publicReq.post("/get-access-for-basic-features", {
        propertyName: "SERVICES",
      });

      // const results = await Promise.all(promises);
      setShowServices(result.data.message.enabled);

      // setBroker(results[0].data.message.enabled);
      // setOwner(results[1].data.message.enabled);
      // setService(results[2].data.message.enabled);
      // setProperty(results[3].data.message.enabled);
    } catch (error) {
      toast.error("server overloaded");
      console.log(error);
    } finally {
      setLoadingServices(false);
    }
  }

  useEffect(() => {
    checkTabS();
    checkTabS2();
  }, []);

  return (
    <div className={sidebarOpen ? "isOpen" : "isClose"}>
      <Sidebar
        pullRight
        sidebar={
          <div
            style={{
              background: "white",
              minHeight: "100vh",
              zIndex: "999999",
            }}
            className="p-4"
          >
            <Link className="navbar-brand" to="/">
              <div
                class="brand-logo"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={Logo}
                  style={{
                    height: "25px",
                    width: "25px",
                  }}
                />
                <h4
                  className="ms-2"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  Ghar
                  <span style={{ color: "#9A291B" }}>Wala</span>
                  <sup style={{ fontSize: "15px" }}>TM</sup>
                </h4>
              </div>
            </Link>
            <div id="navbarNav">
              <div></div>
              {showProperty && (
                <div className="navlinks-header-auth mt-4">
                  {(user == null || user?.role !== "serviceman") && (
                    <div className="navlinks-header-auth-icons">
                      <NavLink
                        style={{
                          color: "#9a291b",
                        }}
                        className={"fs-6"}
                        to="/properties"
                      >
                        Properties
                      </NavLink>
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: "10px",
                            width: "10px",
                          }}
                        />
                      )}
                    </div>
                  )}
                  {
                    <div className="navlinks-header-auth-icons">
                      {/* <NavLink
											style={{
												color: "#9a291b",
											}}
											className={"fs-6"}
											to={"/furniture"}
										>
											Furniture & Home Appliances
										</NavLink> */}
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: "10px",
                            width: "10px",
                          }}
                        />
                      )}
                    </div>
                  }
                  {user?.role === "admin" && (
                    <div className="navlinks-header-auth-icons">
                      <NavLink
                        style={{
                          color: "#9a291b",
                        }}
                        className={"fs-6"}
                        to="/owners"
                      >
                        Owners
                      </NavLink>
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: "10px",
                            width: "10px",
                          }}
                        />
                      )}
                    </div>
                  )}
                  {user?.role === "admin" && (
                    <div className="navlinks-header-auth-icons">
                      <NavLink
                        style={{
                          color: "#9a291b",
                        }}
                        className={"fs-6"}
                        to="/brokers"
                      >
                        Brokers
                      </NavLink>
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: "10px",
                            width: "10px",
                          }}
                        />
                      )}
                    </div>
                  )}
                  {showServices && (
                    <div className="navlinks-header-auth-icons">
                      <NavLink
                        style={{
                          color: "#9a291b",
                        }}
                        className={"fs-6"}
                        to="/other-services"
                      >
                        Services
                      </NavLink>
                      {!isMobile && (
                        <FiberManualRecordIcon
                          sx={{
                            height: "10px",
                            width: "10px",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {token ? (
                <div className={`d-block `}>
                  {user?.role !== "guest" && (
                    <ButtonContained
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      sx={[
                        {
                          display: "flex",
                          width: "fit-content",
                          padding: "10px 16px",
                          borderRadius: "12px",
                          // margin: "0px 20px",
                          fontSize: "12px",
                        },
                      ]}
                      startIcon={<AddOutlined />}
                    >
                      {!isMobile
                        ? user?.role === "admin"
                          ? " Add New"
                          : "Add Listing"
                        : user?.role === "admin"
                        ? " Add"
                        : "Add"}
                    </ButtonContained>
                  )}
                  {user?.role == "guest" && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();

                        setCloseCartsModal(true);
                      }}
                      className="mx-2 me-3"
                      sx={{
                        minWidth: "0px",
                      }}
                    >
                      <Badge badgeContent={totalItemsInCart} color="error">
                        <ShoppingCartIcon
                          sx={{
                            // width: "20px",
                            // height: "20px",
                            color: "#9A291B",
                          }}
                        />
                      </Badge>
                    </Button>
                  )}
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      borderRadius: "8px",
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {user?.role !== "serviceman" && (
                      <Link
                        to={"/add-property"}
                        className="header-fropdown-links"
                      >
                        <MenuItem
                        //  onClick={handleClose}
                        >
                          <Box
                            className="text-center"
                            sx={{
                              backgroundColor: "#f5f5f5",
                              borderRadius: "30px",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <MapsHomeWorkIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "#ADB0B6",
                              }}
                            />
                          </Box>
                          <Typography
                            className="mx-2"
                            sx={[
                              styles.fontFamilyDS,
                              styles.fs16,
                              styles.color240501,
                            ]}
                          >
                            Add New Property
                          </Typography>
                        </MenuItem>
                      </Link>
                    )}

                    {user?.role === "admin" && (
                      <>
                        <Divider className="mx-3 my-0" />

                        <Link
                          to="/add-furniture"
                          className="header-fropdown-links"
                        >
                          <MenuItem>
                            <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <WeekendIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box>
                            <Typography
                              className="mx-2"
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Add New Furniture
                            </Typography>
                          </MenuItem>
                        </Link>
                        <Divider className="mx-3 my-0" />
                        <Link
                          to="/add-broker"
                          className="header-fropdown-links"
                        >
                          <MenuItem
                          //  onClick={handleClose}
                          >
                            <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <PersonIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box>
                            <Typography
                              className="mx-2"
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Add New Broker
                            </Typography>
                          </MenuItem>
                        </Link>
                      </>
                    )}
                    {user?.role === "admin" && (
                      <>
                        <Divider className="mx-3 my-0" />
                        <Link
                          to="/add-accessories "
                          className="header-fropdown-links"
                        >
                          <MenuItem
                          //  onClick={handleClose}
                          >
                            <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <SettingsAccessibilityIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box>
                            <Typography
                              className="mx-2"
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Add Amenities
                            </Typography>
                          </MenuItem>
                        </Link>
                      </>
                    )}

                    {(user?.role === "admin" ||
                      user?.role === "serviceman") && (
                      <>
                        <Divider className="mx-3 my-0" />

                        <Link
                          to="/add-other-service "
                          className="header-fropdown-links"
                        >
                          <MenuItem
                          //  onClick={handleClose}
                          >
                            <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <HomeRepairServiceIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box>
                            <Typography
                              className="mx-2"
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Add Service
                            </Typography>
                          </MenuItem>
                        </Link>
                      </>
                    )}
                  </Menu>

                  <Button
                    aria-controls={
                      openProfile ? "basic-profile-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openProfile ? "true" : undefined}
                    onClick={handleClickProfile}
                  >
                    <Avatar
                      sx={{
                        height: "48px",
                        width: "48px",
                        // display: "flex",
                        // width: "fit-content",
                        // padding: "12px 16px",
                        borderRadius: "48px",
                        // margin: "0px 20px"
                      }}
                      alt={user?.name}
                      src={assetUrl + user?.avatarImage}
                    />
                  </Button>
                  <Menu
                    onClose={handleCloseProfile}
                    id="basic-profile-menu"
                    anchorEl={anchorElProfile}
                    open={openProfile}
                    sx={{
                      borderRadius: "8px",
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button-profile",
                    }}
                  >
                    {user !== null && (
                      <MenuItem>
                        <Box>
                          <Typography
                            sx={[
                              styles.fs16,
                              styles.fontFamilySGB,
                              styles.color9A291B,
                            ]}
                          >
                            {user?.name}
                          </Typography>
                          <Typography
                            sx={[
                              styles.fs12,
                              styles.fontFamilySG,
                              styles.colorADB0B6,
                            ]}
                            className="text-capitalize"
                          >
                            {user?.role}
                          </Typography>
                          {user?.role === "broker" ||
                            (user?.role === "developer" && (
                              <Typography
                                sx={[
                                  styles.fs12,
                                  styles.fontFamilySG,
                                  styles.color9A291B,
                                ]}
                                className="text-capitalize"
                              >
                                Credits Left: {user?.credits}
                              </Typography>
                            ))}
                        </Box>
                      </MenuItem>
                    )}
                    <Divider className="mx-3 my-0" />
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();

                        // setCloseProfileModal(true);
                        navigate("/profile");
                      }}
                      //  onClick={handleClose}
                    >
                      {/* <img src={UserIconProfile} /> */}
                      {/* <Box
                        className="text-center"
                        sx={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "30px",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <PersonIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "#ADB0B6",
                          }}
                        />
                      </Box> */}
                      <FaUser
                        style={{
                          color: "#9A291B",
                          height: "24px",
                          width: "24px",
                          marginRight: "2px",
                        }}
                      />
                      <Link to={"#"} className="header-fropdown-links mx-3">
                        <Typography
                          sx={[
                            styles.fontFamilyDS,
                            styles.fs16,
                            styles.color240501,
                          ]}
                        >
                          My Profile
                        </Typography>
                      </Link>
                    </MenuItem>
                    {user?.role === "broker" ||
                      (user?.role === "developer" && (
                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            // onClick={handleClickBuyCredits}
                            //  onClick={createSession}
                            onClick={handleClose}
                          >
                            {/* <img src={UserIconProfile} /> */}
                            {/* <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <Money
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box> */}

                            <FaCreditCard
                              style={{
                                color: "#9A291B",
                                height: "24px",
                                width: "24px",
                                marginRight: "2px",
                              }}
                            />
                            <button
                              className=""
                              style={{
                                border: "none",
                                background: "none",
                                color: "black",
                              }}
                              onClick={handleBuyCredits}
                            >
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                Buy Credits
                              </Typography>
                            </button>
                          </MenuItem>
                        </>
                      ))}

                    {(user?.role === "broker" ||
                      user?.role === "developer" ||
                      user?.role == "owner") && (
                      <>
                        <Divider className="mx-3 my-0" />
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/manage-properties");
                          }}
                        >
                          {/* <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <HomeMaxOutlined
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",

                                }}
                              />
                            </Box> */}
                          <FaKey
                            style={{
                              color: "#9A291B",
                              height: "24px",
                              width: "24px",
                              marginRight: "2px",
                            }}
                          />
                          <button
                            className=""
                            style={{
                              border: "none",
                              background: "none",
                              color: "black",
                            }}
                            // onClick={() => setOpenPopUp(true)}
                          >
                            <Typography
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Manage Properties
                            </Typography>
                          </button>
                        </MenuItem>
                      </>
                    )}

                    {(user?.role === "broker" ||
                      user?.role === "developer" ||
                      user?.role == "owner") && (
                      <>
                        <Divider className="mx-3 my-0" />
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/viewed-properties-all");
                          }}
                        >
                          {/* <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <HomeMaxOutlined
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",

                                }}
                              />
                            </Box> */}
                          <FaEye
                            style={{
                              color: "#9A291B",
                              height: "24px",
                              width: "24px",
                              marginRight: "2px",
                            }}
                          />
                          <button
                            className=""
                            style={{
                              border: "none",
                              background: "none",
                              color: "black",
                            }}
                            // onClick={() => setOpenPopUp(true)}
                          >
                            <Typography
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              View Responses
                            </Typography>
                          </button>
                        </MenuItem>
                      </>
                    )}

                    {(user?.role === "broker" ||
                      user?.role === "developer" ||
                      user?.role == "owner") && (
                      <>
                        <Divider className="mx-3 my-0" />
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/wishlist");
                          }}
                        >
                          <FaHeart
                            style={{
                              color: "#9A291B",
                              height: "24px",
                              width: "24px",
                              marginRight: "2px",
                            }}
                          />
                          <button
                            className=""
                            style={{
                              border: "none",
                              background: "none",
                              color: "black",
                            }}
                            // onClick={() => setOpenPopUp(true)}
                          >
                            <Typography
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Furniture Wishlist
                            </Typography>
                          </button>
                        </MenuItem>
                      </>
                    )}

                    {(user?.role === "broker" ||
                      user?.role === "developer" ||
                      user?.role == "owner") && (
                      <>
                        <Divider className="mx-3 my-0" />
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/shortlist");
                          }}
                        >
                          <FaClipboardList
                            style={{
                              color: "#9A291B",
                              height: "24px",
                              width: "24px",
                              marginRight: "2px",
                            }}
                          />
                          <button
                            className=""
                            style={{
                              border: "none",
                              background: "none",
                              color: "black",
                            }}
                            // onClick={() => setOpenPopUp(true)}
                          >
                            <Typography
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Property Shortlist
                            </Typography>
                          </button>
                        </MenuItem>
                      </>
                    )}

                    {user?.role === "guest" && (
                      <>
                        <Divider className="mx-3 my-0" />
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();

                            setCloseRatingsModal(true);
                          }}
                        >
                          <Box
                            className="text-center"
                            sx={{
                              backgroundColor: "#f5f5f5",
                              borderRadius: "30px",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <StarIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "#ADB0B6",
                              }}
                            />
                          </Box>
                          <Link to={"#"} className="header-fropdown-links mx-2">
                            <Typography
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Rating & Comments
                            </Typography>
                            {/* <img src={StarIconProfile} />Rating & Comments */}
                          </Link>
                        </MenuItem>
                        <Divider className="mx-3 my-0" />
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();

                            setClosePropertiesContactViewModal(true);
                          }}
                          //  onClick={handleClose}
                        >
                          {" "}
                          <Box
                            className="text-center"
                            sx={{
                              backgroundColor: "#f5f5f5",
                              borderRadius: "30px",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "#ADB0B6",
                              }}
                            />
                          </Box>
                          <Link to="#" className="header-fropdown-links mx-2">
                            {/* <img src={EyeIconProfile} />*/}
                            <Typography
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Properties Contacts Viewed
                            </Typography>
                          </Link>
                        </MenuItem>

                        <>
                          <Divider className="mx-3 my-0" />
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();

                              setCloseCartsModal(true);
                            }}
                            //  onClick={handleClose}
                          >
                            <Box
                              className="text-center"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "30px",
                                width: "30px",
                                height: "30px",
                              }}
                            >
                              <ShoppingCartIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: "#ADB0B6",
                                }}
                              />
                            </Box>
                            <Link to="#" className="header-fropdown-links mx-2">
                              {/* <img src={EyeIconProfile} />*/}
                              <Typography
                                sx={[
                                  styles.fontFamilyDS,
                                  styles.fs16,
                                  styles.color240501,
                                ]}
                              >
                                My Cart
                              </Typography>
                            </Link>
                          </MenuItem>
                        </>
                      </>
                    )}
                    {
                      <>
                        <Divider className="mx-3 my-0" />
                        <MenuItem
                          onClick={(e) => {
                            navigate("/furniture/order");
                          }}
                          //  onClick={handleClose}
                        >
                          <Box
                            className="text-center"
                            sx={{
                              backgroundColor: "#f5f5f5",
                              borderRadius: "30px",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <ShoppingCartIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "#9A291B",
                                fill: "#9A291B",
                              }}
                            />
                          </Box>

                          <Link to="#" className="header-fropdown-links mx-3">
                            {/* <img src={EyeIconProfile} />*/}
                            <Typography
                              sx={[
                                styles.fontFamilyDS,
                                styles.fs16,
                                styles.color240501,
                              ]}
                            >
                              Furniture order
                            </Typography>
                          </Link>
                        </MenuItem>
                      </>
                    }
                    <Divider className="mx-3 my-0" />
                    <MenuItem
                      onClick={() => {
                        localStorage.removeItem("token");
                        window.location.href = "/";
                      }}
                      //  onClick={handleClose}
                    >
                      {/* <Box
                        className="text-center"
                        sx={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "30px",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <LogoutIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "#ADB0B6",
                          }}
                        />
                      </Box> */}
                      <FaBackward
                        style={{
                          color: "#9A291B",
                          height: "24px",
                          width: "24px",
                          marginRight: "2px",
                        }}
                      />
                      <Link className="header-fropdown-links mx-3">
                        {/* <img src={LogoutIconProfile} /> */}
                        Logout{" "}
                      </Link>
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <div className="header-buttons-align mt-4">
                  <div className="header_button_group">
                    <div className="default_white_btn">
                      {/* <Link to="/login">Login</Link> */}
                      <Link
                        style={{
                          width: "20px",
                          maxWidth: "90px",
                          minWidth: "90px",
                          height: "45px",
                        }}
                        to="/login"
                        onClick={(e) => {
                          e.preventDefault();

                          setCloseLoginModal(true);
                        }}
                      >
                        Login
                      </Link>
                      {/* <a style={{ width: '20px', marginRight: '25px' }} href="/login" onClick={(e) => {
                          e.preventDefault()
  
                          setCloseLoginModal(true);
                        }}>    <span>Login</span>
                        </a> */}
                    </div>
                    <div className="default_maroon_btn m-0 d-block mt-2">
                      <a
                        style={{
                          width: "20px",
                          maxWidth: "90px",
                          minWidth: "90px",
                          height: "45px",
                        }}
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPropertyFree(false);
                          setCloseModal(true);
                        }}
                      >
                        <span>Sign up</span>
                      </a>
                      {/* <Link to="">
                        <span>Sign up</span>
                      </Link> */}
                      <Button
                        className="mt-2"
                        onClick={() => {
                          setIsPropertyFree(true);
                          setCloseModal(true);
                        }}
                        sx={[
                          styles.searcButtons,
                          {
                            height: "48px",
                            fontSize: "14px",
                            backgroundColor: "#ffc72c",
                            color: "#000",
                            padding: "0px 15px",
                            borderRadius: "12px",
                          },
                          {
                            "&:hover": {
                              backgroundColor: "#ffc72c",
                            },
                            "&:focus": {
                              border: "1px solid #ffc72c",
                            },
                          },
                        ]}
                      >
                        List My Property
                        <sup
                          style={{
                            fontWeight: "800",
                            fontSize: "15px",
                          }}
                        >
                          Free
                        </sup>
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {user?.role == "guest" && (
                <Button
                  className="mt-2"
                  onClick={() => {
                    setFirstPropty(true);

                    // setIsPropertyFree(true);
                    // setCloseModal(true);
                  }}
                  sx={[
                    styles.searcButtons,
                    {
                      height: "48px",
                      fontSize: "14px",
                      backgroundColor: "#ffc72c",
                      color: "#000",
                      padding: "0px 15px",
                      borderRadius: "12px",
                    },
                    {
                      "&:hover": {
                        backgroundColor: "#ffc72c",
                      },
                      "&:focus": {
                        border: "1px solid #ffc72c",
                      },
                    },
                  ]}
                >
                  List My Property
                  <sup
                    style={{
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Free
                  </sup>
                </Button>
              )}
            </div>
          </div>
        }
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        style={{
          sidebar: {
            background: "white",
            width: "80%",
            height: "100px",
          },
        }}
      >
        <div className="d-flex z-[9999999] justify-content-between align-items-end mt-4 px-1 md:px-4">
          <Link className="navbar-brand" to="/">
            <div
              class="brand-logo"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={Logo}
                style={{
                  height: "30px",
                  width: "30px",
                  marginLeft: "15px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "0px",
                  lineHeight: "0px",
                }}
              >
                <h3
                  className="ms-2"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  Ghar
                  <span style={{ color: "#9A291B" }}>Wala</span>
                  <sup style={{ fontSize: "15px" }}>TM</sup>
                </h3>
                <span
                  style={{
                    fontSize: "8px",
                    marginLeft: "10px",
                    marginTop: "-3px",
                  }}
                >
                  (GharWala Home Solutions LLP){" "}
                </span>
              </div>
            </div>
          </Link>
          <button
            onClick={() => onSetSidebarOpen(true)}
            style={{
              border: "1px solid #ccc",
              padding: "5px 10px",
              borderRadius: "5px",
              backgroundColor:"#b90000",
            }}
          >
            <FormatAlignLeftIcon />
          </button>
        </div>
      </Sidebar>

      {/* <BottomSheet
				snapPoints={({ minHeight }) => minHeight}
				open={firstPropty}
				// stateFunc={() => setFirstPropty(false)}
				title="Choose profession"
			> */}
      <ModalSignUp
        state={firstPropty}
        stateFunc={() => setFirstPropty(false)}
        title="Choose profession"
      >
        <Profession
          user={user}
          isPropertyFree={firstPropty}
          setCloseModal={() => setFirstPropty(false)}
        />
      </ModalSignUp>
      {/* </BottomSheet> */}

      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeModal}
          onDismiss={() => setCloseModal(false)}
        >
          <SignUp
            setSignupModal={setCloseModal}
            isPropertyFree={isPropertyFree}
            setCloseModal={setCloseLoginModal}
          />
        </BottomSheet>
      ) : (
        <ModalSignUp
          state={closeModal}
          stateFunc={setCloseModal}
          title="Please fill all the Details"
        >
          <SignUp
            isPropertyFree={isPropertyFree}
            setCloseModal={setCloseModal}
          />
        </ModalSignUp>
      )}
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeLoginModal}
          onDismiss={() => setCloseLoginModal(false)}
        >
          <Login
            setSignupModal={setCloseModal}
            setCloseModal={setCloseLoginModal}
          />
        </BottomSheet>
      ) : (
        <ModalLogin
          state={closeLoginModal}
          stateFunc={setCloseLoginModal}
          title="Login"
        >
          <Login setCloseModal={setCloseLoginModal} />
        </ModalLogin>
      )}
      <GHModal
        state={closeProfileModal}
        stateFunc={setCloseProfileModal}
        title="My Profile"
      >
        <Profile setCloseModal={setCloseProfileModal} />
      </GHModal>
      <RatingModal
        state={closeRatingsModal}
        stateFunc={setCloseRatingsModal}
        title="Ratings"
      >
        <Ratings setCloseModal={setCloseRatingsModal} />
      </RatingModal>
      <RatingModal
        state={closePropertiesContactViewModal}
        stateFunc={setClosePropertiesContactViewModal}
        title="Properties Contacts Viewed"
      >
        <PropertyContactViewed
          setCloseModal={setClosePropertiesContactViewModal}
        />
      </RatingModal>
      <RatingModal
        state={closeCartsModal}
        stateFunc={setCloseCartsModal}
        title={`My Cart (${totalItemsInCart})`}
      >
        <CartModal
          setTotalItemsInCart={setTotalItemsInCart}
          setCloseModal={setCloseCartsModal}
        />
      </RatingModal>
    </div>
  );
}

export default HeaderMobile;
