import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import publicReq from "../../config/axiosConfig";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import { Helmet } from "react-helmet";

const ContactUs = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    isFeedback: false,
  });

  const [showPage, setShowPage] = useState(true);
  const { user } = useSelector((state) => state.auth); // Redux state for user data
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Validation Functions
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = () =>
    formData.name !== "" &&
    formData.email !== "" &&
    formData.message !== "" &&
    isValidEmail(formData.email);

  // Handle Input Change
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle Form Submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      publicReq
        .post("/contact", {
          fullName: formData.name,
          email: formData.email,
          desc: formData.message,
          phone: formData.phoneNumber,
          is_feedback: formData.isFeedback,
        })
        .then((res) => {
          if (res.status === 201) {
            toast.success("Details submitted successfully.");
            alert("Details submitted successfully.");
            setFormData({
              name: "",
              email: "",
              phoneNumber: "",
              message: "",
              isFeedback: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something went wrong. Please try again later.");
        });
    } else {
      toast.error("Please fill in all required fields with valid data.");
    }
  };

  const leftSideStyles = {
    container: {
      backgroundColor: "#f9f9f9",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    heading: {
      color: "#B90000;",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    paragraph: {
      color: "#555",
      lineHeight: "1.8",
      marginBottom: "15px",
    },
    subHeading: {
      color: "#B90000;",
      fontSize: "18px",
      fontWeight: "semibold",
      marginTop: "20px",
    },
    link: {
      color: "#007bff",
      textDecoration: "none",
    },
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | GharWala One step solution for your housing and furniture rental search gharwalah.com </title>
        <meta
          name="description"
          content="For any inquiries or assistance related to returns and refunds, please contact our customer support team. We strive to ensure a seamless and customer-centric experience with our furniture and home appliances rental services."
        />
      </Helmet>

      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div className="container" style={{ paddingTop: "150px", marginBottom:"40px" }}>
        <Grid container spacing={4}>
          {/* Left Side Content */}
          <Grid item xs={12} md={6}>
            <div style={leftSideStyles.container}>
              <Typography className="h3-about-tems" variant="h5">
                Contact Us
              </Typography>
              <Typography style={leftSideStyles.paragraph}>
                For any inquiries or assistance related to returns and refunds,
                please contact our customer support team. We strive to ensure a
                seamless and customer-centric experience with our furniture and
                home appliances rental services.
              </Typography>
              <Typography className="h4-about-tems" variant="h6">
                Need Help?
              </Typography>
              <Typography style={leftSideStyles.paragraph}>
                If you have any questions, concerns, or require assistance with
                our services, we’re here to help.
              </Typography>
              <Typography className="h4-about-tems" variant="h6">
                Address:
              </Typography>
              <Typography style={leftSideStyles.paragraph}>
                GharWala Home Solutions LLP <br />
                2 New Tollygunge Purba Putiary <br />
                Kolkata- 700093
              </Typography>
              <Typography className="h4-about-tems" variant="h6">
                Email:
              </Typography>
              <Typography style={leftSideStyles.paragraph}>
                <a
                  href="mailto:support@gharwalah.com"
                  style={leftSideStyles.link}
                >
                  support@gharwalah.com
                </a>
              </Typography>
              <Typography className="h4-about-tems" variant="h6">
                Phone No.:
              </Typography>
              <Typography style={leftSideStyles.paragraph}>
                +91 8240444587
              </Typography>
            </div>
          </Grid>

          {/* Right Side Form */}
          <Grid item xs={12} md={6}>
            <Container
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              maxWidth="sm"
            >
              <Typography variant="h4" gutterBottom>
                Contact Us
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Message"
                      name="message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isFeedback"
                          checked={formData.isFeedback}
                          onChange={handleChange}
                        />
                      }
                      label="Is this feedback?"
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isFormValid()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Grid>
        </Grid>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default ContactUs;
