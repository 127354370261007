import { Box, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../styles/styles";
import classes from "../../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";

const AddPropertyStep4 = ({ setActiveStep, activeStep, data, setData }) => {
  console.log(data, "dat>a");
  const [dragActive, setDragActive] = React.useState(false);
  const [imagePaths, setImagePaths] = useState(data.propertyImages);
  const [coverImagePath, setCoverImagePath] = useState(data.coverImage); // State for cover image
  const inputRef = React.useRef(null);
  const coverInputRef = React.useRef(null); // Ref for cover image input
  const theme = useTheme();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  function handleDelete(file, index) {
    let paths = [...imagePaths];
    let images = [...data.photos];
    paths = paths.filter((path) => path !== file);
    images = images.filter((image, imgIndex) => imgIndex !== index);
    setImagePaths(paths);
    setData({ ...data, photos: images });
  }

  function handleFile(files) {
    if (imagePaths?.length === 10) {
      alert("Only 10 files are allowed max.");
      return;
    }
    const arr = [...data.photos];
    let path = [...imagePaths];
    Array.from(files).forEach((file) => {
      arr.push(file);
      path.push(URL.createObjectURL(file));
    });
    setData({ ...data, photos: arr });
    setImagePaths(path);
    inputRef.current.value = "";
  }

  function handleCoverFile(files) {
    const file = files[0];
    if (file) {
      const coverImage = URL.createObjectURL(file);
      setData({ ...data, coverImage: file });
      setCoverImagePath(coverImage);
      coverInputRef.current.value = "";
    }
  }

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const handleCoverChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleCoverFile(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onCoverButtonClick = () => {
    coverInputRef.current.click();
  };

  return (
    <Box
      sx={[
        {
          width: "100%",
          marginLeft: "12px",
          paddingLeft: "20px",
          paddigRight: "8px",
        },
        {
          [theme.breakpoints.between("xs", "md")]: [
            { marginLeft: 0, paddingLeft: 0, paddigRight: 0 },
          ],
        },
      ]}
    >
      <Typography
        sx={[
          styles.fs30,
          styles.fontFamilySGB,
          styles.fbBold,
          styles.color240501,
        ]}
      >
        Photos
      </Typography>

      {/* Cover Image Upload */}
      <Box sx={[styles.mt40]}>
        <Typography
          sx={[
            styles.fs20,
            styles.fontFamilySGB,
            styles.fbBold,
            styles.color240501,
          ]}
        >
          Cover Image
        </Typography>
        <Box className="w-100">
          <input
            ref={coverInputRef}
            type="file"
            accept="image/*,*.png,*.jpeg,*.jpg"
            id="input-cover-upload"
            onChange={handleCoverChange}
            style={{ display: "none" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "150px",
              width: "100%",
              border: "2px dashed #ccc",
              borderRadius: "8px",
              cursor: "pointer",
              backgroundImage: coverImagePath ? `url(${coverImagePath})` : "none",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            onClick={onCoverButtonClick}
          >
            {!coverImagePath && (
              <Typography
                sx={[
                  styles.fontFamilySGR,
                  { color: "#979797", textAlign: "center" },
                ]}
              >
                Click to upload cover image
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      {/* Photo Upload */}
      <Box sx={[styles.mt40]}>
        <Box className="w-100">
          <Box
            sx={[
              { height: "initial" },
              {
                [theme.breakpoints.between("xs", "sm")]: [
                  {
                    maxWidth: "300px",
                    minWidth: "300px",
                  },
                ],
              },
            ]}
          >
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              enctype="multipart/form-data"
            >
              <input
                ref={inputRef}
                type="file"
                accept="image/*,*.png,*.jpeg,*.jpg"
                id="input-file-upload"
                multiple={true}
                onChange={handleChange}
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={dragActive ? "drag-active" : ""}
              >
                <Box
                  gap={1}
                  className="d-flex align-items-center justify-content-center flex-column"
                >
                  <CloudUploadIcon fontSize="large" sx={[styles.color9A291B]} />
                  <Typography sx={[styles.fontFamilySGR]} className="px-3">
                    Click and Drag to upload photos
                  </Typography>
                  <Typography
                    className="text-color979797"
                    sx={[styles.fs14, styles.fontFamilyDS]}
                  >
                    (Max 10 photos)
                  </Typography>
                </Box>
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </Box>
          <Box className="d-flex flex-wrap my-4">
            {imagePaths.map((photo, index) => (
              <div
                key={index}
                className="mx-3 my-2 d-flex justify-content-end align-items-start m-2"
                style={{
                  backgroundImage: `url(${photo})`,
                  backgroundSize: "100%",
                  height: "62px",
                  width: "80px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: "8px",
                }}
              >
                <IconButton
                  className="py-0 px-0 p-4"
                  onClick={() => handleDelete(photo, index)}
                >
                  <CloseIcon
                    fontSize="small"
                    sx={{
                      color: "#000",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "20px",
                    }}
                  />
                </IconButton>
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPropertyStep4;
