import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, MenuItem, TextField, InputAdornment } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "@mui/material/Select";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import classes from "../../styles/styles.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import publicReq, { API_KEY } from "../../config/axiosConfig";
import { getProperties } from "../../redux/action/property";
import { usePlacesWidget } from "react-google-autocomplete";
import { useMediaQuery } from "react-responsive";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import { privateReq } from "../../config/axiosConfig";
import { Watermark } from "@hirohe/react-watermark";
import { Pagination, Stack } from "@mui/material";
import "./index.css";
import PaginationItem from "@mui/material/PaginationItem";
import PropertiesFilter from "../../components/PropertiesFilter/index.js";
import PropertiesCard from "../../components/PropertiesCard/index.js";
import AdminPropertiesCard from "../../components/AdminPropertiesCard/index.js";
import { SearchIcon } from "lucide-react";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

function capitalizeFLetter(word) {
  if (word) {
    return word[0].toUpperCase() + word.slice(1);
  }
}

export const getLocLatLng = (district) => {
  return geocodeByAddress(district?.label)
    .then((results) => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      console.log("Successfully got latitude and longitude", {
        lat,
        lng,
      });
      return { lat, lng };
    })
    .catch((err) => {
      console.log(err);
    });
};

const AdminProperties = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const roleFromUrl = urlParams.get("role");
  console.log("roleFo", roleFromUrl);
  const [propertyFlat, setPropertyFlat] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [budget, setBudget] = useState([1, 1000000000]);
  const [usp, setUsp] = useState([]);
  const [wantTo, setWantTo] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [accessoriesFilter, setAccessoryFilter] = useState([]);
  const [locality, setLocality] = useState({});
  const [userType, setUserType] = useState(roleFromUrl || "");
  const [isLoading, setIsLoading] = useState(false);
  const { properties } = useSelector((state) => state.property);
  const { user, location: userLocation } = useSelector((state) => state.auth);
  const [sortby, setSortBy] = useState("created");
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showFilter, setShowFilter] = useState(false);
  const [go, setGo] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [propertiesPerPage, setPropertiesPerPage] = useState(
    user?.role === "admin" ? 50 : 12
  );

  useEffect(() => {
    if (wantTo === "rent") {
      setBudget([1, 1000000]);
    }
  }, [wantTo]);

  function removeLocations(inputString) {
    if (inputString) {
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();
      return modifiedString;
    }
    return inputString;
  }

  function checkTabS2() {
    publicReq
      .post("/get-access-for-basic-features", {
        propertyName: "PROPERTY",
      })
      .then((result) => {
        if (result.data.message.enabled === false) {
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error("something went wrong");
        console.log(error);
      });
  }

  useEffect(() => {
    checkTabS2();
  }, []);

  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      componentRestrictions: { country: "in" },
      types: ["(regions)"],
    },
    onPlaceSelected: (place) => {
      setLocality({
        lng: place.geometry.location.lng(),
        lat: place.geometry.location.lat(),
      });
    },
  });

  function deleteProperty(id) {
    privateReq
      .delete(`/delete-property/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Property deleted");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getPropertiesInitial();
      });
  }

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  function numDifferentiation(val) {
    if (val >= 10000000) val = val / 10000000 + " Cr";
    else if (val >= 100000) val = val / 100000 + " Lac";
    else if (val >= 1000) val = val / 1000 + ",000";
    return val;
  }

  const onCheck = (state, val, setState) => {
    const arr = [...state];
    if (arr.includes(val)) {
      let idx = arr.findIndex((i) => i === val);
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setState(arr);
  };

  const onResetFilter = () => {
    setPropertyType([]);
    setWantTo([]);
    setUserType([]);
    setPropertyType([]);
    setBudget([1, 1000000000]);
    setAccessoryFilter([]);
    setUsp([]);
    setShowFilter(false);
    setSearchQuery("");
  };

  useEffect(() => {
    if (location.search) {
      let params = new URLSearchParams(location.search);
      if (params.get("wantto")) {
        setWantTo([params.get("wantto")]);
      }
      if (params.get("propertyType")) {
        setPropertyType([params.get("propertyType")]);
      }
      if (params.get("max")) {
        setBudget([1, parseInt(params.get("max"))]);
      }
      if (params.get("latitude") && params.get("longitude")) {
        setLocality({
          lng: params.get("longitude"),
          lat: params.get("latitude"),
        });
      }
      setGo((prev) => prev + 1);
    } else if (userLocation.lat !== undefined && userLocation.lng !== undefined)
      getPropertiesInitial();
    else getPropertiesInitial();
  }, [sortby]);

  useEffect(() => {
    if (go >= 1) onApplyFilter();
  }, [go]);

  const onApplyFilter = async () => {
    try {
      const data = {
        priceRange: budget,
        usp,
        propertyType,
        createdBy: userType,
        wantTo,
        accessories: accessoriesFilter,
        bedrooms: propertyFlat,
      };
      let loc =
        locality.lng && locality.lat ? locality : await getLocLatLng(locality);

      // Create URL query params object
      let queryParams = {};

      // Add price range
      if (budget?.[0] !== 1) queryParams.min = budget[0];
      if (budget?.[1] !== 1000000000) queryParams.max = budget[1];

      // Add location if available
      if (loc?.lat) queryParams.latitude = loc.lat;
      if (loc?.lng) queryParams.longitude = loc.lng;

      // Add array filters
      if (propertyType.length) queryParams.propertyType = propertyType[0];
      if (wantTo.length) queryParams.wantto = wantTo[0];
      if (userType) queryParams.role = userType;

      // Add sort
      if (sortby) queryParams.sortby = sortby;

      // Update URL without reloading page
      const queryString = new URLSearchParams(queryParams).toString();
      navigate(`/admin/properties?${queryString}`, { replace: true });

      // Make API call with full filter data
      let apiQuery = `?`;
      Object.keys(data).map((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            apiQuery += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          apiQuery += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });
      if (sortby) {
        apiQuery += `&sortby=${sortby}`;
      }

      setIsLoading(true);
      dispatch(getProperties(apiQuery));
      setIsLoading(false);
      setShowFilter(false);
    } catch (e) {
      console.log("error ", e);
    }
  };

  const getPropertiesInitial = () => {
    try {
      const data = {
        priceRange: budget,
        usp,
        propertyType,
        wantTo,
        accessories: accessoriesFilter,
      };
      let query = `?`;
      Object.keys(data).map((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });
      if (sortby) {
        query += `&sortby=${sortby}`;
      }
      dispatch(getProperties(query));
    } catch (e) {
      console.log(e);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const propertyList = properties.length ? properties : [];

  // Filter properties based on search query
  const filteredProperties = propertyList.filter((property) => {
    if (!searchQuery) return true;
    const searchLower = searchQuery.toLowerCase();
    return (
      property.title?.toLowerCase().includes(searchLower) ||
      property.description?.toLowerCase().includes(searchLower) ||
      property.location?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    scrollToTop();
  };

  const handlePropertiesPerPageChange = (event) => {
    setPropertiesPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const totalPageCount = Math.ceil(
    filteredProperties.length / propertiesPerPage
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Properties | GharWala One step solution for your housing and furniture
          rental search gharwalah.com{" "}
        </title>

        <meta
          name="description"
          content="Explore best properties for rent or sale near you with gharwalah.com"
        />
      </Helmet>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div style={{ marginTop: "40px" }} className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          <div className="row mx-0 w-100">
            {user?.role !== "admin" && (
              <PropertiesFilter
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                isMobile={isMobile}
                headerHeight={headerHeight}
                onResetFilter={onResetFilter}
                onApplyFilter={onApplyFilter}
                onCheck={onCheck}
                wantTo={wantTo}
                setWantTo={setWantTo}
                userType={userType}
                setUserType={setUserType}
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                budget={budget}
                setBudget={setBudget}
                usp={usp}
                setUsp={setUsp}
                accessories={accessoriesFilter}
                setAccessoryFilter={setAccessoryFilter}
                accessoriesFilter={accessoriesFilter}
                locality={locality}
                setLocality={setLocality}
                sortby={sortby}
                numDifferentiation={numDifferentiation}
                propertyFlat={propertyFlat}
                setPropertyFlat={setPropertyFlat}
              />
            )}

            <div
              className={` ${
                user?.role === "admin"
                  ? "col-lg-12 col-md-12 col-xs-12"
                  : "col-lg-9 col-md-9 col-xs-12"
              } `}
            >
              <div className="row properties-list mx-0">
                <div className="row properties-header mt-5 px-3">
                  <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        underline="hover"
                        color="inherit"
                        to="/admin"
                      >
                        Dashboard
                      </Link>

                      <Link
                        underline="hover"
                        color="inherit"
                        to="/property-list"
                      >
                        Property List 
                      </Link>

                      <Typography sx={{ color: "text.primary" }}>
                        {`${!roleFromUrl ? "All properties" : ""}`}
                        {`${roleFromUrl === "owner" ? "Owner properties" : ""}`}
                        {`${roleFromUrl === "broker" ? "Broker properties" : ""}`}
                        {`${roleFromUrl === "developer"? "Developer properties": ""}`}
                      </Typography>
                    </Breadcrumbs>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 heading-properties">
                    <span>
                      {`${!roleFromUrl ? "All properties" : ""}`}
                      {`${roleFromUrl === "owner" ? "Owner properties" : ""}`}
                      {`${roleFromUrl === "broker" ? "Broker properties" : ""}`}
                      {`${
                        roleFromUrl === "developer"
                          ? "Developer properties"
                          : ""
                      }`}
                    </span>
                  </div>
                  {user?.role === "admin" && (
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center gap-4">
                      <input
                        className="search-properties"
                        placeholder="Search properties..."
                        margin="normal"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                          width: "300px",
                          borderWidth: "0px",
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff",
                            borderWidth: "0px",
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white",
                              borderWidth: "0px",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white",
                              borderWidth: "0px",
                            },

                            "& .MuiInputBase-input": {
                              borderWidth: "0px",
                            },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon sx={{ color: "#757575" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "#fff",
                          padding: "4px 12px",
                          borderRadius: "8px",
                          border: "1px solid #e0e0e0",
                          display: "flex",
                          alignItems: "center",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            borderColor: "#2196f3",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            marginRight: "12px",
                            color: "#757575",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Show:
                        </Typography>
                        <select
                          className="select"
                          style={{
                            padding: "0px",
                          }}
                          value={propertiesPerPage}
                          onChange={handlePropertiesPerPageChange}
                          size="small"
                          placeholder="12"
                          sx={{
                            minWidth: 120,
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <option value={10}>10 per page</option>
                          <option value={20}>20 per page</option>
                          <option value={50}>50 per page</option>
                          <option value={100}>100 per page</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {user?.role !== "admin" && (
                    <div className="col-lg-4 col-md-6 col-sm-12 properties-filter-select">
                      <div className="select-input">
                        <Typography
                          id="label"
                          sx={[classes.textSortBy, classes.fontFamilyDS]}
                        >
                          Sort by:
                        </Typography>
                        <Select
                          labelId="label"
                          id="select"
                          value={sortby}
                          onChange={(e) => setSortBy(e.target.value)}
                          IconComponent={KeyboardArrowDownIcon}
                          style={{ width: "300px" }}
                          sx={[
                            classes.fontFamilyDS,
                            classes.fbBold,
                            {
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            },
                          ]}
                        >
                          <MenuItem value="created">Newest First</MenuItem>
                          <MenuItem value="low-to-high">
                            Price Low To High
                          </MenuItem>
                          <MenuItem value="high-to-low">
                            Price High To Low
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                  )}
                </div>

                {user?.role === "admin" ? (
                  <AdminPropertiesCard
                    currentProperties={currentProperties}
                    isMobile={isMobile}
                    navigate={navigate}
                    deleteProperty={deleteProperty}
                    user={user}
                    removeLocations={removeLocations}
                    capitalizeFLetter={capitalizeFLetter}
                    Watermark={Watermark}
                  />
                ) : (
                  <div>
                    {isLoading && "Loading..."}
                    <PropertiesCard
                      currentProperties={currentProperties}
                      isMobile={isMobile}
                      navigate={navigate}
                      deleteProperty={deleteProperty}
                      user={user}
                      removeLocations={removeLocations}
                      capitalizeFLetter={capitalizeFLetter}
                      Watermark={Watermark}
                    />
                  </div>
                )}
                <Stack
                  spacing={2}
                  alignItems="center"
                  marginTop={4}
                  marginBottom={6}
                >
                  {totalPageCount > 1 && (
                    <Pagination
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  )}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminProperties;
