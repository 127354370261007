import React from "react";
import search from "../../assets/images/search-image.png";
import testimonial from "../../assets/images/testimonial-image.png";
import notification from "../../assets/images/notification-bell.png";
import WriteBlog from "../../assets/images/write-blog.png";
import services from "../../assets/images/services.png";
import owner from "../../assets/images/owner.png";
import ReferEarn from "../../assets/images/Refer-earn.png";
import zipNew from "../../assets/images/zip-new.png";
import BrokerNew from "../../assets/images/broker-new.png";
import TabAccess from "../../assets/images/tab-access.png";
import contact from "../../assets/images/contact-data.png";
import TestimonialData from "../../assets/images/testimonal-data.png";
import phoneDiary from "../../assets/images/phoneDiary.png";
import carryingCost from "../../assets/images/carryingCost.png";
import role from "../../assets/images/setting.png";
import pic13 from "../../assets/images/pic13.png";
import pic14 from "../../assets/images/pic14.png";
import pic15 from "../../assets/images/pic15.png";
import property from "../../assets/images/property.png";
import console from "../../assets/images/google.png";
import calender from "../../assets/images/calender-icon.png";


import "./admin.css";
import { Link } from "react-router-dom";

export default function AdminControl() {
  return (
    <>
      <div
        style={{
          marginTop: "100px",
          padding: "20px",
        }}
      >
        <div className="admin-head">Admin Control</div>

        <div className="each-box">
          <div className="image-box">
            <Link to="/admin/enquires">
              <img className="zip img-admin" src={phoneDiary} alt="zip" />
            </Link>
            <div className="img-admin-name">Enquires</div>
          </div>

          <div className="image-box">
            <Link to="/property-list">
              <img className="zip img-admin" src={property} alt="zip" />
            </Link>
            <div className="img-admin-name">Properties</div>
          </div>

          <div className="image-box">
            <Link to="/admin/rental-assets-management">
              <img className="zip img-admin" src={pic14} alt="zip" />
            </Link>
            <div className="img-admin-name">Rental Assets</div>
          </div>

          <div className="image-box">
            <Link to="/admin/services-management">
              <img className="zip img-admin" src={services} alt="zip" />
            </Link>
            <div className="img-admin-name">Services</div>
          </div>

          <div className="image-box">
            <Link to="/admin/blog-management">
              <img className="tab img-admin" src={WriteBlog} alt="tab" />
            </Link>
            <div className="img-admin-name">Blog Options</div>
          </div>

          <div className="image-box">
            <Link to="/admin/contact-data">
              <img className="zip img-admin" src={contact} alt="zip" />
            </Link>
            <div className="img-admin-name">Contact Messages</div>
          </div>

          {/* <div className="image-box">
            <Link to="/role-management">
              <img className="zip img-admin" src={role} alt="zip" />
            </Link>
            <div className="img-admin-name">Settings</div>
          </div> */}

          <div className="image-box">
            <Link to="/admin/user-management">
              <img className="img-admin" src={owner} alt="owner" />
            </Link>
            <div className="img-admin-name">All users</div>
          </div>

          <div className="image-box">
            <Link to="/admin-settings">
              <img className="zip img-admin" src={role} alt="zip" />
            </Link>
            <div className="img-admin-name">Settings</div>
          </div>

          <div className="image-box">
            <Link to="/admin/google-console">
              <img className="zip img-admin" src={console} alt="zip" />
            </Link>
            <div className="img-admin-name">Page Indexing</div>
          </div>

          {/* <div className="image-box">
            <Link to="/department">
              <img className="zip img-admin" src={pic15} alt="zip" />
            </Link>
            <div className="img-admin-name">Department</div>
          </div> */}
          {/* <div className="image-box">
            <Link to="/site-settings">
              <img className="zip img-admin" src={pic13} alt="zip" />
            </Link>
            <div className="img-admin-name">Site Settings</div>
          </div> */}
          <div className="image-box">
            <Link to="/admin/pincodes">
              <img className="img-admin" src={zipNew} alt="zip" />
            </Link>
            <div className="img-admin-name">Pin Code</div>
          </div>

          {/* <div className="image-box">
            <Link to="/owners">
              <img className="img-admin" src={owner} alt="owner" />
            </Link>
            <div className="img-admin-name">Owner</div>
          </div> */}

          <div className="image-box">
            <Link to="/admin/notification">
              <img className="img-admin bell" src={notification} alt="bell" />
            </Link>
            <div className="img-admin-name">Payment Reminder</div>
          </div>

          <div className="image-box">
            <Link to="/admin/category-management">
              <img className="img-admin search" src={search} alt="search" />
            </Link>
            <div className="img-admin-name">Categories</div>
          </div>

          {/* <div className="image-box">
            <Link to="/brokers">
              <img className="broker img-admin" src={BrokerNew} alt="broker" />
            </Link>
            <div className="img-admin-name">Broker</div>
          </div> */}

          {/* <div className="image-box">
            <Link to="/tabs-access">
              <img className="tab img-admin" src={TabAccess} alt="tab" />
            </Link>
            <div className="img-admin-name">Tab Access</div>
          </div> */}

          {/* <div className="image-box">
            <Link to="/refer-scheme">
              <img className="tab img-admin" src={ReferEarn} alt="tab" />
            </Link>
            <div className="img-admin-name">Refer</div>
          </div> */}

          <div className="image-box">
            <Link to="/admin/testimonial">
              <img className="tab img-admin" src={testimonial} alt="tab" />
            </Link>
            <div className="img-admin-name">Testimonial</div>
          </div>

          <div className="image-box">
            <Link to="/admin/backlog-enteries">
              <img className="tab img-admin" src={calender} alt="tab" />
            </Link>
            <div className="img-admin-name">Backlog Enteries</div>
          </div>
          
          {/* <div className="image-box">
            <Link to="/carrying-cost">
              <img className="tab img-admin" src={carryingCost} alt="tab" />
            </Link>
            <div className="img-admin-name">Carrying cost</div>
          </div> */}

          {/* <div className="image-box">
            <Link to="/contact">
              <img className="zip img-admin" src={contact} alt="zip" />
            </Link>
            <div className="img-admin-name">Contact</div>
          </div> */}

          {/* <div className="image-box">
            <Link to="/recent-properties">
              <img className="zip img-admin" src={TestimonialData} alt="zip" />
            </Link>
            <div className="img-admin-name">Recent Properties</div>
          </div> */}
        </div>
      </div>
    </>
  );
}
