import React from "react";
import search from "../../assets/images/search-image.png";
import testimonial from "../../assets/images/testimonial-image.png";
import notification from "../../assets/images/notification-bell.png";
import WriteBlog from "../../assets/images/write-blog.png";
import services from "../../assets/images/services.png";
import owner from "../../assets/images/owner.png";
import ReferEarn from "../../assets/images/Refer-earn.png";
import zipNew from "../../assets/images/zip-new.png";
import BrokerNew from "../../assets/images/broker-new.png";
import TabAccess from "../../assets/images/tab-access.png";
import contact from "../../assets/images/contact-data.png";
import TestimonialData from "../../assets/images/testimonal-data.png";
import phoneDiary from "../../assets/images/phoneDiary.png";
import carryingCost from "../../assets/images/carryingCost.png";
import role from "../../assets/images/setting.png";
import pic13 from "../../assets/images/pic13.png";
import pic14 from "../../assets/images/pic14.png";
import pic15 from "../../assets/images/pic15.png";
import rolemanagement from "../../assets/images/role-manage.png";

import "../AdminControl/admin.css";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

export default function Setting() {
  return (
    <>
      <div
        style={{
          marginTop: "100px",
          padding: "20px",
        }}
      >
        <div className="admin-head">Settings</div>

        <div style={{marginLeft:"15px", marginTop:"1px", marginBottom:"10px"}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/admin">
              Dashboard
            </Link>

            <Typography sx={{ color: "text.primary" }}>
              Settings
            </Typography>
          </Breadcrumbs>
          </div>

        <div className="each-box">
          {/* <div className="image-box">
            <Link to="/role-management">
              <img className="zip img-admin" src={role} alt="zip" />
            </Link>
            <div className="img-admin-name">Settings</div>
          </div> */}

          <div className="image-box">
            <Link to="/admin-settings/department">
              <img className="zip img-admin" src={pic15} alt="zip" />
            </Link>
            <div className="img-admin-name">Department</div>
          </div>

          {/* <div className="image-box">
            <Link to="/role-management">
              <img className="zip img-admin" src={rolemanagement} alt="role-management" />
            </Link>
            <div className="img-admin-name">Role Management</div>
          </div> */}

          <div className="image-box">
            <Link to="/admin-settings/role-management">
              <img
                className="zip img-admin"
                src={rolemanagement}
                alt="role-management"
              />
            </Link>
            <div className="img-admin-name">Role Management</div>
          </div>

          <div className="image-box">
            <Link to="/admin-settings/tabs-access">
              <img className="tab img-admin" src={TabAccess} alt="tab" />
            </Link>
            <div className="img-admin-name">Tab Access</div>
          </div>

          <div className="image-box">
            <Link to="/admin-settings/refer-scheme">
              <img className="tab img-admin" src={ReferEarn} alt="tab" />
            </Link>
            <div className="img-admin-name">Refer Income</div>
          </div>

          <div className="image-box">
            <Link to="/admin-settings/carrying-cost">
              <img className="tab img-admin" src={carryingCost} alt="tab" />
            </Link>
            <div className="img-admin-name">Carrying cost</div>
          </div>

          <div className="image-box">
            <Link to="/admin-settings/site-settings">
              <img className="zip img-admin" src={pic13} alt="zip" />
            </Link>
            <div className="img-admin-name">Site Settings</div>
          </div>
        </div>
      </div>
    </>
  );
}
