import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedProperties } from "../../redux/action/featured";
import { useMediaQuery } from "react-responsive";
import { Watermark } from "@hirohe/react-watermark";
import { Pagination, Stack } from "@mui/material";
import "./index.css";
import PropertiesCard from "../../components/PropertiesCard/index.js";
import { Helmet } from "react-helmet";
import PropertiesCardList from "../../components/PropertyCardList/index.js";

function capitalizeFLetter(word) {
  if (word) {
    return word[0].toUpperCase() + word.slice(1);
  }
}

const FeatureProperties = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const dispatch = useDispatch();
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const { featuredProperties } = useSelector((state) => state.featured);
  const { user, location: userLocation } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [searchQuery, setSearchQuery] = useState("");
  const [propertiesPerPage, setPropertiesPerPage] = useState(
    user?.role === "admin" ? 50 : 12
  );

  useEffect(() => {
    dispatch(getFeaturedProperties(""));
  }, []);

  function removeLocations(inputString) {
    if (inputString) {
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();
      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();
      return modifiedString;
    }
    return inputString;
  }

  function deleteProperty(id) {}

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  const [currentPage, setCurrentPage] = useState(1);
  const propertyList = featuredProperties.length ? featuredProperties : [];

  // Filter properties based on search query
  const filteredProperties = propertyList.filter((property) => {
    if (!searchQuery) return true;
    const searchLower = searchQuery.toLowerCase();
    return (
      property.title?.toLowerCase().includes(searchLower) ||
      property.description?.toLowerCase().includes(searchLower) ||
      property.location?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    scrollToTop();
  };

  const totalPageCount = Math.ceil(
    filteredProperties.length / propertiesPerPage
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Properties | GharWala One step solution for your housing and furniture rental search gharwalah.com </title>
      </Helmet>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div style={{ marginTop: "40px" }} className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          <div className="row mx-0 w-100">
            <div className={` ${"col-lg-12 col-md-12 col-xs-12"} `}>
              <div className="row properties-list mx-0">
                <div className="row properties-header mt-5 px-3">
                  <div className="col-lg-6 col-md-6 col-sm-12 heading-properties">
                    <span>Featured Properties</span>
                  </div>
                </div>
                <div>
                  <PropertiesCardList
                    currentProperties={currentProperties}
                    isMobile={isMobile}
                    navigate={navigate}
                    deleteProperty={deleteProperty}
                    user={user}
                    removeLocations={removeLocations}
                    capitalizeFLetter={capitalizeFLetter}
                    Watermark={Watermark}
                  />
                </div>

                <Stack
                  spacing={2}
                  alignItems="center"
                  marginTop={4}
                  marginBottom={6}
                >
                  {totalPageCount > 1 && (
                    <Pagination
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  )}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FeatureProperties;
