import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LinkedinShareButton,
} from "react-share";
import styled from "styled-components";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";

Modal.setAppElement("#root");

const customStyles = {
  Modal: {
    borderRadius: "25px",
    overlay: "hidden",
  },
  customModal: {
    border: "none",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
    alignItems: "center",
    display: "flex",
    opacity: 0,
    transition: "opacity 300ms ease-in-out",
  },
  content: {
    maxWidth: "30%",
    minWidth: "30%",
    height: "60%",
    minHeight: "470px",
    margin: "0 auto",
    borderRadius: "20px",
    transform: "translateY(20px)",
    opacity: 0,
    transition: "all 300ms ease-in-out",
  },
};
const customStylesMobile = {
  ReactModal__Content: {
    border: "none",
  },
  customModal: {
    border: "none",
    marginTop: "100px",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
    opacity: 0,
    transition: "opacity 300ms ease-in-out",
  },
  content: {
    maxWidth: "100%",
    border: "none",
    margin: "0 auto",
    marginTop: "75px",
    transform: "translateY(20px)",
    opacity: 0,
    transition: "all 300ms ease-in-out",
  },
};

const ShareButton = styled.div`
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
  color: white;
  font-weight: 500;

  &:hover {
    transform: translateY(-2px);
  }
`;

const FacebookBtn = styled(ShareButton)`
  background: #1877f2;
`;

const TwitterBtn = styled(ShareButton)`
  background: #1da1f2;
`;

const RedditBtn = styled(ShareButton)`
  background: #ff4500;
`;

const LinkedInBtn = styled(ShareButton)`
  background: #0077b5;
`;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f5f5f5;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
  width: 100%;
`;

const UrlInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  font-size: 14px;
  color: #666;
  outline: none;
`;

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background: #007bff;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #0056b3;
  }
`;

const ShareTitle = styled.h2`
  margin-bottom: 24px;
  color: #333;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(135deg, #2563eb, #4f46e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.5px;
`;

export default function ShareModal({ state, stateFunc }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const shareUrl = window.location.href;
  const title = "Check this out!";

  const afterOpenModal = () => {
    document.querySelector(".ReactModal__Overlay").style.opacity = "1";
    document.querySelector(".ReactModal__Content").style.opacity = "1";
    document.querySelector(".ReactModal__Content").style.transform =
      "translateY(0)";
  };

  const beforeClose = () => {
    stateFunc(false);
    document.querySelector(".ReactModal__Overlay").style.opacity = "0";
    document.querySelector(".ReactModal__Content").style.opacity = "0";
    document.querySelector(".ReactModal__Content").style.transform =
      "translateY(20px)";
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    toast.success("Copied to clipboard");
  };

  return (
    <Modal
      isOpen={state}
      style={!isMobile ? customStyles : customStylesMobile}
      contentClassName="custom-modal"
      onAfterOpen={afterOpenModal}
      onRequestClose={beforeClose}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <ShareTitle>Share This Page</ShareTitle>

        <UrlContainer>
          <UrlInput value={shareUrl} readOnly />
          <CopyButton onClick={copyToClipboard}>
            <ContentCopyIcon fontSize="small" />
            Copy
          </CopyButton>
        </UrlContainer>

        <div
          style={{
            display: "flex",
            gap: "1.5rem",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookBtn>
              <FacebookIcon />
              Share On Facebook
            </FacebookBtn>
          </FacebookShareButton>

          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterBtn>
              <TwitterIcon />
              Share On Twitter
            </TwitterBtn>
          </TwitterShareButton>

          <RedditShareButton url={shareUrl} title={title}>
            <RedditBtn>
              <RedditIcon />
              Share On Reddit
            </RedditBtn>
          </RedditShareButton>

          <LinkedinShareButton url={shareUrl} title={title}>
            <LinkedInBtn>
              <LinkedInIcon />
              Share On LinkedIn
            </LinkedInBtn>
          </LinkedinShareButton>
        </div>
      </div>
    </Modal>
  );
}
